import { useMemo } from "react";
import {
  RECIPE_LANGUAGES,
  MultiLangEntityState,
  CurrentMultiLangEntity,
  Unit,
} from "../../api/types";
import { UnitsResponse } from "../../api/units";
import { API_URL } from "../../config";
import { useEditLang } from "../../hooks/useEditLang";
import { ReqState, useRequestURL } from "../../hooks/useRequestURL";
import { getLangLabel } from "../../utils/getLangLabel";

const parseUnitData = (data: UnitsResponse): Unit[] => data.units;
const mergeUnitDataToKeywordState = (
  language: RECIPE_LANGUAGES,
  ingredients: Unit[],
): MultiLangEntityState => {
  // const defaultName = categories.find(({id, ...language})=>)
  const x = ingredients.map(({ id, ...langOb }) => {
    const main: CurrentMultiLangEntity = {
      id,
      name: getLangLabel(langOb, language),
      ...langOb,
    };
    return main;
  });
  return x;
};

export const useUnits = (): [ReqState<MultiLangEntityState>, () => void] => {
  const [language] = useEditLang();

  const [unitState, refreshUnits] = useRequestURL({
    url: `${API_URL.UNITS}`,
    method: "GET",
    resetStateOnRefresh: false,
    parseData: parseUnitData,
  });

  const units: ReqState<MultiLangEntityState> = useMemo(() => {
    const { data, loading, error } = unitState;
    return {
      loading,
      error,
      data: data ? mergeUnitDataToKeywordState(language, data) : undefined,
    };
  }, [language, unitState]);

  return [units, refreshUnits];
};

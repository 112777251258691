import { requestWithTimeout } from ".";
import { API_URL } from "../config";
import { clearMyLangKeys } from "../utils/clearMyLangKeys";
import { Unit } from "./types";

export interface UnitsResponse {
  code: number;
  units: Unit[];
}

export const addUnit = async ({
  id, //remove me from request
  ...unit
}: Unit) => {
  return await requestWithTimeout<unknown, { unitId: number }>({
    url: `${API_URL.UNITS}`,
    method: "POST",
    body: clearMyLangKeys(unit),
  }).then(({ unitId }) => unitId);
};
export const updateUnit = async ({
  id, //remove me from request
  ...unit
}: Unit) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.UNITS}`,
    method: "PUT",
    body: {
      id,
      name: clearMyLangKeys(unit),
    },
  }).then(() => id);
};

export const deleteUnit = async (id: number) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.UNITS}?id=${id}`,
    method: "DELETE",
  }).then(() => true);
};

import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { RecipeDetails } from "../../../api/types";
import { DEFAULT_GRID_SPACING } from "../../../styles/themes";
import { DurationField } from "../../molecules/DurationField";

export interface DurationTabProps {
  recipe: RecipeDetails;
  setState: React.Dispatch<React.SetStateAction<RecipeDetails>>;
}
export function DurationTab({
  setState,
  recipe: { prepTime, cookTime, totalTime },
}: DurationTabProps) {
  const { t } = useTranslation("editRecipe");
  const onDurationChange = (key: keyof RecipeDetails, durations: string) => {
    setState((thisState) => {
      const totalTime =
        moment
          .duration(key === "prepTime" ? durations : thisState.prepTime)
          .as("minute") +
        moment
          .duration(key === "cookTime" ? durations : thisState.cookTime)
          .as("minute");
      return {
        ...thisState,
        [key]: durations,
        totalTime: moment.duration(totalTime, "minute").toISOString(),
      };
    });
  };
  return (
    <Grid container spacing={DEFAULT_GRID_SPACING}>
      <Grid item xs={4}>
        <DurationField
          tprops={{
            label: t("prepTime"),
            fullWidth: true,
            margin: "normal",
            required: true,
          }}
          value={prepTime}
          setDuration={onDurationChange.bind(null, "prepTime")}
        />
      </Grid>
      <Grid item xs={4}>
        <DurationField
          tprops={{
            label: t("cookTime"),
            fullWidth: true,
            margin: "normal",
            required: true,
          }}
          value={cookTime}
          setDuration={onDurationChange.bind(null, "cookTime")}
        />
      </Grid>
      <Grid item xs={4}>
        <DurationField
          tprops={{
            label: t("totalTime"),
            fullWidth: true,
            margin: "normal",
            disabled: true,
            // InputProps: { readOnly: true },
          }}
          unitFix={"minute"}
          value={totalTime}
        />
      </Grid>
    </Grid>
  );
}

import { TFunction } from "i18next";
import isEqual from "lodash/isEqual";
import {
  deleteStepImage,
  updateMainImageForSubRecipe,
  uploadImage,
} from "../api/images";
import {
  addMainRecipe,
  addSubRecipe,
  updateMainRecipe,
  updateSubRecipe,
} from "../api/recipes";
import {
  deleteStep,
  NewStep,
  UpdateStep,
  updateStep,
  updateStepOrder,
} from "../api/steps";
import {
  RecipeCategories,
  RecipeDetails,
  RecipeIngredients,
  UpdateAction,
  UpdateKeywords,
  UpdateMainRecipeDetails,
  UpdateRecipeCategories,
  UpdateRecipeDetails,
  UpdateRecipeIngredients,
  UpdateRecipeIngredientsSection,
} from "../api/types";
import { createNewStep } from "./createNewStep";

export interface ValidationResult {
  errors: string[];
  publishWarn: string[];
  warnings: string[];
}

export const extractMainRecipe = (
  recipe: RecipeDetails
): UpdateMainRecipeDetails => ({
  prepTime: recipe.prepTime,
  cookTime: recipe.cookTime,
  totalTime: recipe.totalTime,
  yield: recipe.recipeYield,
  ...recipe.nutrition,
  products: recipe.associatedProducts,
});

const flattenCategoryForUpdate = (
  { catId, subcatId }: RecipeCategories,
  action: "add" | "delete"
): UpdateRecipeCategories => ({
  catId,
  subcatId,
  action,
});
export const flattenUnitForUpdate = (
  { id, name, description, ingredientId, unit, ...ing }: RecipeIngredients,
  action: UpdateAction
): UpdateRecipeIngredients => {
  const ingStep: UpdateRecipeIngredients = {
    ...ing,
    description: description || "",
    unitId: unit.id,
    ingredientId,
    action,
    id,
  };
  if (id < 0) delete ingStep.id;
  return ingStep;
};

export const saveRecipe = async (
  recipe: RecipeDetails,
  oldRecipe: RecipeDetails,
  language: string,
  t: TFunction,
  doIt = false,
  debug = true
): Promise<{ errors: string[]; recipeId: number; mainId: number }> => {
  const errors: string[] = [];

  let { recipeId, mainId } = recipe;
  const mainRecipe: UpdateMainRecipeDetails = extractMainRecipe(recipe);
  if (debug) console.debug({ mainRecipe });
  let isNewMainRecipe = false;
  let isNewSubRecipe = false;
  let defaultImage: string | undefined = undefined;
  // new Main recipe
  if (mainId === -1) {
    if (debug) console.debug("Would addMainRecipe");
    // do not catch throw to parent
    mainId = doIt ? await addMainRecipe(mainRecipe) : 1;
    isNewMainRecipe = true;
    // new Main Recipe image
    if (recipe.imageFile) {
      if (debug) console.debug("Would uploadImage for main recipe");
      const images = doIt
        ? await uploadImage([recipe.imageFile], {
            mainRecipeId: mainId,
          })
        : // .catch((e) => {
          //   console.error(e);
          //   errors.push(t("editError:errorOnPicUpload"));
          //   return [undefined];
          // })
          [undefined];
      if (images && images[0]) {
        defaultImage = images[0];
      }
    }
  } else {
    // Main recipe exists an changed
    if (!isEqual(extractMainRecipe(oldRecipe), mainRecipe)) {
      // do not catch throw to parent
      if (debug)
        console.debug("Would updateMainRecipe: " + JSON.stringify(mainRecipe));
      if (doIt) {
        await updateMainRecipe({ ...mainRecipe, id: mainId });
      }
    }
  }

  // new sub recipe depending of the main recipe
  if (recipeId === -1) {
    isNewSubRecipe = true;
    const newSubRecipe = {
      name: recipe.name,
      headline: recipe.headline,
      description: recipe.description,
      keywords: recipe.keywords,
      ingredientsSections: recipe.ingredientsSections,
      categories: recipe.categories,
      author: recipe.author,
      status: recipe.status,
    };
    if (debug)
      console.debug(
        "Would addSubRecipe",
        newSubRecipe,
        mainId,
        language,
        defaultImage
      );
    // do not catch throw to parent
    recipeId = doIt
      ? await addSubRecipe(newSubRecipe, mainId, language, defaultImage)
      : 1;
  } else {
    // modify subrecipe
    const updateKeys: Array<keyof UpdateRecipeDetails> = [
      "name",
      "headline",
      "description",
      "author",
      "status",
    ];
    let updateRecipe: UpdateRecipeDetails = {};
    updateKeys.forEach((key) => {
      if (!isEqual(oldRecipe[key], recipe[key])) {
        updateRecipe = { ...updateRecipe, [key]: recipe[key] };
      }
    });
    const categories: UpdateRecipeCategories[] = [];
    // find new (added)
    recipe.categories
      .filter(
        (newCat) =>
          !oldRecipe.categories.find((oldCat) => isEqual(oldCat, newCat))
      )
      .forEach((addCat) => {
        categories.push(flattenCategoryForUpdate(addCat, "add"));
      });

    // find old (delete)
    oldRecipe.categories
      .filter(
        (oldCat) => !recipe.categories.find((newCat) => isEqual(oldCat, newCat))
      )
      .forEach((delCat) => {
        categories.push(flattenCategoryForUpdate(delCat, "delete"));
      });
    if (categories.length) {
      updateRecipe.categories = categories;
    }
    const ingredientsSections: UpdateRecipeIngredientsSection[] = [];
    /**
     * todo: refactor the type
     */
    type UpdateIngredients = UpdateRecipeIngredients & {
      sectionId: string;
      sectionTitle: string;
    };
    const ingredients: UpdateIngredients[] = [];

    oldRecipe.ingredientsSections.forEach((section) => {
      let sectionTitle = section.title;
      const potentialSection = recipe.ingredientsSections.find(
        ({ id }) => id === section.id
      );
      if (potentialSection) {
        sectionTitle = potentialSection.title;
      }

      section.ingredients.forEach((ingredient) => {
        /* find the ingredient in question in the specific section */
        const maybeEdited = recipe.ingredientsSections
          .find(({ id }) => id === section.id)
          ?.ingredients.find(({ id }) => id === ingredient.id);
        // We didn't find it anymore -> delete
        if (maybeEdited === undefined) {
          ingredients.push({
            ...flattenUnitForUpdate(ingredient, "delete"),
            sectionId: section.id,
            sectionTitle,
          });
        } else {
          // it is not the same -> update
          if (!isEqual(maybeEdited, ingredient)) {
            ingredients.push({
              ...flattenUnitForUpdate(maybeEdited, "update"),
              sectionId: section.id,
              sectionTitle,
            });
          }
        }
      });
    });
    recipe.ingredientsSections.forEach((section) => {
      console.log("recipe", section);
      section.ingredients.forEach((ingredient) => {
        const maybeNew = oldRecipe.ingredientsSections
          .find(({ id }) => id === section.id)
          ?.ingredients.find(({ id }) => id === ingredient.id);
        if (maybeNew === undefined && ingredient.ingredientId > 0) {
          ingredients.push({
            ...flattenUnitForUpdate(ingredient, "add"),
            sectionId: section.id,
            sectionTitle: section.title,
          });
        }
      });
    });
    if (ingredients.length) {
      /* build ingredients sections  with the new/udpated/deleted ingredients*/
      const sections: { id: string; title: string }[] = [];
      ingredients.forEach((ingredient) => {
        if (!sections.find((section) => section.id === ingredient.sectionId)) {
          sections.push({
            id: ingredient.sectionId,
            title: ingredient.sectionTitle,
          });
        }
      });
      sections.forEach((section) => {
        const sectionIngredients = ingredients
          .filter((ingredient) => ingredient.sectionId === section.id)
          .map((ingredient): UpdateRecipeIngredients => {
            const elementToReturn: UpdateRecipeIngredients = {
              description: ingredient.description,
              amount: ingredient.amount,
              action: ingredient.action,
              unitId: ingredient.unitId,
              ingredientId: ingredient.ingredientId,
            };

            if (ingredient.id) elementToReturn.id = ingredient.id;
            return elementToReturn;
          });
        ingredientsSections.push({
          id: section.id,
          title: section.title,
          ingredients: sectionIngredients,
        });
      });
      updateRecipe.ingredientsSections = ingredientsSections;
    }
    /* this is needed to trigger an update when no ingredients were changed. It is used to update the section title */
    const sections: UpdateRecipeIngredientsSection[] = [];
    oldRecipe.ingredientsSections.map((section) => {
      const potentialSection = recipe.ingredientsSections.find(
        ({ id, title }) => id === section.id && title !== section.title
      );
      if (potentialSection) {
        sections.push({
          id: section.id,
          title: potentialSection.title,
        });
      }
    });

    if (sections.length) {
      /* if it exists already we need to add the elements and not reinitialize the array */
      if (updateRecipe.ingredientsSections) {
        for (const section of sections) {
          /* prevent adding duplicates */
          if (
            !updateRecipe.ingredientsSections.find(
              (element) => element.id === section.id
            )
          )
            updateRecipe.ingredientsSections.push(section);
        }
      } else {
        updateRecipe.ingredientsSections = sections;
      }
    }

    const keywords: UpdateKeywords[] = [];
    oldRecipe.keywords.forEach((k) => {
      const maybeEdited = recipe.keywords.find(({ id }) => id === k.id);
      // We didn't find it anymore -> delete
      if (maybeEdited === undefined) {
        keywords.push({ keyId: k.id, action: "delete" });
      }
    });
    recipe.keywords.forEach((k) => {
      const maybeNew = oldRecipe.keywords.find(({ id }) => id === k.id);
      // We didn't find it in old -> new
      if (maybeNew === undefined) {
        keywords.push({ keyId: k.id, action: "add" });
      }
    });
    if (keywords.length) {
      updateRecipe.keywords = keywords;
    }

    const changedKey = Object.keys(updateRecipe);
    if (changedKey.length) {
      if (debug) console.debug("Upate recipe", JSON.stringify(updateRecipe));
      if (doIt) {
        await updateSubRecipe(recipeId, updateRecipe);
      }
    }
  }
  // overwrite the main pic only for this subRecipe
  if (!isNewMainRecipe && recipe.imageFile) {
    if (debug) console.debug("Would updateMainImageForSubRecipe");
    if (doIt) {
      await updateMainImageForSubRecipe(recipe.imageFile, {
        recipeId,
      }).catch((e) => {
        console.error(e);
        errors.push(t("editError:errorOnPicUpload"));
        return undefined;
      });
    }
  }

  let oldStepOrder = oldRecipe.recipeInstructions.map(({ id }) => id);

  const newStepOrder: number[] = [];
  for (const step of recipe.recipeInstructions) {
    const { deleted, id: stepId, name, text: description, images } = step;
    const isNewStep = stepId < 0;
    if (deleted) {
      // do not catch throw to parent
      oldStepOrder = oldStepOrder.filter((id) => id === stepId);
      if (doIt) await deleteStep(stepId);
      if (debug) console.debug("Would deleteStep stepId:" + stepId);
      continue;
    }
    const oldStep = oldRecipe.recipeInstructions.find(
      ({ id }) => stepId === id
    );
    if (isNewStep || !oldStep) {
      const newStep: NewStep = {
        recipeId,
        name,
        description,
        language,
      };

      // do not catch throw to parent
      const newStepId = await createNewStep(
        newStep,
        images,
        errors,
        t,
        doIt,
        debug
      );
      newStepOrder.push(newStepId);
    } else {
      newStepOrder.push(stepId);
      let updatedStep: UpdateStep = {
        stepId,
      };
      if (oldStep.name !== name) {
        updatedStep = { ...updatedStep, name };
      }
      if (oldStep.text !== description) {
        updatedStep = { ...updatedStep, description };
      }
      if (Object.keys(updatedStep).length > 1) {
        if (doIt) {
          // do not catch throw to parent
          await updateStep(updatedStep);
        }
        if (debug)
          console.debug("Would updateStep " + JSON.stringify(updatedStep));
      }
      const deleteImages: string[] = [];
      const uploadImages: File[] = [];
      images.forEach((image) => {
        if (image.deleted) deleteImages.push(image.base64orUrl);
        if (image.uploadFile) uploadImages.push(image.uploadFile);
      });
      if (uploadImages.length) {
        if (doIt) {
          await uploadImage(uploadImages, { stepId }).catch((e) => {
            console.error(e);
            errors.push(t("editError:errorOnPicUpload"));
          });
        }
        if (debug)
          console.debug("Would upload images.length: " + uploadImages.length);
      }
      if (deleteImages.length) {
        if (doIt)
          await Promise.all(
            deleteImages.map((imgURL) =>
              deleteStepImage(imgURL, stepId).catch((e) => {
                console.error(e);
                errors.push(t("editError:errorOnPicDelete"));
              })
            )
          );
        if (debug)
          console.debug("Would delete images: " + deleteImages.join(","));
      }
    }
  }
  if (oldStepOrder.join("") !== newStepOrder.join("") && !isNewSubRecipe) {
    if (doIt) {
      await updateStepOrder(newStepOrder);
    }
    if (debug) console.debug("Would update order: " + newStepOrder.join(","));
  }
  return { errors, recipeId, mainId };
};

import { TFunction } from "i18next";
import { requestWithTimeout } from ".";
import { API_URL } from "../config";
import { createNewStep } from "../utils/createNewStep";
import { flattenUnitForUpdate, saveRecipe } from "../utils/saveRecipe";
import { validateRecipe } from "../utils/validateRecipe";
import { getStepsForRecipe, NewStep } from "./steps";
import {
  MultiLangEntityState,
  RecipeCategories,
  RecipeDetails,
  RecipeIngredients,
  RecipeIngredientsSection,
  RECIPE_LANGUAGES,
  RECIPE_STATE,
  SubCatExtendEntity,
  SubRecipeDetails,
  UpdateMainRecipeDetails,
  UpdateRecipeDetails,
} from "./types";

export const addMainRecipe = async (theRecipe: UpdateMainRecipeDetails) => {
  return await requestWithTimeout<unknown, { id: number }>({
    url: `${API_URL.RECIPES_MAIN}`,
    method: "POST",
    body: theRecipe,
  }).then(({ id }) => id);
};

export const updateMainRecipe = async (
  theRecipe: UpdateMainRecipeDetails & { id: number }
) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.RECIPES_MAIN}`,
    method: "PUT",
    body: theRecipe,
  });
};

interface AddSubRecipe
  extends Omit<SubRecipeDetails, "ingredientsSections" | "keywords"> {
  id: number;
  language: string;
  defaultImage?: string;
  ingredientsSections: Array<
    Omit<RecipeIngredientsSection, "ingredients"> & {
      ingredients: Array<
        Omit<RecipeIngredients, "id" | "unit" | "name"> & { unitId: number }
      >;
    }
  >;
  keywords: number[];
}

export const addSubRecipe = async (
  theRecipe: SubRecipeDetails,
  mainRecipeId: number,
  language: string,
  defaultImage?: string
) => {
  const clearUp: AddSubRecipe = {
    ...theRecipe,
    id: mainRecipeId,
    language,
    defaultImage: defaultImage || "",
    keywords: theRecipe.keywords.map(({ id }) => id),
    ingredientsSections: theRecipe.ingredientsSections.map((section) => {
      const ingredients = section.ingredients.map((i) => {
        const { action, id, ...ni } = flattenUnitForUpdate(i, "delete");
        return ni;
      });
      return { ...section, ingredients };
    }),
  };
  return await requestWithTimeout<unknown, { id: number }>({
    url: `${API_URL.RECIPES}`,
    method: "POST",
    body: clearUp,
  }).then(({ id }) => id);
};

export const updateSubRecipe = async (
  recipeId: number,
  theRecipe: UpdateRecipeDetails
) => {
  return await requestWithTimeout({
    url: `${API_URL.RECIPES}`,
    method: "PUT",
    body: { ...theRecipe, id: recipeId },
  });
};
type GetSingelRecipeDetails = Omit<
  RecipeDetails,
  "recipeCategories" | "nutrition" | "imageBas64" | "categories"
> & {
  recipeIngredientsSections: RecipeIngredientsSection[];
  recipeCategories: Array<{
    categoryId: number;
    categoryName: string | null;
    subcategoryId: number;
    subcategoryName: string | null;
  }>;
  nutrition: {
    "@type": string;
    calories: number;
    carbohydrateContent: number;
    fatContent: number;
    proteinContent: number;
  };
  image?: {
    lastModified: string;
    path: string;
  };
};

export const parseSingleRecipe = ({
  recipe: getRecipe,
}: {
  recipe: GetSingelRecipeDetails;
}): Omit<RecipeDetails, "recipeInstructions"> => {
  const {
    recipeIngredientsSections,
    recipeCategories,
    image,
    nutrition: { calories, carbohydrateContent, fatContent, proteinContent },
    ...recipe
  } = getRecipe;
  const ingredientsSections = recipeIngredientsSections.map((section) => {
    const ingredients = section.ingredients.map((ingredient) => {
      return { ...ingredient, description: ingredient.description || "" };
    });
    return { ...section, ingredients };
  });
  const categories = recipeCategories.map(
    ({ categoryId: catId, subcategoryId: subcatId }): RecipeCategories => ({
      catId,
      subcatId,
    })
  );

  return {
    ...recipe,
    ingredientsSections,
    categories,
    nutrition: {
      calories,
      carbs: carbohydrateContent,
      fat: fatContent,
      protein: proteinContent,
    },
    imageBas64: image ? image.path : "",
  };
};

export const getPlainNewRecipe = (inLanguage: string): RecipeDetails => ({
  mainId: -1,
  recipeId: -1,
  name: "",
  headline: "",
  description: "",
  keywords: [],
  inLanguage: [{ id: -1, language: inLanguage }],
  author: "",
  imageBas64: "",
  dateCreated: "",
  dateModified: "",
  datePublished: "",
  prepTime: "P0D",
  cookTime: "P0D",
  totalTime: "P0D",
  status: RECIPE_STATE.pending,
  categories: [],
  recipeYield: 0,
  recipeInstructions: [],
  ingredientsSections: [],
  nutrition: {
    calories: 0,
    carbs: 0,
    fat: 0,
    protein: 0,
  },
  associatedProducts: "",
});

export const getRecipe = async (
  language: string,
  mainId: number
): Promise<RecipeDetails> => {
  const recipeDetails = await requestWithTimeout({
    url: `${API_URL.RECIPES}/${mainId}/${language}`,
    parseData: parseSingleRecipe,
  });
  const recipeInstructions = await getStepsForRecipe(recipeDetails.recipeId);
  // const [recipeInstructions, recipeDetails] = await Promise.all([
  //   getStepsForRecipe(mainId),
  //   requestWithTimeout({
  //     url: `${API_URL.RECIPES}/${mainId}/${language}`,
  //     parseData: parseSingleRecipe,
  //   }),
  // ]);
  return Object.freeze({
    ...recipeDetails,
    recipeInstructions: [...recipeInstructions],
  });
};

export const translateRecipe = async (
  recipe: RecipeDetails,
  newLanguage: string,
  translateTag: string
) => {
  const newSubRecipe = {
    name: translateTag + recipe.name,
    headline: translateTag + recipe.headline,
    description: translateTag + recipe.description,
    keywords: recipe.keywords,
    ingredientsSections: recipe.ingredientsSections,
    categories: recipe.categories,
    author: recipe.author,
    status: RECIPE_STATE.pending,
  };
  const recipeId = await addSubRecipe(
    newSubRecipe,
    recipe.mainId,
    newLanguage,
    recipe.imageBas64
  );
  const t = () => {};
  const errors: string[] = [];
  for (const step of recipe.recipeInstructions) {
    const { name, text: description, images } = step;
    const newStep: NewStep = {
      recipeId,
      name: translateTag + name,
      description: translateTag + description,
      language: newLanguage,
    };
    await createNewStep(newStep, images, errors, t);
  }

  return recipeId;
};

export const deleteRecipeMain = async (mainId: number) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.RECIPES_MAIN}?id=${mainId}`,
    method: "DELETE",
  }).then(() => true);
};
export const deleteSubRecipe = async (recipeId: number) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.RECIPES}?id=${recipeId}`,
    method: "DELETE",
  }).then(() => true);
};

export const togglePublishState = async (
  mainId: number,
  language: RECIPE_LANGUAGES,
  t: TFunction,
  mdata: MultiLangEntityState<{}>,
  sdata: MultiLangEntityState<SubCatExtendEntity>
) => {
  const r = await getRecipe(language, mainId);
  const { errors, publishWarn } = validateRecipe(
    r,
    t,
    mdata,
    sdata,
    language,
    true
  );
  if (errors.length || publishWarn.length) return [...errors, ...publishWarn];
  await saveRecipe(
    {
      ...r,
      status:
        r.status === "pending" ? RECIPE_STATE.published : RECIPE_STATE.pending,
    },
    r,
    language,
    t,
    true
  );
  return [];
};

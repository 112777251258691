import { requestWithTimeout } from ".";
import { API_URL } from "../config";

export const uploadImage = async (
  images: File[],
  payload: { mainRecipeId?: number; stepId?: number }
) => {
  const formData = new FormData();
  formData.append("data", JSON.stringify(payload));
  images.forEach((image) => {
    formData.append("images", image);
  });

  return await requestWithTimeout<unknown, { uploadedImagesURLs: string[] }>({
    url: `${API_URL.UPLOAD}`,
    method: "POST",
    body: formData,
    isNotJSON: true,
    timeout: 60 * 1000, // one minute
  }).then(({ uploadedImagesURLs }) => uploadedImagesURLs);
};

export const updateMainImageForSubRecipe = async (
  image: File,
  payload: { recipeId: number }
) => {
  const formData = new FormData();
  formData.append("data", JSON.stringify(payload));
  formData.append("image", image);

  return await requestWithTimeout<unknown, { uploadedImagesURL: string }>({
    url: `${API_URL.UPLOAD}`,
    method: "PUT",
    body: formData,
    isNotJSON: true,
    timeout: 60 * 1000, // one minute
  }).then(({ uploadedImagesURL }) => uploadedImagesURL);
};

export const deleteRecipeImage = (imageURL: string, recipeId?: number) => {
  return requestWithTimeout<unknown, unknown>({
    url: `${API_URL.UPLOAD}?recipeId=${recipeId}&imageURL=${encodeURIComponent(
      imageURL
    )}`,
    method: "DELETE",
  });
};
export const deleteStepImage = (imageURL: string, stepId?: number) => {
  return requestWithTimeout<unknown, unknown>({
    url: `${API_URL.UPLOAD}?stepId=${stepId}&imageURL=${encodeURIComponent(
      imageURL
    )}`,
    method: "DELETE",
  });
};

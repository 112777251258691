import { Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { plainMultiLangObj } from "../../utils/plainMultiLangObj";
import { DEFAULT_DEBOUNCE_TIME } from "../../config";
import { useEditLang } from "../../hooks/useEditLang";
import { RecipeDataContext } from "../../provider/RecipeData";
import { DEFAULT_GRID_SPACING } from "../../styles/themes";
import { DebouncedSearch } from "../atoms/DebouncedSearch";
import { BaseLayout } from "../layout/BaseLayout";
import { ListMainCategories } from "../molecules/ListMainCategories";
import { ListSubCategories } from "../molecules/ListSubCategories";
import { ListRecipes } from "../molecules/ListRecipes";

export const ListCategoriesPage: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>();

  const [selectedMainCategoryId, setSelectedMainCategoryId] = React.useState<
    number | undefined
  >(undefined);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = React.useState<
    number | undefined
  >(undefined);
  const [editLang] = useEditLang();
  const [mainSearchValue, setMainSearchValue] = useState("");
  const [subSearchValue, setSubSearchValue] = useState("");

  const [
    { mCat: mCatUnfiltered, sCat: sCatUnfiltered },
    { refreshMainCategories, refreshSubCategories },
  ] = useContext(RecipeDataContext);

  const { data: mData } = mCatUnfiltered;
  const { data: sData } = sCatUnfiltered;

  const { t } = useTranslation("categoryPage");

  const selectedMainCategory = React.useMemo(() => {
    if (selectedMainCategoryId !== undefined && selectedMainCategoryId < 0)
      return { id: selectedMainCategoryId, name: "", ...plainMultiLangObj() };
    return (mData || []).find(({ id }) => id === selectedMainCategoryId);
  }, [mData, selectedMainCategoryId]);

  // if no main cat is selected, select one
  useEffect(() => {
    if (!selectedMainCategory) {
      if (mData && mData.length > 0) {
        setSelectedMainCategoryId(mData[0].id);
        setSearchValue(mData[0].name);
      }
    }
  }, [selectedMainCategory, mData]);

  const selectedSubCategory = React.useMemo(() => {
    // No main
    if (!selectedMainCategoryId) {
      return undefined;
    }
    // New
    if (selectedSubCategoryId !== undefined && selectedSubCategoryId < 0) {
      return {
        id: selectedSubCategoryId,
        name: "",
        ...plainMultiLangObj(),
        categoryId: selectedMainCategoryId,
        sortOrder: 0 // just for TS here, isn't shown and shouldn't send to API todo test creation
      };
    }
    // find in responses
    return (sData || []).find(
      ({ id, categoryId }) =>
        id === selectedSubCategoryId && selectedMainCategoryId === categoryId,
    );
  }, [sData, selectedSubCategoryId, selectedMainCategoryId]);

  const mDataFiltered = React.useMemo(() => {
    if (!mainSearchValue) return mData;
    return (mData || []).filter(
      (keyword) =>
        keyword[editLang]
          ?.toLowerCase()
          .indexOf(mainSearchValue.toLowerCase()) !== -1,
    );
  }, [mData, mainSearchValue, editLang]);
  const sDataFiltered = React.useMemo(() => {
    if (!subSearchValue) return sData;
    return (sData || []).filter(
      (keyword) =>
        keyword[editLang]
          ?.toLowerCase()
          .indexOf(subSearchValue.toLowerCase()) !== -1,
    );
  }, [sData, subSearchValue, editLang]);

  return (
    <BaseLayout>
      <Grid container spacing={DEFAULT_GRID_SPACING}>
        <Grid item xs={6}>
          <Typography variant="h3">{t("main")}</Typography>
          <DebouncedSearch
            debounceTime={DEFAULT_DEBOUNCE_TIME}
            updateSearchValue={setMainSearchValue}
            value={mainSearchValue}
            label={t("actions:search")}
            txtFp={{
              variant: "standard",
              margin: "normal",
              placeholder: t("actions:search") + " " + t("main"),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h3">
            {t("sub")}{" "}
            {selectedMainCategory
              ? t("of") + " " + selectedMainCategory.name
              : null}
          </Typography>
          <DebouncedSearch
            debounceTime={DEFAULT_DEBOUNCE_TIME}
            updateSearchValue={setSubSearchValue}
            value={subSearchValue}
            label={t("actions:search")}
            txtFp={{
              margin: "normal",
              variant: "standard",
              placeholder: t("actions:search") + " " + t("sub"),
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <ListMainCategories
            selCategory={selectedMainCategory}
            reqState={[
              {
                loading: mCatUnfiltered.loading,
                error: mCatUnfiltered.error,
                data: mDataFiltered,
              },
              refreshMainCategories,
            ]}
            setSelectedCategoryId={(id, cat) => {
              setSelectedMainCategoryId(id);
              setSearchValue(cat?.name);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {selectedMainCategory ? (
            <ListSubCategories
              mainCatForSubCat={selectedMainCategory}
              reqState={[
                {
                  loading: sCatUnfiltered.loading,
                  error: sCatUnfiltered.error,
                  data: sDataFiltered,
                },
                refreshSubCategories,
              ]}
              setSelectedCategoryId={(id, cat) => {
                setSelectedSubCategoryId(id);
                setSearchValue(cat?.name);
              }}
              selCategory={selectedSubCategory}
            />
          ) : (
            <Typography align="center">{t("selectMainFirst")}</Typography>
          )}
        </Grid>
      </Grid>
      {searchValue ? (
        <ListRecipes
          searchValue={searchValue}
          rowsPerPage={5}
          offset={0}
          page={0}
          searchKey={"category"}
        />
      ) : null}
    </BaseLayout>
  );
};

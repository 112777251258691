import {
  createStyles,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  withStyles,
} from "@material-ui/core";
import React from "react";

export interface LangSelectorProps {
  languages: string[];
  selLang: string;
  onChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    xxx: {
      color: "#fff",
    },
  })
);

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      color: "#fff",
    },
  })
)(InputBase);

export const LangSelector: React.FC<LangSelectorProps> = ({
  languages,
  selLang,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={selLang}
      variant="standard"
      onChange={onChange}
      classes={{ icon: classes.xxx }}
      input={<BootstrapInput />}
    >
      {languages.map((l) => (
        <MenuItem value={l} key={l}>
          {l}
        </MenuItem>
      ))}
    </Select>
  );
};

import { useState } from "react";
import { ErrorObject } from "../utils/typesCheck";
export type SimpleReq<T> = {
  loading: boolean;
  error?: ErrorObject;
  data?: T;
};
export const useSimpleRequest = <T>(
  initLoading = false
): [SimpleReq<T>, React.Dispatch<React.SetStateAction<SimpleReq<T>>>] => {
  const [simpleRequest, setSimpleRequest] = useState<SimpleReq<T>>({
    loading: initLoading,
  });
  return [simpleRequest, setSimpleRequest];
};

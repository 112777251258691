import * as H from "history";
import { TFunction } from "i18next";
import React, { ReactNode, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./index.css";
import "./lang/i18next";

// export const locationHashChanges = (e: HashChangeEvent) => {
// };
// export const locationChanges = (e: any) => {
// };
// export const popstate = (e: PopStateEvent) => {
//   e.stopPropagation();
//   e.preventDefault();
// };

// window.addEventListener("hashchange", locationHashChanges);
// window.addEventListener("locationchange", locationChanges);
// window.addEventListener("popstate", popstate);

class RoutingHelperClass extends React.Component<{
  t: TFunction;
  history: H.History<H.LocationState>;
  children?: ReactNode;
}> {
  warnWhenNavigate?: boolean;
  navigateTo = (
    event: SyntheticEvent | undefined,
    path: string,
    state?: H.LocationState,
  ) => {
    const t = this.props.t;
    const doIt = () => this.props.history.push(path, state);
    if (this.warnWhenNavigate) {
      window.ALERT({
        title: t("warning"),
        text: t("reallyCancelEdit"),
        okLabel: t("no"),
        cancelLabel: t("yes"),
        doIt: (noClicked) => {
          if (!noClicked) {
            doIt();
          }
        },
      });
    } else {
      doIt();
    }
  };

  setWarnWhenNavigate = (warnWhenNavigate: boolean) => {
    this.warnWhenNavigate = warnWhenNavigate;
  };

  componentDidMount() {
    window.ROUTING = this;

    window.addEventListener("beforeunload", this.beforeunload);
    // window.addEventListener("hashchange", this.hashchange);
    // window.addEventListener("popstate", this.popstate);
  }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunload);
    // window.removeEventListener("hashchange", this.hashchange);
    // window.removeEventListener("popstate", this.popstate);
  }

  // popstate = (e: PopStateEvent) => {
  // };

  // hashchange = (e: HashChangeEvent) => {
  // };
  beforeunload = (e: BeforeUnloadEvent) => {
    if (this.warnWhenNavigate) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  render() {
    return <>{this.props.children}</>;
  }
}

declare global {
  interface Window {
    ROUTING: RoutingHelperClass;
  }
}
export const RoutingHelper: React.FC<{}> = ({ children }) => {
  const history = useHistory();
  const { t } = useTranslation("actions");
  return (
    <RoutingHelperClass t={t} history={history}>
      {children}
    </RoutingHelperClass>
  );
};

import { useMemo } from "react";
import { IngredientsResponse } from "../../api/ingredients";
import {
  Keyword,
  RECIPE_LANGUAGES,
  MultiLangEntityState,
  CurrentMultiLangEntity,
  Ingredient,
} from "../../api/types";
import { API_URL } from "../../config";
import { useEditLang } from "../../hooks/useEditLang";
import { ReqState, useRequestURL } from "../../hooks/useRequestURL";
import { getLangLabel } from "../../utils/getLangLabel";

const parseIngredientData = (data: IngredientsResponse): Keyword[] =>
  data.ingredients;
const mergeIngredientDataToKeywordState = (
  language: RECIPE_LANGUAGES,
  ingredients: Ingredient[],
): MultiLangEntityState => {
  // const defaultName = categories.find(({id, ...language})=>)
  return ingredients.map(({ id, ...langOb }) => {
    const main: CurrentMultiLangEntity = {
      id,
      name: getLangLabel(langOb, language),
      ...langOb,
    };
    return main;
  });
};
export const useIngredients = (): [
  ReqState<MultiLangEntityState>,
  () => void,
] => {
  const [language] = useEditLang();

  const [ingredients, refreshIngredients] = useRequestURL({
    url: `${API_URL.INGREDIENTS}`,
    method: "GET",
    resetStateOnRefresh: false,
    parseData: parseIngredientData,
  });

  const ingrs: ReqState<MultiLangEntityState> = useMemo(() => {
    const { data, loading, error } = ingredients;
    return {
      loading,
      error,
      data: data
        ? mergeIngredientDataToKeywordState(language, data)
        : undefined,
    };
  }, [language, ingredients]);

  return [ingrs, refreshIngredients];
};

import { CssBaseline } from "@material-ui/core";
import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { APP_ROUTES } from "./AppNavigation";
import { GlobalAlert } from "./GlobalAlert";
import "./index.css";
import "./lang/i18next";
import { AppContextProvider } from "./provider/AppState";
import { RecipeDataContextProvider } from "./provider/RecipeData";
import { RoutingHelper } from "./RoutingHelper";
import { RPTheme } from "./styles/themes";

export default function App() {
  return (
    <RPTheme>
      <RecoilRoot>
        <AppContextProvider>
          <RecipeDataContextProvider>
            <CssBaseline />
            <GlobalAlert />
            <Router>
              <RoutingHelper>
                <Switch>{APP_ROUTES}</Switch>
              </RoutingHelper>
            </Router>
          </RecipeDataContextProvider>
        </AppContextProvider>
      </RecoilRoot>
    </RPTheme>
  );
}

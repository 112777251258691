import { requestWithTimeout } from ".";
import { API_URL } from "../config";
import { clearMyLangKeys } from "../utils/clearMyLangKeys";
import { Keyword } from "./types";

export interface KeywordsResponse {
  code: number;
  keywords: Keyword[];
}

export const addKeyword = async ({
  id, //remove me from request
  ...keyword
}: Keyword) => {
  return await requestWithTimeout<unknown, { keywordId: number }>({
    url: `${API_URL.KEYWORDS}`,
    method: "POST",
    body: clearMyLangKeys(keyword),
  }).then(({ keywordId }) => keywordId);
};
export const updateKeyword = async ({
  id, //remove me from request
  ...keyword
}: Keyword) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.KEYWORDS}`,
    method: "PUT",
    body: {
      id,
      name: clearMyLangKeys(keyword),
    },
  }).then(() => id);
};

export const deleteKeyword = async (id: number) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.KEYWORDS}?id=${id}`,
    method: "DELETE",
  }).then(() => true);
};

import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { cloneDeep } from "lodash";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { insertMainCategory, updateCategory } from "../../api/categories";
import { insertSubCategory, updateSubCategory } from "../../api/subCategories";
import {
  CurrentMultiLangEntity,
  RECIPE_LANGUAGES,
  SubCatExtendEntity,
} from "../../api/types";
import { useSimpleRequest } from "../../hooks/useSimpleRequest";
import LoadingComponent from "../atoms/LoadingComponent";
import { SingleCategoryEditDialogContent } from "./SingleCategoryEditDialogContent";

export interface CategoryEditDialogProps {
  category: CurrentMultiLangEntity<SubCatExtendEntity> | CurrentMultiLangEntity;
  mainCatForSubCat?: CurrentMultiLangEntity;
  onClose: (id?: number) => void;
}

export const CategoryEditDialog: React.FC<CategoryEditDialogProps> = ({
  category,
  onClose,
  mainCatForSubCat,
}) => {
  const { t } = useTranslation("actions");
  const [editedCategory, setCategory] = useState(cloneDeep(category));

  const [{ loading, error }, setSaveState] = useSimpleRequest();
  const isNew = category ? category.id < 0 : false;

  const onSave = () => {
    setSaveState({ loading: true, error: undefined });
    (!mainCatForSubCat
      ? !isNew
        ? updateCategory(editedCategory as CurrentMultiLangEntity)
        : insertMainCategory(editedCategory as CurrentMultiLangEntity)
      : !isNew
      ? updateSubCategory(
          editedCategory as CurrentMultiLangEntity<SubCatExtendEntity>
        )
      : insertSubCategory(
          editedCategory as CurrentMultiLangEntity<SubCatExtendEntity>
        )
    )
      .then((categoryId) => {
        setSaveState({ loading: false, error: undefined });
        onClose(categoryId);
      })
      .catch((e) => {
        setSaveState({ loading: false, error: e });
      });
  };

  const onChange = (l: RECIPE_LANGUAGES) => (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const trans = event.target.value;
    setCategory((category) => ({ ...category, [l]: trans }));
  };

  return (
    <Dialog open={true} onEscapeKeyDown={() => onClose()}>
      <AppBar elevation={0} style={{ position: "relative" }}>
        <Toolbar>
          <Typography variant="h6">
            {!isNew ? t("edit") : t("create")}
          </Typography>
        </Toolbar>
      </AppBar>
      <LoadingComponent isLoading={loading} errorText={error}>
        <DialogContent>
          <SingleCategoryEditDialogContent
            editedCategory={editedCategory}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>{t("cancel")}</Button>
          <Button onClick={() => onSave()} color="primary">
            {!isNew ? t("save") : t("create")}
          </Button>
        </DialogActions>
      </LoadingComponent>
    </Dialog>
  );
};

import React from "react";
import { useParams } from "react-router-dom";
import { useEditLang } from "../../hooks/useEditLang";
import { useEditRecipe } from "../../hooks/useEditRecipe";
import LoadingComponent from "../atoms/LoadingComponent";
import { BaseLayout } from "../layout/BaseLayout";
import { RecipeEditForm } from "../RecipeEditForm";

export const EditRecipePage: React.FC = () => {
  const { mainId: mIdAsString } = useParams<{
    mainId: string;
  }>();
  const [language] = useEditLang();
  const mainId = parseInt(mIdAsString, 10);

  const [{ loading, data, error }, refreshData] = useEditRecipe(
    language,
    mainId,
  );
  return (
    <BaseLayout>
      <LoadingComponent isLoading={loading} errorText={error}>
        {/* Dismount on when loading!! To reset RecipeEditForm */}
        {data && !loading ? (
          <RecipeEditForm
            refresh={refreshData}
            recipe={data}
            isLoading={loading}
          />
        ) : null}
      </LoadingComponent>
    </BaseLayout>
  );
};

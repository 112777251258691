import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import moment from "moment";
import { initReactI18next } from "react-i18next";

import SupportedLanguageCode from "./SupportedLanguageCode";
import { Translations } from "./Translations";

// // @ts-ignore
// import deLocale from "moment/locale/de";
// // @ts-ignore
// import enLocale from "moment/locale/en-gb";
// moment.updateLocale("de", deLocale);
// moment.updateLocale("en", enLocale);

const getLanguageCode = () => {
  return window.navigator.language.substring(0, 2);
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      lookupQuerystring: "lang",
      caches: [],
    },
    fallbackLng: SupportedLanguageCode.English,
    interpolation: {
      escapeValue: false,
    },
    resources: Translations,
  });

export { getLanguageCode };
export default i18next;

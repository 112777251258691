import { Box, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { deleteAllMainCategories } from "../../api/categories";
import { CurrentMultiLangEntity, MultiLangEntityState } from "../../api/types";
import { ReqState } from "../../hooks/useRequestURL";
import { useSimpleRequest } from "../../hooks/useSimpleRequest";
import { useDefaultStyles } from "../../styles/themes";
import { ConfirmDialog } from "../Dialogs/ConfirmDialog";
import { CategoryEditDialog } from "../Dialogs/SingleCategoryEditDialog";
import { RMSTable, RMSTableData, RMSTableHeadCell } from "../RMSTable";

const getHeadCells = (
  t: TFunction,
  classes: Record<any, string>,
  onClick: (
    v: CurrentMultiLangEntity,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void
): RMSTableHeadCell<RMSTableData<CurrentMultiLangEntity>>[] => [
  {
    disablePadding: true,
    id: "id",
    width: "20%",
    label: t("actions:actions"),
    numeric: false,
    sortable: false,
    formatter: (v) => (
      <Button
        style={{ padding: 0, textTransform: "none" }}
        onClick={onClick.bind(null, v)}
      >
        <EditIcon className={classes.iconRight} />
      </Button>
    ),
  },
  {
    disablePadding: true,
    id: "name",
    width: "70%",
    label: t("name"),
    numeric: false,
    sortable: true,
  },
];
export interface ListMainCategoriesProps {
  selCategory: CurrentMultiLangEntity | undefined;
  setSelectedCategoryId: (
    id?: number,
    cat?: RMSTableData<CurrentMultiLangEntity>
  ) => void;
  reqState: [ReqState<MultiLangEntityState>, () => void];
}

export const ListMainCategories: React.FC<ListMainCategoriesProps> = ({
  selCategory,
  reqState,
  setSelectedCategoryId,
}) => {
  const classes = useDefaultStyles();
  const [{ loading, data, error }, refresh] = reqState;

  const [selected, setSelected] = React.useState<number[]>([]);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  const rows = data || [];
  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  const { t } = useTranslation(["editMainCategory", "tbl", "actions"]);
  const onEditClick = (v: RMSTableData<CurrentMultiLangEntity>) => {
    setSelectedCategoryId(v.id, v);
  };
  const onAddClick = () => {
    setSelectedCategoryId(-1);
    setOpenEditDialog(true);
  };

  const onSaveClose = (savedId?: number) => {
    if (savedId !== undefined) {
      refresh();
      setSelectedCategoryId(savedId);
    }
    setOpenEditDialog(false);
  };

  const [delReq, setDeleteRequests] = useSimpleRequest();

  const deleteMainCategories = (confirmed: boolean) => {
    setDeleteConfirm(false);
    if (confirmed) {
      setDeleteRequests({ loading: true, error: undefined });
      deleteAllMainCategories(selected).then((errorIds) => {
        setSelected([]);
        setDeleteRequests({
          loading: false,
          error: errorIds.length
            ? {
                code: undefined,
                message: errorIds.length + t("couldNotBeDelete"),
                hint: undefined,
              }
            : undefined,
        });
        refresh();
      });
    }
  };

  return (
    <>
      {selCategory && openEditDialog ? (
        <CategoryEditDialog category={selCategory} onClose={onSaveClose} />
      ) : null}
      <ConfirmDialog
        open={deleteConfirm}
        doIt={deleteMainCategories}
        text={t("confirmDelete", { count: selected.length })}
        okLabel={t("actions:deleteLabel")}
        cancelLabel={t("actions:cancel")}
      />
      <RMSTable
        isLoading={loading || delReq.loading}
        error={error || delReq.error}
        startOrderBy={"name"}
        hoverItemId={selCategory?.id}
        // startPageSize={5}
        pagination={{
          labelRowsPerPage: t("tbl:labelRowsPerPage"),
        }}
        cellDefinition={getHeadCells(t, classes, (v, e) => {
          e.stopPropagation();
          setSelectedCategoryId(v.id);
          setOpenEditDialog(true);
        })}
        onClick={onEditClick}
        setSelected={setSelected}
        selected={selected}
        rows={rows}
      />
      <Box pt={2} pb={2} textAlign="right" className="rightSpacingChildren">
        <Button
          variant="contained"
          color="secondary"
          disabled={selected.length === 0}
          onClick={() => setDeleteConfirm(true)}
        >
          <DeleteIcon className={classes.iconLeft} />
          {t("actions:deleteLabel")}{" "}
          {selected.length !== 0 ? `(${selected.length})` : ""}
        </Button>
        <Button variant="contained" onClick={onAddClick} color="primary">
          <AddIcon className={classes.iconLeft} />
          {t("actions:create")}
        </Button>
      </Box>
    </>
  );
};

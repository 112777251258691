import { Resource } from "i18next";
import SupportedLanguageCode from "./SupportedLanguageCode";

export const Translations: Resource = {
  [SupportedLanguageCode.German]: {
    translation: {
      loading: "Lade...",
      settings: "Einstellungen",
      search: "Suche",
      upserror: "Da ist leider was angebrannt ;-(",
      appBarTitle: "Severin RMS",
      logoutExpires: "Neuanmelden in {{minutes}} Minuten notwendig!",
    },
    validate: {
      pwdToShort: "Zu kurz (8 Zeichen)",
      notEmpty: "Muss ausgefüllt werden",
      invalid: "Ungültig",
    },
    errorCodes: {
      "400": "Anwendungsfehler (400)",
      "409": "Existiert bereits auf",
      "401": "Anmeldung abgelaufen, du wirst gleich zum Login weitergeleitet",
      "403": "Fehlende Berechtigungen",
      "404": "Ressource nicht gefunden",
      "500": "Interner Serverfehler",
      failedToFetch: "Netzwerkprobleme",
    },
    login: {
      wrongEmail: "Nutzer mit der eingegebenen E-Mail existiert nicht",
      wrongPassword: "Passwort falsch",
      loading: "Lade...",
      loginTitle: "Anmelden",
      eMail: "E-Mail",
      password: "Passwort",
      loginText: "Bitte melden Sie sich mit ihrer E-Mail und Passwort an:",
      pwdResetText:
        "Bitte geben Sie die Mail-Adresse an, ihnen wird dann ein neues Passwort zugesendet",
      pwdResetSend:
        "Ein neues Passwort wurde an die angegebene Mail-Adressse gesendet",
      loginButton: "Ok",
      pwdForgotten: "Passwort vergessen",
    },
    notFoundPage: {
      title: "Seite nicht gefunden",
      toStartPage: "Zur Startseite",
    },
    menu: {
      categories: "Kategorien",
      keywords: "Schlüsselwörter",
      "ingredients-units": "Zutaten und Einheiten",
      units: "Einheiten",
      ingredients: "Zutaten",
      recipes: "Rezepte",
      users: "Benutzer",
      lang: "Sprachen",
    },
    tbl: {
      labelRowsPerPage: "Anzahl pro Seite",
      noData: "- Keine Daten -",
      recipesSearch: "Rezepte mit {{searchValue}}",
    },
    actions: {
      actions: "Aktionen",
      ok: "Ok",
      deleteLabel: "Löschen",
      ignoreAndSage: "Ignorieren und Speichern",
      add: "Hinzufügen",
      create: "Anlegen",
      edit: "Bearbeiten",
      cancel: "Abbrechen",
      save: "Speichern",
      search: "Suche",
      copy: "Kopieren",
      addAfter: "danach hinzufügen",
      addBefore: "davor hinzufügen",
      next: "weiter",
      back: "zurück",
      toTop: "nach oben",
      toBottom: "nach unten",
      upload: "hochladen",
      open: "öffnen",
      loading: "Lade...",
      yes: "Ja",
      no: "Nein",
      warning: "Warnung",
      editTranslation: "Übersetzung bearbeiten",
      delete: "Löschen",
      hint: "Hinweis",
      noOptionsText: "Keine Ergebnisse",
      reallyCancelEdit:
        "Alle ihre bisherigen Änderungen gehen verloren. Fortfahren?",
    },
    date: {
      fulldateFormat: "DD.MM.YY HH:mm",
    },
    durationField: {
      minute: "Minuten",
      hour: "Stunden",
    },
    addCategory: {
      addCategoryTitle: "Kategorie hinzufügen",
      noOptionsText: "Keine Ergebnisse",
      catSearch: "Kategorie-Suche",
    },
    addKeyword: {
      addCategoryTitle: "Schlüsselwort hinzufügen",
      noOptionsText: "Keine Ergebnisse",
      keySearch: "Schlüsselwort-Suche",
    },
    usersTable: {
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail",
      admin: "Admin?",
      usersCouldNotBeDelete: " Benutzer konnten nicht gelöscht werden",
      confirmDelete: "Wirklich alle {{count}} Benutzer löschen?",
    },
    categoryPage: {
      main: "Hauptkategorien",
      mainSingle: "Hauptkategorie",
      sub: "Unterkategorien",
      subSingle: "Unterkategorie",
      of: "von",
      new: "Neue",
      editAllWarn:
        "Die Kategorien werden beim Speichern für allen verknpüften Rezepte aktualisiert",
      selectMainFirst: "Bitte wählen sie eine Hauptkategorie aus",
    },
    editMainCategory: {
      name: "Name",
      translations: "Übersetzungen",
      currentLang: "Aktuelle Bearbeitungssprache:",
      confirmDelete: "Wirklich alle {{count}} Kategorien löschen?",
      couldNotBeDelete: " Kategorien konnten nicht gelöscht werden",
    },
    editKeyword: {
      name: "Name",
      searchPlaceholder: "Schlüsselwort suchen",
      translations: "Übersetzungen",
      currentLang: "Aktuelle Bearbeitungssprache:",
      deleteWarn:
        "{{length}} Schlüsselwörter werden IN ALLEN SPRACHEN UND VERKNÜPFTEN REZEPTEN gelöscht! Fortfahren?",
      deleteError:
        "Nicht alle ausgewählten Schlüsselwörter konnten erfolgreich gelöscht werden.",
    },
    editIngredient: {
      name: "Name",
      searchPlaceholder: "Zutat suchen",
      translations: "Übersetzungen",
      currentLang: "Aktuelle Bearbeitungssprache:",
      deleteWarn:
        "{{length}} Zutaten werden IN ALLEN SPRACHEN UND VERKNÜPFTEN REZEPTEN gelöscht! Fortfahren?",
      deleteError:
        "Nicht alle ausgewählten Zutaten konnten erfolgreich gelöscht werden.",
    },
    editUnit: {
      name: "Name",
      searchPlaceholder: "Einheit suchen",
      translations: "Übersetzungen",
      currentLang: "Aktuelle Bearbeitungssprache:",
      deleteWarn:
        "{{length}} Einheiten werden IN ALLEN SPRACHEN UND VERKNÜPFTEN REZEPTEN gelöscht! Fortfahren?",
      deleteError:
        "Nicht alle ausgewählten Einheiten konnten erfolgreich gelöscht werden.",
    },
    editSubCategory: {
      name: "Name",
      sortOrder: "Sortierung",
      of: "von",
      confirmDelete: "Wirklich alle {{count}} Unterkategorien löschen?",
      couldNotBeDelete: " Unterkategorien konnten nicht gelöscht werden",
    },
    editUser: {
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail",
      isAdmin: "Admin?",
      password: "Passwort",
      changePassword: "Passwort ändern (optional)",
    },
    editRecipe: {
      publish: "Veröffentlichen",
      couldNotPublished: "Das Rezept kann nicht veröffentlich werden",
      unpublish: "Nicht mehr Veröffentlichen",
      newRecipe: "Neues Rezept",
      meta: "Metadaten",
      description: "Beschreibung",
      headline: "Kurzbeschreibung",
      name: "Name",
      title: "Name",
      searchPlaceholder: "Suchbegriff",
      dateCreated: "Erstellt",
      datePublished: "Veröffentlicht",
      dateModified: "Bearbeitet",
      prepTime: "Vorbereitungszeit",
      cookTime: "Zubereitungszeit",
      totalTime: "Dauer insgesamt",
      status: "Status",
      duration: "Dauer",
      complete: "Vollständig",
      keywords: "Schlüsselwörter",
      keyword: "Schlüsselwort",
      categories: "Kategorien",
      category: "Kategorie",
      steps: "Schritte",
      nutrition: "Nährwerte pro Portion",
      ingredients: "Zutaten",
      ingredientsAndNutrtion: "Zutaten / Nährwerte",
      ingredient: "Zutat",
      products: "Produkte",
      product: "Produkt Id",
      recipeYield: "Portionsanzahl",
      author: "Autor",
      contentLink: "Verknüpfung",
      availableLanguages: "verfügbare Sprachen",
      recipePicture: "Rezeptbild",
      recipePictureHints: {
        title: "Hinweise:",
        dimensionsHint: "- Die Mindestgröße des Bildes ist 300x300 Pixel",
        sizeHint: "- Die maximale Dateigröße liegt bei 4 MB",
      },
      actions: "Aktionen",
      iamount: "Menge",
      iname: "Zutat",
      iunit: "Einheit",
      idescription: "Beschreibung",
      calories: "Kalorien (in kcal)",
      carbohydrateContent: "Kohlenhydrate (in g)",
      fatContent: "Fett (in g)",
      proteinContent: "Eiweiß (in g)",
      searchKey: "Suchen in",
    },
    langEdit: {
      newLang: "Neue Sprache",
      deleteLang: "Sprache löschen",
      deleteLangWarn:
        "Warnung das Rezept für die Sprache {{language}} wird gelöscht. Dieser Schritt kann nicht rückgängig gemacht werden!",
      deleteLangCheckbox:
        "Alle anderen vorhandenen Sprachen ({{length}}) ebenfalls löschen",
      copyLangTo:
        "Möchten Sie das Rezept in {{language}} übersetzen? Alle Angaben werden dabei kopiert.",
      languageExist: "vorhanden",
      unsavedChanges:
        "Bitte speichern Sie erst dieses Rezept, bevor sie eine neue Übersetzung hinzufügen",
      languageNotExist: "nicht vorhanden",
    },
    editError: {
      tooSmall:
        "Das Bild ({{fileName}}) ist zu klein! Die empfohlene Mindestgröße ist ({{width}}x{{height}}).",
      tooBig:
        "Das Bild ({{fileName}}) ist zu groß! Die maximale Größe liegt bei 4 MB",
      errorOnSave:
        "Fehler beim Speichern um {{date}}. Bitte ein Screenshot dieser Website nehmen und mit einer kurzen Beschreibung dessen , was schief gelaufen ist, an Nuuk sich wenden, um Unterstützung zu erhalten",
      emptyArray: "Keine angegeben",
      resolveWarn: "Diese Felder sollten evtl. überprüft werden:",
      resolveError: "Diese Felder müssen überprüft werden:",
      empty: "fehlt, leer, oder 0",
      ingredient: "Zutat",
      translateMe: "muss noch übersetzt werden",
      errorOnPicUpload: "Fehler beim Bildupload",
      errorOnPicDelete: "Fehler beim Löschen eines Bildes",
    },
    recipeStep: {
      images: "Bilder",
      step: "Schritt",
      name: "Titel",
      text: "Beschreibung",
    },
    ingredients: {
      sectionTitle: "Teilgericht Titel",
    },
    recipeStates: {
      stateLabel: "Status",
      // default,
      // placed: "Platziert",
      // approved: "Genehmigt",
      // delivered: "Ausgeliefert",
      // EditState,
      published: "Veröffentlicht",
      pending: "Unveröffentlicht",
    },
    newProduct: {
      newProductID: "Neue Produkt ID",
      newProductDialogTitle: "Neues Produkt hinzufügen",
    },
  },
  [SupportedLanguageCode.English]: {
    translation: {
      loading: "Loading...",
      settings: "Settings",
      search: "Search",
      upserror: "Something went wrong ;-(",
      appBarTitle: "Severin RMS",
      logoutExpires: "New registration is needed in {{minutes}} minutes!",
    },
    validate: {
      pwdToShort: "Too short (8 characters)",
      notEmpty: "Needs to be filled out",
      invalid: "Invalid",
    },
    errorCodes: {
      "400": "Application error (400)",
      "409": "Already exists on",
      "401": "Login expired, you will be redirected to login in a moment",
      "403": "Missing permissions",
      "404": "Resource not found",
      "500": "Internal server error",
      failedToFetch: "Network problems",
    },
    login: {
      wrongEmail: "User with the provided email does not exist",
      wrongPassword: "Password is incorrect",
      loading: "Loading...",
      loginTitle: "Login",
      eMail: "e-mail",
      password: "Password",
      loginText: "Please login with your email and password:",
      pwdResetText:
        "Please enter your email, a new password will be sent to you",
      pwdResetSend:
        "A new password has been sent to the specified mail address",
      loginButton: "Ok",
      pwdForgotten: "Forgotten password",
    },
    notFoundPage: {
      title: "Page not found",
      toStartPage: "To Home page",
    },
    menu: {
      categories: "Categories",
      keywords: "Keywords",
      "ingredients-units": "Ingredients and units",
      units: "Units",
      ingredients: "Ingredients",
      recipes: "Recipes",
      users: "Users",
      lang: "Languages",
    },
    tbl: {
      labelRowsPerPage: "Number per page",
      noData: "- no data -",
      recipesSearch: "Recipes with {{searchValue}}",
    },
    actions: {
      actions: "Actions",
      ok: "Ok",
      deleteLabel: "Delete",
      ignoreAndSage: "Ignore and save",
      add: "Add",
      create: "Create",
      edit: "Edit",
      cancel: "Cancel",
      save: "Save",
      search: "Search",
      copy: "Copy",
      addAfter: "add after",
      addBefore: "add before",
      next: "next",
      back: "back",
      toTop: "to the top",
      toBottom: "down",
      upload: "upload",
      open: "open",
      loading: "loading...",
      yes: "Yes",
      no: "No",
      warning: "Warning",
      editTranslation: "Edit translation",
      delete: "Delete",
      hint: "Hint",
      noOptionsText: "No results",
      reallyCancelEdit: "All your previous changes will be lost. Continue?",
    },
    date: {
      fulldateFormat: "DD.MM.YY HH:mm",
    },
    durationField: {
      minute: "Minutes",
      hour: "Hours",
    },
    addCategory: {
      addCategoryTitle: "Add category",
      noOptionsText: "No results",
      catSearch: "Category-Search",
    },
    addKeyword: {
      addCategoryTitle: "Add keyword",
      noOptionsText: "No results",
      keySearch: "Keyword-Search",
    },
    usersTable: {
      firstName: "First name",
      lastName: "Last name",
      email: "E-mail",
      admin: "Admin?",
      usersCouldNotBeDelete: " Users could not be deleted",
      confirmDelete: "All {{count}} users will be deleted, continue?",
    },
    categoryPage: {
      main: "Maincategories",
      mainSingle: "Main category",
      sub: "Subcategories",
      subSingle: "Subcategory",
      of: "of",
      new: "New",
      editAllWarn:
        "The categories will be updated for all linked recipes when saving",
      selectMainFirst: "Please select a main category",
    },
    editMainCategory: {
      name: "Name",
      translations: "Translations",
      currentLang: "Current editing language:",
      confirmDelete: "All {{count}} categories will be deleted, continue?",
      couldNotBeDelete: " categories could not be deleted",
    },
    editKeyword: {
      name: "Name",
      searchPlaceholder: "keyword search",
      translations: "Translations",
      currentLang: "Current editing language:",
      deleteWarn:
        "{{length}} keywords will be deleted IN ALL LANGUAGES AND RELATED RECIPES! Continue?",
      deleteError: "Not all selected keywords were successfully deleted.",
    },
    editIngredient: {
      name: "Name",
      searchPlaceholder: "Search ingredient",
      translations: "Translations",
      currentLang: "Current editing language:",
      deleteWarn:
        "{{length}} Ingredients will be deleted IN ALL LANGUAGES AND RELATED RECIPES! Continue?",
      deleteError: "Not all selected ingredients were successfully deleted.",
    },
    editUnit: {
      name: "Name",
      searchPlaceholder: "Search Unit",
      translations: "Translations",
      currentLang: "Current editing language:",
      deleteWarn:
        "{{length}} units will be deleted IN ALL LANGUAGES AND RELATED RECIPES! Continue?",
      deleteError: "Not all selected units were successfully deleted.",
    },
    editSubCategory: {
      name: "Name",
      sortOrder: "Order",
      of: "of",
      confirmDelete: "All {{count}} subcategories will be deleted, continue?",
      couldNotBeDelete: " Subcategories could not be deleted.",
    },
    editUser: {
      firstName: "First name",
      lastName: "Last name",
      email: "E-mail",
      isAdmin: "Admin?",
      password: "Password",
      changePassword: "Change password (optional)",
    },
    editRecipe: {
      publish: "Publish",
      couldNotPublished: "The recipe cannot be published",
      unpublish: "Do not publish anymore",
      newRecipe: "New recipe",
      meta: "Metadata",
      description: "Description",
      headline: "Short description",
      name: "Name",
      title: "Name",
      searchPlaceholder: "Search term",
      dateCreated: "Created",
      datePublished: "Published",
      dateModified: "Edited",
      prepTime: "Preparation time",
      cookTime: "Cooking time",
      totalTime: "Total duration",
      status: "Status",
      duration: "Duration",
      complete: "Complete",
      keywords: "Keywords",
      keyword: "Keyword",
      categories: "Categories",
      category: "Category",
      steps: "Steps",
      nutrition: "Nutritional values per serving",
      ingredients: "Ingredients",
      ingredientsAndNutrtion: "Ingredients / nutritional values",
      ingredient: "Ingredient",
      products: "Products",
      product: "Product Id",
      recipeYield: "Serving Count",
      author: "Author",
      contentLink: "Link",
      availableLanguages: "available languages",
      recipePicture: "Recipe picture",
      recipePictureHints: {
        title: "Hints:",
        dimensionsHint: "- The minimum allowed dimension is 300x300",
        sizeHint: "- The maximum allowed size is 4 MB",
      },
      actions: "Actions",
      iamount: "Quantity",
      iname: "Ingredient",
      iunit: "Unit",
      idescription: "Description",
      calories: "Calories (in kcal)",
      carbohydrateContent: "Carbohydrates (in g)",
      fatContent: "Fat (in g)",
      proteinContent: "Protein (in g)",
      searchKey: "Search in",
    },
    langEdit: {
      newLang: "New language",
      deleteLang: "Delete language",
      deleteLangWarn:
        "Warning the recipe in {{language}} will be deleted. This step cannot be undone!",
      deleteLangCheckbox:
        "Delete all other existing languages ({{length}}) as well",
      copyLangTo:
        "Do you want to translate the recipe into {{language}}? All information will be copied in the process.",
      languageExist: "exists",
      unsavedChanges:
        "Please save this recipe first before adding a new translation",
      languageNotExist: "not available.",
    },
    editError: {
      tooSmall:
        "The image ({{fileName}}) is too small! The recommended minimum size is ({{width}}x{{height}}).",
      tooBig: "The image ({{fileName}}) is too big! The maximum size is 4 MB",
      errorOnSave:
        "Error saving at {{date}}. Please take a screenshot of this website and reach out with a short description of what went wrong to Nuuk for support",
      emptyArray: "None specified",
      resolveWarn: "These fields may need to be checked:",
      resolveError: "These fields must be checked:",
      empty: "missing, empty, or 0",
      ingredient: "Ingredient",
      translateMe: "still needs to be translated",
      errorOnPicUpload: "Error occured when uploading an image",
      errorOnPicDelete: "Error occured when deleting an image",
    },
    recipeStep: {
      images: "Images",
      step: "Step",
      name: "Title",
      text: "Description",
    },
    ingredients: {
      sectionTitle: "Section title",
    },
    recipeStates: {
      stateLabel: "Status",
      published: "Published",
      pending: "Pending",
    },
    newProduct: {
      newProductID: "New Product ID",
      newProductDialogTitle: "Add new product",
    },
  },
  [SupportedLanguageCode.French]: {
    translation: {
      loading: "Chargement...",
      settings: "Paramètres",
      search: "Rechere",
      upserror: "Malheureusement, quelque chose a mal tourné ;-(",
      appBarTitle: "Severin RMS",
      logoutExpires: "Vous devez vous reconnecter dans {{minutes}} minutes!",
    },
    validate: {
      pwdToShort: "Trop court (8 caractères)",
      notEmpty: "Doit être rempli",
      invalid: "Invalide",
    },
    errorCodes: {
      "400": "Erreur d'application (400)",
      "409": "Already exists on",
      "401": "Session expriée, vous serez redirigé vers la page de connexion",
      "403": "Autorisations manquantes",
      "404": "Ressource introuvable",
      "500": "Erreur interne du serveur",
      failedToFetch: "Problèmes de réseau",
    },
    login: {
      wrongEmail: "L'utilisateur avec l'adresse e-mail saisie n'existe pas",
      wrongPassword: "Mot de passe est incorrect",
      loading: "Chargement...",
      loginTitle: "Connexion",
      eMail: "E-mail",
      password: "Mot de passe",
      loginText:
        "Veuillez vous connecter avec votre email et votre mot de passe:",
      pwdResetText:
        "Veuillez saisir votre adresse e-mail, un nouveau mot de passe vous sera alors envoyé",
      pwdResetSend:
        "Un nouveau mot de passe a été envoyé à l'adresse e-main spécifiée",
      loginButton: "Ok",
      pwdForgotten: "Mot de passe oublié",
    },
    notFoundPage: {
      title: "Page non trouvée",
      toStartPage: "Vers la page d'accueil",
    },
    menu: {
      categories: "Catégories",
      keywords: "Mots clés",
      "ingredients-units": "Ingrédients et unités",
      units: "Unités",
      ingredients: "Ingrédients",
      recipes: "Recettes",
      users: "Utilisateurs",
      lang: "Langues",
    },
    tbl: {
      labelRowsPerPage: "Nombre par page",
      noData: "- Aucune donnée -",
      recipesSearch: "Recettes avec {{searchValue}}",
    },
    actions: {
      actions: "Actions",
      ok: "Ok",
      deleteLabel: "Supprimer",
      ignoreAndSage: "Ignorer et enregistrer",
      add: "Ajouter",
      create: "Créer",
      edit: "Modifier",
      cancel: "Annuler",
      save: "Enregistrer",
      search: "Rechercher",
      copy: "Copier",
      addAfter: "ajouter après",
      addBefore: "ajouter avant",
      next: "suivant",
      back: "retour",
      toTop: "vers le haut",
      toBottom: "vers le bas",
      upload: "uploader",
      open: "ouvrir",
      loading: "Chargement...",
      yes: "Oui",
      no: "Non",
      warning: "Avertissement",
      editTranslation: "Modifier la traduction",
      delete: "Effacer",
      hint: "Indice",
      noOptionsText: "Aucun résultat",
      reallyCancelEdit:
        "Toutes vos modifications précédentes seront perdues, continuer?",
    },
    date: {
      fulldateFormat: "DD.MM.YY HH:mm",
    },
    durationField: {
      minute: "Minutes",
      hour: "Heures",
    },
    addCategory: {
      addCategoryTitle: "Ajouter une catégorie",
      noOptionsText: "Aucun résultat",
      catSearch: "Recherche par catégorie",
    },
    addKeyword: {
      addCategoryTitle: "Ajouter un mot-clé",
      noOptionsText: "Aucun résultat",
      keySearch: "Recherche par mot-clé",
    },
    usersTable: {
      firstName: "Prénom",
      lastName: "Nom",
      email: "E-mail",
      admin: "Admin?",
      usersCouldNotBeDelete: " Les utilisateurs n'ont pas pu être supprimés",
      confirmDelete:
        "Voulez-vous vraiment supprimer tous les {{count}} utilisateurs?",
    },
    categoryPage: {
      main: "Catégories principales",
      mainSingle: "Catégorie principale",
      sub: "Sous-catégories",
      subSingle: "Sous-catégorie",
      of: "de",
      new: "nouveau",
      editAllWarn:
        "Les catégories sont mises à jour pour toutes les recettes liées lors de leur enregistrement",
      selectMainFirst: "Veuillez sélectionner une catégorie principale",
    },
    editMainCategory: {
      name: "Nom",
      translations: "Traductions",
      currentLang: "Langue d'édition actuelle:",
      confirmDelete:
        "Voulez-vous vraiment supprimer toutes les {{count}} catégories?",
      couldNotBeDelete: " categories could not be deleted",
    },
    editKeyword: {
      name: "Nom",
      searchPlaceholder: "Rechercher le mot-clé",
      translations: "Traductions",
      currentLang: "Langue d'édition actuelle:",
      deleteWarn:
        "{{length}} keywords seront supprimées DANS TOUTES LES LANGUES ET RECETTES CONNEXES! Continuer?",
      deleteError:
        "Tous les mots clés sélectionnés n'ont pas pu être supprimés.",
    },
    editIngredient: {
      name: "Nom",
      searchPlaceholder: "Rechercher l'ingrédient",
      translations: "Traductions",
      currentLang: "Langue d'édition actuelle:",
      deleteWarn:
        "{{length}} Ingrédients seront supprimées DANS TOUTES LES LANGUES ET RECETTES CONNEXES! Continuer?",
      deleteError:
        "Tous les ingrédients sélectionnés n'ont pas été supprimés avec succès.",
    },
    editUnit: {
      name: "Nom",
      searchPlaceholder: "Rechercher l'unité",
      translations: "Traductions",
      currentLang: "Langue d'édition actuelle:",
      deleteWarn:
        "{{length}} unités seront supprimées DANS TOUTES LES LANGUES ET RECETTES CONNEXES! Continuer?",
      deleteError:
        "Toutes unités sélectionnées n'ont pas été supprimées avec succès.",
    },
    editSubCategory: {
      name: "Nom",
      sortOrder: "Triage",
      of: "de",
      confirmDelete:
        "Voulez-vous vraiment supprimer toutes les {{count}} sous-catégories?",
      couldNotBeDelete: " Les sous-catégories n'ont pas pu être supprimées.",
    },
    editUser: {
      firstName: "Prénom",
      lastName: "Nom",
      email: "E-mail",
      isAdmin: "Admin?",
      password: "Mot de passe",
      changePassword: "Changer le mot de passe (facultatif)",
    },
    editRecipe: {
      publish: "Publier",
      couldNotPublished: "La recette ne peut pas être publiée",
      unpublish: "Ne plus publier",
      newRecipe: "Nouvelle recette",
      meta: "Données générales",
      description: "Description",
      headline: "Brève description",
      name: "Nom",
      title: "Nom",
      searchPlaceholder: "Terme de recherche",
      dateCreated: "Créée",
      datePublished: "Publiée",
      dateModified: "Modifiée",
      prepTime: "Temps de préparation",
      cookTime: "Temps de cuisson",
      totalTime: "Durée totale",
      status: "Statut",
      duration: "Duré",
      complete: "Complète",
      keywords: "Mot-clés",
      keyword: "Mot-clé",
      categories: "Catégories",
      category: "Catégorie",
      steps: "Étapes",
      nutrition: "Valeurs nutritionnelles par portion",
      ingredients: "Ingrédients",
      ingredientsAndNutrtion: "Ingrédients / Valeurs nutritionnelles",
      ingredient: "Ingrédient",
      products: "Produits",
      product: "ID produit",
      recipeYield: "Nombre de portions",
      author: "Auteur",
      contentLink: "Lien",
      availableLanguages: "Langues disponibles",
      recipePicture: "Image de recette",
      recipePictureHints: {
        title: "indices:",
        dimensionsHint: "- La dimension minimale est 300x300",
        sizeHint: "- La taille maximale est 4 MB",
      },
      actions: "Actions",
      iamount: "Quantité",
      iname: "Ingrédient",
      iunit: "Unité",
      idescription: "Description",
      calories: "Calories (en kcal)",
      carbohydrateContent: "Glucides (en g)",
      fatContent: "Lipides (en g)",
      proteinContent: "Protéines (en g)",
      searchKey: "Rechercher dans",
    },
    langEdit: {
      newLang: "Nouvelle langue",
      deleteLang: "Supprimer la langue",
      deleteLangWarn:
        "Attention, la recette de la langue {{language}} sera supprimée. Cette étape est irréversible!",
      deleteLangCheckbox:
        "Supprimer également toutes les autres langues existantes ({{length}})",
      copyLangTo:
        "Souhaitez-vous traduire la recette en {{language}}? Toutes les informations seront copiées.",
      languageExist: "disponible",
      unsavedChanges:
        "Veuillez enregistrer cette recette avant d'ajouter une nouvelle traduction",
      languageNotExist: "n'est pas disponible",
    },
    editError: {
      tooSmall:
        "L'image ({{fileName}}) est trop petite! La taille recommandée est ({{width}}x{{height}}).",
      tooBig:
        "L'image ({{fileName}}) est trop grande! La taille maximale est 4 MB",
      errorOnSave:
        "Erreur lors de l'enregistrement à {{date}}. Veuillez prendre une capture d'écran de ce site Web et contacter Nuuk avec une brève description de ce qui n'a pas fonctionné pour obtenir de l'aide.",
      emptyArray: "Aucun spécifié",
      resolveWarn: "Ces champs doivent éventuellement être vérifiés:",
      resolveError: "Ces champs doivent être vérifiés:",
      empty: "manquant, vide ou 0",
      ingredient: "Ingrédient",
      translateMe: "doit encore être traduit",
      errorOnPicUpload: "Erreur lors du téléchargement de l'image",
      errorOnPicDelete:
        "Erreur s'est produite lors de la suppression d'une image",
    },
    recipeStep: {
      images: "Images",
      step: "Étape",
      name: "Titre",
      text: "Description",
    },
    ingredients: {
      sectionTitle: "Titre du groupe d'ingrédients",
    },
    recipeStates: {
      stateLabel: "Statut",
      published: "Publié",
      pending: "Non publié",
    },
    newProduct: {
      newProductID: "Nouvel ID produit",
      newProductDialogTitle: "Ajouter un nouveau produit",
    },
  },
};

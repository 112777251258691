import React from "react";

import { UploadableImage } from "../../api/types";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { ImageGallery } from "../molecules/ImageGallery";
import { UploadButton } from "../molecules/UploadButton";
import { imgFileToBase64 } from "../../utils/imgFileToBase64";
import { TFunction } from "i18next";
import { ImageErrorDialog } from "../Dialogs/ImageErrorDialog";

export interface RecipeStepImageEditorProps {
  onChange: (value: UploadableImage[]) => void;
  images: UploadableImage[];
  uniqId: string;
  t: TFunction;
}

export const RecipeStepImageEditor = class RecipeStepEditor extends React.PureComponent<
  RecipeStepImageEditorProps,
  { activeStep: number; errorMessage?: string }
> {
  constructor(props: RecipeStepImageEditorProps) {
    super(props);
    this.state = { activeStep: 0, errorMessage: undefined };
  }
  onAddUpload = async (file: File) => {
    try {
      const imageBas64 = await imgFileToBase64(file);

      const newImage: UploadableImage = {
        base64orUrl: imageBas64,
        uploadFile: file,
        deleted: false,
      };
      const newImages = [...this.props.images, newImage];
      const newActiveStep =
        newImages.filter(({ deleted }) => deleted === false).length - 1;
      // const newActiveStep = newImages.length - 1;
      this.props.onChange(newImages);
      this.setActiveStep(newActiveStep);
    } catch (e) {
      // @ts-ignore
      this.setState({ errorMessage: e });
    }
  };
  onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (!files) return;
    (async () => {
      for (let i = 0; i < files.length; i++) {
        await this.onAddUpload(files[i]);
      }
    })();
  };
  setActiveStep = (newActiveStep: number) => {
    this.setState({ activeStep: newActiveStep });
  };

  deleteImage = () => {
    // filter deleted images
    const currentImage = this.props.images.filter(
      ({ deleted }) => deleted === false
    )[this.state.activeStep];

    let newIndex = 0;
    // get index from current (hide deleted)
    this.props.images.find((i) => {
      if (i === currentImage) {
        return true;
      } else {
        newIndex++;
        return false;
      }
    });

    let newImages = [...this.props.images];
    // remove only (new) added iamges
    if (this.props.images[newIndex].uploadFile) {
      newImages.splice(newIndex, 1);
    } else {
      // mark existing images as deleted
      newImages[newIndex] = { ...newImages[newIndex], deleted: true };
    }
    const newLength =
      newImages.filter(({ deleted }) => deleted === false).length - 1;
    if (this.state.activeStep > newLength) {
      this.setActiveStep(newLength === -1 ? 0 : newLength);
    }
    this.props.onChange(newImages);
  };

  render() {
    const { images, t, uniqId } = this.props;
    const { errorMessage } = this.state;
    const imgList = images
      .filter(({ deleted }) => deleted === false)
      .map(({ base64orUrl: src }) => ({ src }));

    return (
      <>
        <ImageErrorDialog
          errorMessage={errorMessage}
          close={() => this.setState({ errorMessage: undefined })}
        />
        <div style={{ backgroundColor: "#666" }}>
          <ImageGallery
            activeStep={this.state.activeStep}
            setActiveStep={this.setActiveStep}
            hideHeader
            noDataComponent={
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <UploadButton
                  title={t("actions:upload") as string}
                  color="#fff"
                  id={uniqId + "file-init"}
                  multiple
                  onChange={this.onChangeImage}
                />
              </div>
            }
            imgList={imgList}
          >
            <div
              style={{
                flex: 1,
                alignContent: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tooltip title={t("actions:deleteLabel") as string}>
                <Button
                  component="span"
                  size="small"
                  onClick={this.deleteImage}
                  disabled={imgList.length === 0}
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
              <UploadButton
                multiple
                title={t("actions:upload") as string}
                onChange={this.onChangeImage}
                id={uniqId + "file-after"}
              />
            </div>
          </ImageGallery>
        </div>
      </>
    );
  }
};

import { Box, IconButton, Tooltip } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RecipeDetails, RecipeSteps } from "../../../api/types";
import { createNewUniquId } from "../../../utils/createNewUniquId";
import { RecipeStep } from "../RecipeStep";

export interface RecipeStepsTabProps {
  steps: RecipeSteps[];
  setState: React.Dispatch<React.SetStateAction<RecipeDetails>>;
}

export const NEW_RECIPE_STEP: Omit<RecipeSteps, "id"> = {
  images: [],
  name: "",
  text: "",
  deleted: false,
  stepNumber: createNewUniquId(),
};

export const RecipeStepsTab = withTranslation(["recipeStep", "actions"])(
  class RecipeStepUntranslated extends React.Component<
    RecipeStepsTabProps & WithTranslation
  > {
    onAddNewStep = (
      index: number,
      newItem: RecipeSteps = {
        ...NEW_RECIPE_STEP,
        // take a random negative number
        id: createNewUniquId(),
      },
    ) => () => {
      const newInstructions = [...this.props.steps];
      newInstructions.splice(index, 0, newItem);
      this.props.setState((r) => ({
        ...r,
        recipeInstructions: newInstructions,
      }));
    };

    moveDirection = (index: number) => (dir: -1 | 1) => () => {
      const newInstructions = [...this.props.steps];
      const deleted = newInstructions.splice(index, 1);
      newInstructions.splice(index + dir, 0, deleted[0]);
      this.props.setState((r) => ({
        ...r,
        recipeInstructions: newInstructions,
      }));
    };
    onChangeStep = (index: number, key: keyof RecipeSteps, value: any) => {
      const newInstructions = [...this.props.steps];
      newInstructions[index] = {
        ...newInstructions[index],
        [key]: value,
      };
      this.props.setState((r) => ({
        ...r,
        recipeInstructions: newInstructions,
      }));
    };
    onDeleteStep = (index: number) => () => {
      let newInstructions: RecipeSteps[] = [...this.props.steps];

      // remove only (new) added steps
      if (this.props.steps[index].id < 0) {
        newInstructions = newInstructions.filter((s, i) => i !== index);
      } else {
        // mark existin steps as deleted
        newInstructions[index] = { ...newInstructions[index], deleted: true };
      }

      this.props.setState((r) => ({
        ...r,
        recipeInstructions: newInstructions,
      }));
    };
    render() {
      const { t, steps: recipeInstructions } = this.props;
      return (
        <>
          <Box mt={2} mb={1} textAlign="center">
            <Tooltip title={t("actions:addBefore") as string}>
              <IconButton
                onClick={this.onAddNewStep(0, undefined)}
                color="primary"
                size="small"
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          </Box>
          {recipeInstructions.map((instruction, index) => (
            <RecipeStep
              onAddNewStep={this.onAddNewStep(index + 1, undefined)}
              onChange={this.onChangeStep}
              moveDirection={this.moveDirection(index)}
              onDeleteStep={this.onDeleteStep(index)}
              instruction={instruction}
              length={
                recipeInstructions.filter(({ deleted }) => !deleted).length
              }
              key={instruction.id}
              index={index}
            />
          ))}
        </>
      );
    }
  },
);

import { RECIPE_LANGUAGES_ARRAY } from "../config";
import { MultiLangObject } from "../api/types";

export const clearMyLangKeys = (category: Partial<MultiLangObject>) => {
  const clearedCategory: Partial<MultiLangObject> = {};
  RECIPE_LANGUAGES_ARRAY.forEach((k) => {
    clearedCategory[k] = category[k] || "";
  });
  return clearedCategory as MultiLangObject;
};

import React from "react";
import {
  makeStyles,
  Theme,
  useTheme,
  createStyles,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LoadingComponentImage from "../atoms/LoadingComponentImage";
import { CSSProperties } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
    },
    header: {
      display: "flex",
      alignItems: "center",
      height: 50,
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.background.default,
    },
  }),
);

export interface ImageGalleryProps {
  hideHeader?: boolean;
  activeStep: number;
  imgList: Array<{ src: string; label?: string }>;
  setActiveStep: (step: number) => void;
  noDataComponent?: React.ReactNode;
  innerStyle?: CSSProperties;
}
export const ImageGallery: React.FC<ImageGalleryProps> = ({
  imgList,
  hideHeader,
  children,
  setActiveStep,
  noDataComponent,
  activeStep,
  innerStyle = {
    height: "100%",
    minHeight: "200px",
    display: "flex",
  },
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const maxSteps = imgList.length || 1;

  const handleNext = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setActiveStep(activeStep + 1);
  };

  const handleBack = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setActiveStep(activeStep - 1);
  };
  const { t } = useTranslation("actions");
  return (
    <div className={classes.root}>
      {hideHeader ? null : (
        <Paper square elevation={0} className={classes.header}>
          {imgList[activeStep].label ? (
            <Typography>{imgList[activeStep].label}</Typography>
          ) : null}
        </Paper>
      )}

      {noDataComponent && !imgList.length ? (
        <div style={innerStyle}>{noDataComponent}</div>
      ) : (
        <LoadingComponentImage
          wrapperStyle={innerStyle}
          src={imgList[activeStep]?.src}
        />
      )}
      <Paper square elevation={0} className={classes.footer}>
        <Tooltip title={t("back") as string}>
          <span>
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
          </span>
        </Tooltip>
        {children}
        <Tooltip title={t("next") as string}>
          <span>
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          </span>
        </Tooltip>
      </Paper>
    </div>
  );
};

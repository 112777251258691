import isEqual from "lodash/isEqual";
import React, { createContext, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { LocalStorage } from "../api/LocalStorage";
import { RECIPE_LANGUAGES } from "../api/types";
import { usePrevious } from "../hooks/usePrevious";

export type SearchKey =
  | "title"
  | "keyword"
  | "headline"
  | "description"
  | "author"
  | "status"
  | "ingredient"
  | "category"
  | "keyword"
  | "ingredient"
  | "product";

export interface RecipeSearchState {
  searchKey: SearchKey;
  searchValue: string;
  searchId?: number;
  rowsPerPage: number;
  offset: number;
  page: number;
}
export interface IAppState {
  language?: RECIPE_LANGUAGES;
  recipeSeach: RecipeSearchState;
}
const AppState = atom<IAppState>({
  key: "appState",
  default: LocalStorage.getAppState(),
});

export const AppStateContext = createContext<AppContextConsumerProps>(
  undefined,
);

export type AppContextConsumerProps =
  | [IAppState, React.Dispatch<React.SetStateAction<IAppState>>]
  | undefined;

export const AppContextProvider: React.FC = ({ children }) => {
  const [appState, setAppContext] = useRecoilState<IAppState>(AppState);
  const oldAppState = usePrevious(appState);

  useEffect(() => {
    if (oldAppState && !isEqual(oldAppState, appState)) {
      LocalStorage.storeAppState(appState);
    }
  }, [appState, oldAppState]);

  return (
    <AppStateContext.Provider value={[appState, setAppContext]}>
      {children}
    </AppStateContext.Provider>
  );
};

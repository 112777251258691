import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { RECIPE_LANGUAGES } from "../api/types";
import { RECIPE_LANGUAGES_ARRAY } from "../config";
import { AppStateContext } from "../provider/AppState";

export const useEditLang = (): [
  RECIPE_LANGUAGES,
  (lang: RECIPE_LANGUAGES) => void,
] => {
  const { i18n } = useTranslation();
  const context = useContext(AppStateContext);
  if (!context) throw Error("no context");
  const [appState, setAppState] = context;

  const setLang = (lang: RECIPE_LANGUAGES) => {
    setAppState((appState) => ({ ...appState, language: lang }));
  };

  const directLanguage = RECIPE_LANGUAGES_ARRAY.find(
    (lang) => i18n.language === lang,
  );
  const indirectLang = RECIPE_LANGUAGES_ARRAY.find(
    (lang) =>
      i18n.language.toLowerCase().substr(0, 2) ===
      lang.toLowerCase().substr(0, 2),
  );

  const fallback = RECIPE_LANGUAGES_ARRAY[0];

  const editLang =
    appState.language || directLanguage || indirectLang || fallback;

  return [editLang, setLang];
};

import {
  Box,
  TextField,
  Typography,
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Toolbar,
} from "@material-ui/core";
import LoadingComponent from "../atoms/LoadingComponent";
import React, { ChangeEvent } from "react";
import { MultiLangEntity, RECIPE_LANGUAGES } from "../../api/types";
import { RECIPE_LANGUAGES_ARRAY } from "../../config";
import { useEditLang } from "../../hooks/useEditLang";
import { useSimpleRequest } from "../../hooks/useSimpleRequest";
import { MultiLangActions } from "../molecules/ListMultiLangEntitiesClass";
import { TFunction } from "i18next";
import { ErrorObject } from "../../utils/typesCheck";

export interface EditEntityDialogProps
  extends Omit<MultiLangActions, "deleteEntity"> {
  entity: MultiLangEntity;
  onClose: (id?: number, isNew?: boolean) => void;
  t: TFunction;
  onChange: (
    l: RECIPE_LANGUAGES
  ) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const EditEntityDialog: React.FC<EditEntityDialogProps> = ({
  entity,
  onClose,
  onChange,
  addEntity,
  updateEntity,
  t,
}) => {
  const [{ loading, error }, setSaveState] = useSimpleRequest();
  const [editLang] = useEditLang();
  const onSave = async () => {
    setSaveState({ loading: true, error: undefined });
    try {
      let newId = entity.id;
      if (entity.id < 0) {
        newId = await addEntity(entity);
      } else {
        await updateEntity(entity);
      }
      setSaveState({ loading: false, error: undefined });
      onClose(newId, entity.id < 0);
    } catch (_e: unknown) {
      const error = _e as ErrorObject;
      setSaveState({ loading: false, error  });
    }
  };

  return (
    <Dialog open={true} onEscapeKeyDown={() => onClose()}>
      <AppBar elevation={0} style={{ position: "relative" }}>
        <Toolbar>
          <Typography variant="h6">
            {entity.id < 0
              ? t("actions:add")
              : t("actions:edit") + " ID:" + entity.id}
          </Typography>
        </Toolbar>
      </AppBar>
      <LoadingComponent isLoading={loading} errorText={error}>
        <DialogContent>
          <Typography variant="h3">
            {t("currentLang")} {t(editLang)}
          </Typography>
          <TextField
            color={"secondary"}
            margin="normal"
            label={t(editLang)}
            autoFocus={true}
            defaultValue={entity[editLang] || ""}
            onBlur={onChange(editLang)}
            fullWidth
          />
          <Box mt={2}>
            <Typography variant="h3">{t("translations")}</Typography>
          </Box>
          {RECIPE_LANGUAGES_ARRAY.filter((l) => l !== editLang).map((l) => {
            return (
              <TextField
                key={l}
                color={l === editLang ? "secondary" : "primary"}
                margin="normal"
                label={t(l)}
                autoFocus={l === editLang}
                defaultValue={entity[l] || ""}
                onBlur={onChange(l)}
                fullWidth
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>{t("actions:cancel")}</Button>
          <Button onClick={onSave} color="primary">
            {t("actions:save")}
          </Button>
        </DialogActions>
      </LoadingComponent>
    </Dialog>
  );
};

import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecipeDetails } from "../../../api/types";
import { DEFAULT_GRID_SPACING, PRIMARAY_COLOR } from "../../../styles/themes";
import { imgFileToBase64 } from "../../../utils/imgFileToBase64";
import LoadingComponentImage from "../../atoms/LoadingComponentImage";
import { ImageErrorDialog } from "../../Dialogs/ImageErrorDialog";
import { UploadButton } from "../../molecules/UploadButton";

export interface MetaTabProps {
  recipe: RecipeDetails;
  setState: React.Dispatch<React.SetStateAction<RecipeDetails>>;
}
export function MetaTab({
  setState,
  recipe: { name, headline, description, author, imageBas64 },
}: MetaTabProps) {
  const onBlur = (
    key: keyof RecipeDetails,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = event.target.value;
    setState((thisState) => ({ ...thisState, [key]: value }));
  };
  const [imgUploadError, setImgUploadError] = useState<string | undefined>(
    undefined
  );
  const { t } = useTranslation("editRecipe");
  const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files && files[0]) {
      imgFileToBase64(files[0])
        .then((imageBas64) => {
          setState((thisState) => ({
            ...thisState,
            imageBas64,
            imageFile: files[0],
          }));
        })
        .catch((e) => {
          setImgUploadError(e);
        });
    }
  };

  const uploadImage = (
    <UploadButton
      title={t("actions:upload") as string}
      color={PRIMARAY_COLOR}
      multiple={false}
      id="nodata-icon-button-file-init"
      onChange={onChangeImage}
    />
  );

  return (
    <Grid container spacing={DEFAULT_GRID_SPACING}>
      <ImageErrorDialog
        errorMessage={imgUploadError}
        close={() => setImgUploadError(undefined)}
      />
      <Grid item xs={7}>
        <TextField
          label={t("name")}
          onBlur={onBlur.bind(null, "name")}
          fullWidth
          required
          margin="normal"
          defaultValue={name}
        />
        <TextField
          label={t("headline")}
          onBlur={onBlur.bind(null, "headline")}
          fullWidth
          required
          margin="normal"
          defaultValue={headline}
        />
        <TextField
          label={t("author")}
          onBlur={onBlur.bind(null, "author")}
          fullWidth
          margin="normal"
          defaultValue={author}
        />
        <TextField
          label={t("description")}
          multiline
          rows={10}
          onBlur={onBlur.bind(null, "description")}
          fullWidth
          margin="normal"
          defaultValue={description}
        />
      </Grid>
      <Grid item xs={5}>
        <Box mt={2} mb={2}>
          <Typography align="center" variant="h3">
            {t("recipePicture")}
            <span>*</span>
          </Typography>
        </Box>
        <div
          style={{
            backgroundColor: "#666",
            border: "1px solid #000",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1,
            }}
          >
            {uploadImage}
          </div>
          <LoadingComponentImage
            wrapperStyle={{
              height: "100%",
              minHeight: "300px",
              display: "flex",
            }}
            src={imageBas64}
          />
        </div>
        <Box mt={2} mb={2}>
          <Typography align="center" variant="h3">
            {t("recipePictureHints.title")}
          </Typography>
          <Typography variant="subtitle2">
            {t("recipePictureHints.dimensionsHint")}
          </Typography>
          <Typography variant="subtitle2">
            {t("recipePictureHints.sizeHint")}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

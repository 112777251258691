import { FETCH_TIMEOUT } from "../config";
import { LocalStorage } from "./LocalStorage";
import { AuthUserInfo } from "./types";

declare global {
  interface Window {
    CACHE: { [key: string]: any };
    AUTH?: AuthUserInfo;
  }
}

export const storeAuthToken = (token?: string) => {
  LocalStorage.storeAuthToken(token);
};

export const getAuthToken = () => {
  return window.AUTH;
};

export const logout = () => {
  storeAuthToken();
  document.location.reload();
};

interface FetchWithTimeout {
  url: string;
  init?: RequestInit;
  isNotJSONResponse?: boolean;
  notRedirectOnAuthFail?: boolean;
  timeout?: number;
}

export function fakeWait<T>(dummyData: T): Promise<T> {
  return new Promise((res) =>
    setTimeout(() => {
      res(dummyData);
    }, 1000)
  );
}

async function fetchWithTimeout<T>({
  timeout,
  url,
  init,
  isNotJSONResponse,
  notRedirectOnAuthFail,
}: FetchWithTimeout): Promise<T> {
  const controller = new AbortController();
  const timeoutCB = setTimeout(
    () => controller.abort(),
    timeout !== undefined ? timeout : FETCH_TIMEOUT
  );

  return await fetch(url, {
    signal: controller.signal,
    ...init,
  })
    .then((data) => data)
    .then(async (rawResponse) => {
      if (!rawResponse.ok) {
        // unauthorized
        if (rawResponse.status === 401 && !notRedirectOnAuthFail) {
          setTimeout(() => {
            logout();
          }, 2000);
        }
        const responseParsed = await rawResponse.json();
        const code = responseParsed.error
          ? responseParsed.error.code
          : responseParsed.code;
        const hint = responseParsed.error
          ? responseParsed.error.message
          : responseParsed.message;
        const error = {
          code,
          message:
            rawResponse.status.toString() +
            "/" +
            rawResponse.statusText.toString(),
          hint,
        };
        throw error;
      } else {
        if (isNotJSONResponse) return rawResponse as unknown as T;
        return rawResponse.json() as unknown as T;
      }
    })
    .catch((err) => {
      console.log({ err });
      throw err;
    })
    .finally(() => {
      clearTimeout(timeoutCB);
    });
}

export interface RequestProps<Response, ParsedData> {
  body?: any;
  url: string;
  method?: RequestInit["method"];
  withoutAUTH?: boolean;
  cacheResponse?: boolean;
  isNotJSON?: boolean;
  isNotJSONResponse?: boolean;
  timeout?: number;
  parseData?: (r: Response) => ParsedData;
  initialLoading?: boolean;
  resetStateOnRefresh?: boolean;
  notRedirectOnAuthFail?: boolean;
}

export function requestWithTimeout<Response, ParsedData>({
  body,
  url,
  method,
  isNotJSON,
  isNotJSONResponse,
  timeout,
  parseData,
  cacheResponse,
  withoutAUTH,
  notRedirectOnAuthFail,
}: RequestProps<Response, ParsedData>) {
  let cacheKey: string | undefined = undefined;
  if (cacheResponse) {
    cacheKey = url;
    if (body) cacheKey += body;
  }
  if (cacheKey && window.CACHE[cacheKey]) {
    return Promise.resolve(window.CACHE[cacheKey] as ParsedData);
  }
  const headers: HeadersInit = isNotJSON
    ? {}
    : {
        "Content-Type": "application/json",
      };
  if (!withoutAUTH) {
    const token = getAuthToken();
    if (!token) {
      return Promise.reject(new Error("403"));
    }
    if (token) {
      headers.authorization = `Bearer ${token.jwt}`;
    }
  }
  const bodyParsed = body
    ? isNotJSON
      ? body
      : JSON.stringify(body)
    : undefined;
  return fetchWithTimeout<Response>({
    url,
    init: {
      method,
      body: bodyParsed,
      headers,
    },
    timeout,
    isNotJSONResponse,
    notRedirectOnAuthFail,
  }).then((response) => {
    let data: ParsedData;
    if (parseData) {
      data = parseData(response);
    } else {
      data = response as unknown as ParsedData;
    }
    if (cacheKey) {
      window.CACHE[cacheKey] = data;
    }
    return data;
  });
}

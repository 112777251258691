import {
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { ChangeEvent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RecipeSteps, UploadableImage } from "../../api/types";
import { DEFAULT_GRID_SPACING } from "../../styles/themes";
import { RecipeStepImageEditor } from "./RecipeStepImageEditor";

export interface RecipeStepProps {
  instruction: RecipeSteps;
  index: number;
  onAddNewStep: () => void;
  onDeleteStep: () => void;
  length: number;
  onChange: (index: number, key: keyof RecipeSteps, value: any) => void;
  moveDirection: (dir: -1 | 1) => () => void;
}
type Props = RecipeStepProps & WithTranslation;

// THIS IS A PERFORMANCE OPTIMZED COMPONENT
// Because it can be uses many times in one form (many steps in a recipe)
// so no change event is fired, onyl a onBlur
export const RecipeStep = withTranslation([
  "recipeStep",
  "actions",
  "editError",
])(
  class RecipeStepUntranslated extends React.Component<Props> {
    onChangeImage = (value: UploadableImage[]) => {
      this.props.onChange(this.props.index, "images", value);
    };

    onBlur =
      (key: "name" | "text") =>
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.stopPropagation();
        event.preventDefault();
        this.props.onChange(this.props.index, key, event.target.value);
      };

    render() {
      const {
        moveDirection,
        instruction: { name, text, images, deleted, id: stepId },
        index,
        t,
        onAddNewStep,
        onDeleteStep,
        length,
      } = this.props;
      if (deleted) return null;
      return (
        <>
          <Box p={1} style={{ backgroundColor: "#eee" }}>
            <Grid container spacing={DEFAULT_GRID_SPACING}>
              <Grid item xs={8}>
                <Box mb={1} display="flex" alignItems="center">
                  <Typography
                    style={{ lineHeight: "auto", flex: 1 }}
                    variant="h2"
                  >
                    {t("step") + " " + (index + 1) + " / " + length}
                  </Typography>
                  <div>
                    <Tooltip title={t("actions:toTop") as string}>
                      <span>
                        <IconButton
                          onClick={moveDirection(-1)}
                          disabled={index === 0}
                          color="primary"
                          size="small"
                        >
                          <ArrowUpwardIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={t("actions:toBottom") as string}>
                      <span>
                        <IconButton
                          onClick={moveDirection(1)}
                          disabled={index === length - 1}
                          color="primary"
                          size="small"
                        >
                          <ArrowDownwardIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={t("actions:deleteLabel") as string}>
                      <IconButton
                        onClick={onDeleteStep}
                        color="primary"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Box>
                <TextField
                  // DO NOT USE ONCHANGE; IT'S TO SLOW ON MANY STEPS
                  label={t("name")}
                  onBlur={this.onBlur("name")}
                  fullWidth
                  margin="normal"
                  defaultValue={name}
                />
                <TextField
                  label={t("text")}
                  multiline
                  rows={4}
                  // DO NOT USE ONCHANGE; IT'S TO SLOW ON MANY STEPS
                  onBlur={this.onBlur("text")}
                  fullWidth
                  defaultValue={text}
                />
              </Grid>
              <Grid item xs={4}>
                <RecipeStepImageEditor
                  t={t}
                  onChange={this.onChangeImage}
                  images={images}
                  uniqId={stepId.toString()}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2} mb={1} textAlign="center">
            <Tooltip title={t("actions:addAfter") as string}>
              <IconButton onClick={onAddNewStep} color="primary" size="small">
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      );
    }
  }
);

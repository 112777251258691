import React, { useState } from "react";

import { ElementChips, ElementChip } from "./ElementChips";
import { AddAssociatedProductsDialog } from "../Dialogs/AddAssociatedProductsDialog";

export interface AssociatedProductsChipsProps {
  products: string;
  updateProducts: (products: string) => void;
}

export const AssociatedProductsChips: React.FC<AssociatedProductsChipsProps> = ({
  products: productsRaw,
  updateProducts,
}) => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const products: number[] = React.useMemo(() => {
    return productsRaw
      .split(",")
      .map((p) => {
        return parseInt(p, 10);
      })
      .filter((x) => !!x);
  }, [productsRaw]);
  const elements: ElementChip<any>[] = React.useMemo(() => {
    return products.map((num) => ({
      label: num,
      key: num,
    }));
  }, [products]);

  const addProducts = () => {
    setAddDialogOpen(true);
  };
  const onProductsDelete = ({ key: k }: ElementChip<any>) => {
    const newKeywords = products.filter((newId) => newId !== k);
    updateProducts(newKeywords.join(","));
  };
  const onSave = (newNumber?: number) => {
    if (newNumber !== undefined) {
      updateProducts([...products, newNumber].join(","));
    }
    setAddDialogOpen(false);
  };

  return (
    <>
      {addDialogOpen ? <AddAssociatedProductsDialog onSave={onSave} /> : null}
      <ElementChips
        elements={elements}
        onElementDelete={onProductsDelete}
        addElement={addProducts}
      />
    </>
  );
};

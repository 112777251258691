import React, { useState, ChangeEvent } from "react";

import {
  Dialog,
  DialogContent,
  TextField,
  AppBar,
  Toolbar,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface AddAssociatedProductsDialogProps {
  onSave: (newNumber?: number) => void;
}

export const AddAssociatedProductsDialog: React.FC<AddAssociatedProductsDialogProps> = ({
  onSave,
}) => {
  const { t } = useTranslation(["newProduct", "actions"]);
  const [newNumber, setNewNumber] = useState("");

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setNewNumber(event.target.value);
  };

  return (
    <Dialog open onEscapeKeyDown={() => onSave()}>
      <AppBar elevation={0} style={{ position: "relative" }}>
        <Toolbar>
          <Typography variant="h6">{t("newProductDialogTitle")}</Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <TextField
          type="number"
          margin="normal"
          autoFocus
          label={t("newProductID")}
          value={newNumber}
          onChange={onChange}
          fullWidth
          style={{ minWidth: 300 }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onSave(parseInt(newNumber, 10));
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSave()}>{t("actions:cancel")}</Button>
        <Button
          onClick={onSave.bind(null, parseInt(newNumber, 10))}
          color="primary"
        >
          {t("actions:add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

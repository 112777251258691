import { Users } from "./types";
import { requestWithTimeout } from ".";
import { API_URL } from "../config";

export type InsertUser = Users & { password?: string };

export const deleteUser = async (id: number) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.USERS}?id=${id}`,
    method: "DELETE",
  }).then(() => true);
};

export const deleteAllSelectedUsers = (selected: number[]) =>
  Promise.all(
    selected.map((id) =>
      deleteUser(id)
        .then(() => true)
        .catch(() => false),
    ),
  ).then((results) => results.filter((e) => e !== true));

export const updateUser = async ({ email, ...user }: InsertUser) => {
  if (!user.password) {
    delete user.password;
  }
  return await requestWithTimeout<
    {
      code: number;
      userId: number;
    },
    {
      code: number;
      userId: number;
    }
  >({
    url: API_URL.USERS,
    method: "PUT",
    body: user,
  }).then(() => true);
};
export const insertUser = async ({ id, ...user }: InsertUser) => {
  if (!user.password) delete user.password;
  return await requestWithTimeout<
    {
      code: number;
      userId: number;
    },
    {
      code: number;
      userId: number;
    }
  >({
    url: API_URL.USERS,
    method: "POST",
    body: user,
  }).then(() => true);
};

import { RECIPE_LANGUAGES } from "./api/types";

export const FETCH_TIMEOUT = 30000;

export const IS_DEBUG = process.env.REACT_APP_ENV === "local";
const IS_STAGING = process.env.REACT_APP_ENV === "staging";
let API_URL_BASE = "https://api.recipes.severin.customers.nuuk.de";

if (IS_STAGING)
  API_URL_BASE = "https://staging.api.recipes.severin.customers.nuuk.de";
if (IS_DEBUG) API_URL_BASE = "http://localhost:4000";

export const API_URL = {
  USERS: API_URL_BASE + "/users",
  LOGIN: API_URL_BASE + "/login",
  PWD_RESET: API_URL_BASE + "/pwdReset",
  RECIPES_MAIN: API_URL_BASE + "/recipes/main",
  RECIPES: API_URL_BASE + "/recipes",
  UPLOAD: API_URL_BASE + "/upload",
  STEPS: API_URL_BASE + "/steps",
  STEPS_ORDER: API_URL_BASE + "/steps/order",
  RECIPE: API_URL_BASE + "/get-recipe",
  CATEGORIES: API_URL_BASE + "/categories",
  SUBCATEGORIES: API_URL_BASE + "/subcategories",
  SUBCATEGORIES_SORT_ORDER: API_URL_BASE + "/subcategories/sort-order",
  KEYWORDS: API_URL_BASE + "/keywords",
  INGREDIENTS: API_URL_BASE + "/ingredients",
  UNITS: API_URL_BASE + "/units",
};

export { API_URL_BASE };
export const RECIPE_LANGUAGES_ARRAY = Object.values(RECIPE_LANGUAGES);
export const DEFAULT_LANGUAGE = "de-DE";
export const DEFAULT_DEBOUNCE_TIME = 300;

export const KEYWORDS_SPLIT = ",";
export const TRANSLATE_ME_TAG = "Translate!:";
export const REFRESH_CATEGORIES_AFTER = 60 * 1000; //1min;

export const MIN_IMAGE_SIZE = { width: 300, height: 300 };

import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { cloneDeep, isEqual } from "lodash";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateCategory } from "../../api/categories";
import { updateSubCategory } from "../../api/subCategories";
import {
  CurrentMultiLangEntity,
  SubCatExtendEntity,
  RECIPE_LANGUAGES,
} from "../../api/types";
import { useSimpleRequest } from "../../hooks/useSimpleRequest";
import { DEFAULT_SPACING } from "../../styles/themes";
import { AlertComponent } from "../atoms/Alert";
import LoadingComponent from "../atoms/LoadingComponent";
import { SingleCategoryEditDialogContent } from "./SingleCategoryEditDialogContent";

export interface SubAndMainCategoryEditDialogProps {
  main: CurrentMultiLangEntity;
  sub: CurrentMultiLangEntity<SubCatExtendEntity>;
  onClose: (saved: boolean) => void;
}

export const SubAndMainCategoryEditDialog: React.FC<SubAndMainCategoryEditDialogProps> = ({
  main,
  sub,
  onClose,
}) => {
  const { t } = useTranslation(["categoryPage", "actions"]);
  const [tabValue, setTabValue] = useState(0);
  const [mainCategory, setMainCategory] = useState(cloneDeep(main));
  const [subCategory, setSubCategory] = useState(cloneDeep(sub));

  const [{ loading, error }, setSaveState] = useSimpleRequest();
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const onSave = () => {
    setSaveState({ loading: true, error: undefined });
    const mainChanged = !isEqual(main, mainCategory);
    const subChanged = !isEqual(sub, subCategory);
    Promise.all([
      mainChanged ? updateCategory(mainCategory) : Promise.resolve(-1),
      subChanged
        ? updateSubCategory(
            subCategory as CurrentMultiLangEntity<SubCatExtendEntity>
          )
        : Promise.resolve(-1),
    ])
      .then(() => {
        setSaveState({ loading: false, error: undefined });
        onClose(true);
      })
      .catch((e) => {
        setSaveState({ loading: false, error: e });
      });
  };

  const onChange = (l: RECIPE_LANGUAGES) => (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const trans = event.target.value;
    if (tabValue === 0) {
      setMainCategory((category) => ({ ...category, [l]: trans }));
    } else {
      setSubCategory((category) => ({ ...category, [l]: trans }));
    }
  };

  return (
    <Dialog open={true} onEscapeKeyDown={() => onClose(false)}>
      <AppBar elevation={0} style={{ position: "relative" }}>
        <Toolbar>
          <Typography variant="h6">{t("actions:edit")}</Typography>
        </Toolbar>
      </AppBar>
      <LoadingComponent isLoading={loading} errorText={error}>
        <AppBar elevation={1} position="static" color="default">
          <Tabs variant="fullWidth" value={tabValue} onChange={handleChangeTab}>
            <Tab label={t("mainSingle")} />
            <Tab label={t("subSingle")} />
          </Tabs>
        </AppBar>
        <DialogContent>
          {tabValue === 0 ? (
            <SingleCategoryEditDialogContent
              editedCategory={mainCategory}
              onChange={onChange}
            />
          ) : (
            <SingleCategoryEditDialogContent
              editedCategory={subCategory}
              onChange={onChange}
            />
          )}
          <AlertComponent
            variant="outlined"
            severity="warning"
            title={t("actions:hint")}
            style={{ marginBottom: DEFAULT_SPACING * 2 }}
          >
            {t("editAllWarn")}
          </AlertComponent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)}>{t("actions:cancel")}</Button>
          <Button onClick={() => onSave()} color="primary">
            {t("actions:save")}
          </Button>
        </DialogActions>
      </LoadingComponent>
    </Dialog>
  );
};

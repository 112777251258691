import { Box, TextField, Typography } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  CurrentMultiLangEntity,
  RECIPE_LANGUAGES,
  SubCatExtendEntity,
} from "../../api/types";
import { RECIPE_LANGUAGES_ARRAY } from "../../config";
import { useEditLang } from "../../hooks/useEditLang";

export interface SingleCategoryEditDialogContent {
  editedCategory:
    | CurrentMultiLangEntity<SubCatExtendEntity>
    | CurrentMultiLangEntity;
  onChange: (
    l: RECIPE_LANGUAGES,
  ) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const SingleCategoryEditDialogContent: React.FC<SingleCategoryEditDialogContent> = ({
  editedCategory,
  onChange,
}) => {
  const { t } = useTranslation(["editMainCategory", "actions"]);
  const [editLang] = useEditLang();

  return (
    <>
      <Typography variant="h3">
        {t("currentLang")} {t(editLang)}
      </Typography>
      <TextField
        color={"secondary"}
        margin="normal"
        label={t(editLang)}
        autoFocus={true}
        value={editedCategory[editLang] || ""}
        onChange={onChange(editLang)}
        fullWidth
      />
      <Box mt={2}>
        <Typography variant="h3">{t("translations")}</Typography>
      </Box>
      {RECIPE_LANGUAGES_ARRAY.filter((l) => l !== editLang).map((l) => {
        return (
          <TextField
            key={l}
            color={l === editLang ? "secondary" : "primary"}
            margin="normal"
            label={t(l)}
            autoFocus={l === editLang}
            value={editedCategory[l] || ""}
            onChange={onChange(l)}
            fullWidth
          />
        );
      })}
    </>
  );
};

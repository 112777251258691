import React from "react";
import { useTranslation } from "react-i18next";
import { logout } from "../../api";
import { ErrorObject } from "../../utils/typesCheck";
import { RECIPE_LANGUAGES } from "../../api/types";
export const WrapAPIError: React.FC<{
  error: ErrorObject;
}> = ({ error }) => {
  let errorCode = 0;
  let errorMessage = error.message;
  let languages: string[] = [];
  const { t } = useTranslation("errorCodes");
  console.log({ error });

  if (error.code !== undefined) {
    errorCode = error.code;
  } else {
    errorCode = parseInt(error.message.substring(0, 3));
  }

  if (errorCode === 500) {
    errorMessage = t("500");
  }
  if (errorCode === 400) {
    errorMessage = t("400");
  }
  if (
    errorMessage.startsWith("Failed to fetch") ||
    errorMessage.toLowerCase().startsWith("the user aborted a request")
  ) {
    errorMessage = t("failedToFetch");
  }
  if (errorCode === 403) {
    errorMessage = t("403");
  }
  if (errorCode === 404) {
    errorMessage = t("404");
  }
  if (errorCode === 409) {
    for (const langKey of Object.keys(RECIPE_LANGUAGES)) {
      if (error.hint!.includes(langKey)) languages.push(langKey);
    }
    errorMessage = t("409") + ` ${languages.join(", ")}`;
  }
  if (errorCode === 401) {
    errorMessage = t("401");
    setTimeout(() => {
      logout();
    }, 2000);
  }
  return <>{errorMessage}</>;
};

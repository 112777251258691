import { TextField, TextFieldProps } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import debounce from "lodash/debounce";
import React from "react";

export interface DebouncedSearchState {
  searchValue: string;
  debouncedSearchValue: string;
}
export interface DebouncedSearchProps {
  updateSearchValue: (value: string) => void;
  debounceTime: number;
  value?: string;
  label: string;
  txtFp?: TextFieldProps;
}
export class DebouncedSearch extends React.Component<
  DebouncedSearchProps,
  DebouncedSearchState
> {
  static getDerivedStateFromProps = (
    nextProps: DebouncedSearchProps,
    nextState: DebouncedSearchState,
  ) => {
    if (nextProps.value !== nextState.debouncedSearchValue) {
      return {
        searchValue: nextProps.value || "",
        debouncedSearchValue: nextProps.value || "",
      };
    }
    return null;
  };

  constructor(props: DebouncedSearchProps) {
    super(props);
    this.state = {
      searchValue: props.value || "",
      debouncedSearchValue: props.value || "",
    };
  }
  onSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => this.setSearchValue(event.target.value);

  bubbleSearchValue = (value: string) => {
    this.props.updateSearchValue(value);
    this.setState({ debouncedSearchValue: value });
  };
  setDebounced =
    this.props.debounceTime !== 0
      ? debounce(this.bubbleSearchValue, this.props.debounceTime)
      : this.bubbleSearchValue;

  setSearchValue = (value: string, debounced = true) => {
    this.setState({ searchValue: value });
    if (debounced) this.setDebounced(value);
    else this.bubbleSearchValue(value);
  };

  render() {
    const { txtFp, label } = this.props;
    const { searchValue } = this.state;

    return (
      <TextField
        value={searchValue}
        onChange={this.onSearchChange}
        fullWidth
        label={label}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => this.setSearchValue("", false)}
              >
                {searchValue ? <ClearIcon color="error" /> : <SearchIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...txtFp}
      />
    );
  }
}
// export const DebouncedSearch: React.FC<DebouncedSearchProps> = ({
//   updateSearchValue,
//   debounceTime,
//   value,
//   txtFp,
//   label,
// }) => {
//   const [searchValue, setSearchValue] = useState(value || "");
//   const setDebounced = useMemo(
//     () =>
//       debounceTime !== 0
//         ? debounce((value: string) => updateSearchValue(value), debounceTime)
//         : (value: string) => updateSearchValue(value),
//     [updateSearchValue, debounceTime],
//   );
//   // useEffect(() => {
//   //   setDebounced(value || "");
//   //   // eslint-disable-next-line react-hooks/exhaustive-deps
//   // }, [value]);

//   const onSearchChange = (
//     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
//   ) => {
//     const value = event.target.value;
//     setSearchValue(value);
//     setDebounced(value);
//   };
//   return (
//     <Box mb={3}>
//       <TextField
//         value={searchValue}
//         onChange={onSearchChange}
//         fullWidth
//         label={label}
//         InputProps={{
//           endAdornment: (
//             <InputAdornment position="end">
//               <IconButton
//                 aria-label="toggle password visibility"
//                 onClick={() => setSearchValue("")}
//               >
//                 {searchValue ? <ClearIcon /> : <SearchIcon />}
//               </IconButton>
//             </InputAdornment>
//           ),
//         }}
//         {...txtFp}
//       />
//     </Box>
//   );
// };

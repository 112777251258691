import { CSSProperties } from "@material-ui/styles";
import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSimpleRequest } from "../../hooks/useSimpleRequest";
import LoadingComponent from "./LoadingComponent";

export interface LoadingComponentImageProps {
  src?: string;
  wrapperClassName?: string;
  wrapperStyle?: CSSProperties;
}

const LoadingComponentImage: FunctionComponent<LoadingComponentImageProps> = ({
  wrapperClassName,
  wrapperStyle,
  src,
}) => {
  const [{ loading, error }, setReq] = useSimpleRequest();
  const { t } = useTranslation("errorCodes");
  useEffect(() => {
    const img = new Image();
    if (src) setReq({ loading: true, error: undefined });
    img.onload = () => {
      setReq({ loading: false, error: undefined });
    };
    img.onerror = () => {
      setReq({
        loading: false,
        error: {
          code: 404,
          message: src + " " + t("404"),
          hint: src + " " + t("404"),
        },
      });
    };
    if (src) img.src = src;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <LoadingComponent isLoading={loading} errorText={error}>
      <div className={wrapperClassName} style={wrapperStyle}>
        {!loading && !error ? (
          <div
            style={{
              backgroundImage: `url(${src})`,
              width: "100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          />
        ) : null}
      </div>
    </LoadingComponent>
  );
};

export default LoadingComponentImage;

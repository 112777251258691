import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { addUnit, deleteUnit, updateUnit } from "../../api/units";
import { useEditLang } from "../../hooks/useEditLang";
import { RecipeDataContext } from "../../provider/RecipeData";
import { useDefaultStyles } from "../../styles/themes";
import LoadingComponent from "../atoms/LoadingComponent";
import { ListMultiLangEntitiesClass } from "./ListMultiLangEntitiesClass";

export const ListUnits: React.FC = () => {
  const [
    {
      units: { data, loading, error },
    },
    { refreshUnits },
  ] = useContext(RecipeDataContext);
  const { t } = useTranslation(["editUnit", "tbl"]);
  const classes = useDefaultStyles();
  const [searchValue, setSearchValue] = useState("");
  const [editLang] = useEditLang();
  const filteredUnits = React.useMemo(() => {
    if (!data) return [];
    if (!searchValue) return data;
    return data.filter(
      (unit) =>
        unit[editLang]?.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
    );
  }, [data, searchValue, editLang]);

  return (
    <LoadingComponent isLoading={loading} errorText={error}>
      <ListMultiLangEntitiesClass
        t={t}
        setSearchValue={setSearchValue}
        classes={classes}
        searchValue={searchValue}
        entityState={filteredUnits}
        refresh={refreshUnits}
        deleteEntity={deleteUnit}
        addEntity={addUnit}
        updateEntity={updateUnit}
      />
    </LoadingComponent>
  );
};

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";

export interface SplitButtonOptions {
  label: string;
  key: string;
  disabled?: boolean;
}
export interface SplitButtonProps {
  options: SplitButtonOptions[];
  buttonLabel?: string;
  selectedKey: string;
  disabled?: boolean;
  setSelected: (selectedKey: string) => void;
}

export function SplitButton({
  options,
  buttonLabel,
  selectedKey,
  setSelected,
  disabled,
}: SplitButtonProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const selected = options.find(({ key }) => selectedKey === key);
  //   const selectedIndex = options.find(({ selected }) => selected);
  //   const [selectedIndex, setSelectedIndex] = React.useState(1);

  //   const handleClick = () => {
  //     console.info(`You clicked ${options[selectedIndex]}`);
  //   };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelected(options[index].key);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        disabled={disabled}
        aria-label="split button"
        size="small"
      >
        <Button>
          {buttonLabel ? buttonLabel + " " : ""}
          {selected?.label}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined}>
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="split-button-menu">
              {options.map(({ label, disabled, key }, index) => (
                <MenuItem
                  key={key}
                  disabled={disabled}
                  selected={selected?.label === label}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {label}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
}

import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  // createMuiTheme,
  MuiThemeProvider,
  darken,
  makeStyles,
  createStyles,
} from "@material-ui/core/styles";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import * as React from "react";

export const PRIMARAY_COLOR = "#003f7f";
export const DARKEN_PRIMARAY_COLOR = darken(PRIMARAY_COLOR, 0.3);
export const SECONDARAY_COLOR = "#e5302e";
export const DEFAULT_SPACING = 8;
export const DEFAULT_GRID_SPACING = 3;
export const CONTENT_PADDING = 3 * DEFAULT_SPACING;
export const useDefaultStyles = makeStyles(() =>
  createStyles({
    iconLeft: {
      marginRight: DEFAULT_SPACING,
    },
    iconRight: {
      marginLeft: DEFAULT_SPACING,
    },
    noUpperCase: {
      textTransform: "none",
    },
    noPadding: {
      padding: 0,
    },
  }),
);

export const greenTheme: ThemeOptions = {
  typography: {
    fontSize: 16,
    htmlFontSize: 16,
    h1: {
      fontWeight: 300,
      fontSize: "3em",
    },
    h2: {
      fontSize: "2em",
      color: PRIMARAY_COLOR,
      fontWeight: 300,
    },
    h3: {
      fontSize: "1.5em",
      color: PRIMARAY_COLOR,
      fontWeight: 300,
    },
  },
  overrides: {
    MuiFormLabel: {
      root: { color: DARKEN_PRIMARAY_COLOR },
    },
    MuiTableHead: {
      root: {
        backgroundColor: "#888",
      },
    },
    MuiTableCell: {
      head: {
        color: "#fff",
      },
    },
  },
  palette: {
    secondary: {
      main: SECONDARAY_COLOR,
    },
    primary: {
      main: PRIMARAY_COLOR,
    },
  },
  spacing: DEFAULT_SPACING,
};

export const RPTheme = class Theme extends React.Component {
  public render() {
    const theme = createMuiTheme(greenTheme);
    return (
      <MuiThemeProvider theme={theme}>{this.props.children}</MuiThemeProvider>
    );
  }
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ListRecipeSearchDetails,
  useStateLessRecipeSearch,
} from "../../hooks/useRecipeSearch";
import { RecipeSearchState } from "../../provider/AppState";
import { RMSTableLazyLoad, RMSTableData, RMSTableHeadCell } from "../RMSTable";
import moment from "moment";
import { TFunction } from "i18next";
import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { getEditRecipeUrl } from "../../AppNavigation";

const disablePadding = true;
const getHeadCells = (
  t: TFunction,
): RMSTableHeadCell<RMSTableData<ListRecipeSearchDetails>>[] => {
  return [
    {
      disablePadding: false,
      width: "30%",
      id: "name",
      label: t("name"),
      numeric: false,
      sortable: false,
    },
    {
      disablePadding,
      width: "35%",
      id: "headline",
      label: t("headline"),
      numeric: false,
      sortable: false,
    },
    {
      disablePadding,
      width: "15%",
      id: "dateModified",
      label: t("dateModified"),
      numeric: false,
      sortable: false,
      formatter: (r) => {
        return r.dateModified
          ? moment(r.dateModified).format(t("date:fulldateFormat"))
          : null;
      },
    },
  ];
};

export const ListRecipes: React.FC<RecipeSearchState> = (props) => {
  // const {searchValue} = props;
  const history = useHistory();
  const [searchState, setSearchState] = useState<RecipeSearchState>(props);
  const { t } = useTranslation([
    "editRecipe",
    "tbl",
    "langEdit",
    "recipeStates",
    "date",
  ]);
  const { error, loading, data, rowsPerPage, page } = useStateLessRecipeSearch(
    searchState,
  );
  const { searchValue } = searchState;
  const newSearchValue = props.searchValue;

  useEffect(() => {
    if (newSearchValue !== searchValue) {
      setSearchState((s) => ({ ...s, searchValue: newSearchValue }));
    }
  }, [searchValue, newSearchValue]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setSearchState((s) => ({
      ...s,
      page: newPage,
    }));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setSearchState((s) => ({ ...s, page: 0, rowsPerPage }));
  };
  return (
    <Box mt={2}>
      <Box mb={2}>
        <Typography variant="h3">
          {t("tbl:recipesSearch", { searchValue })}
        </Typography>
      </Box>
      <RMSTableLazyLoad
        error={error}
        rows={
          data ? (data.recipes as RMSTableData<ListRecipeSearchDetails>[]) : []
        }
        rowCount={data ? data.count : 0}
        startOrderBy={"status"}
        pagination={{
          labelRowsPerPage: t("tbl:labelRowsPerPage"),
        }}
        isLoading={loading}
        cellDefinition={getHeadCells(t)}
        page={page}
        rowsPerPage={rowsPerPage}
        onClick={(r) => {
          history.push(getEditRecipeUrl(r.mainId));
        }}
        isLazyLoad
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

import { useContext } from "react";
import { SetterOrUpdater } from "recoil";
import { RecipeSearchDetails, RECIPE_LANGUAGES } from "../api/types";
import { RMSTableData } from "../components/RMSTable";
import { API_URL, TRANSLATE_ME_TAG } from "../config";
import {
  AppStateContext,
  IAppState,
  RecipeSearchState,
} from "../provider/AppState";
import { ErrorObject } from "../utils/typesCheck";
import { useEditLang } from "./useEditLang";
import { useRequestURL } from "./useRequestURL";

export type ListRecipeSearchDetails = RecipeSearchDetails & { actions?: any };
export type RecipeDataType = {
  count: number;
  recipes: Array<RMSTableData<RecipeSearchDetails & { id: number }>>;
};

export const parseSearchRecipeData = (data: {
  count: number;
  recipes: Array<RMSTableData<RecipeSearchDetails>>;
}): RecipeDataType => {
  return {
    count: data.count,
    recipes: data.recipes.map((r) => ({ ...r, id: r.recipeId })),
  };
};

export type UseRecipeSearchState = RecipeSearchState & {
  loading: boolean;
  data: RecipeDataType | undefined;
  error?: ErrorObject;
  language: RECIPE_LANGUAGES;
};

export const useRecipeSearch = (): [
  UseRecipeSearchState,
  SetterOrUpdater<IAppState>,
  () => void //   refresh
] => {
  const context = useContext(AppStateContext);
  const [language] = useEditLang();
  if (!context) throw Error("no context");
  const [
    {
      recipeSeach: {
        searchKey,
        searchValue,
        rowsPerPage,
        offset,
        page,
        searchId,
      },
    },
    setAppContext,
  ] = context;

  const [{ loading, data, error }, refresh] = useRequestURL({
    url: `${
      API_URL.RECIPES
    }/search/?offset=${offset}&pageSize=${rowsPerPage}&language=${language}&searchKey=${encodeURIComponent(
      searchKey
    )}&searchValue=${encodeURIComponent(searchValue)}`,
    method: "GET",
    parseData: parseSearchRecipeData,
  });

  return [
    {
      loading,
      data,
      error,
      language,
      searchKey,
      searchValue,
      rowsPerPage,
      searchId,
      offset,
      page,
    },
    setAppContext,
    refresh,
  ];
};

export const useStateLessRecipeSearch = (
  recipeSearch: RecipeSearchState
): RecipeSearchState & {
  loading: boolean;
  data: RecipeDataType | undefined;
  error?: ErrorObject;
} => {
  const [language] = useEditLang();
  const {
    searchKey,
    searchValue: searchValueRaw,
    rowsPerPage,
    offset,
    page,
  } = recipeSearch;

  const searchValue = searchValueRaw.replace(TRANSLATE_ME_TAG, "");

  const [{ loading, data, error }] = useRequestURL({
    url: `${
      API_URL.RECIPES
    }/search/?offset=${offset}&pageSize=${rowsPerPage}&language=${language}&searchKey=${encodeURIComponent(
      searchKey
    )}&searchValue=${encodeURIComponent(searchValue)}`,
    method: "GET",
    parseData: parseSearchRecipeData,
  });

  return {
    loading,
    data,
    error,
    searchKey,
    searchValue,
    rowsPerPage,
    offset,
    page,
  };
};

import React from "react";

import { useTranslation } from "react-i18next";
import { BaseLayout } from "../layout/BaseLayout";
import { Typography, Box, Button } from "@material-ui/core";
import { getAuthToken } from "../../api";
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useDefaultStyles } from "../../styles/themes";

export const NotFoundPage: React.FC = () => {
  const { t } = useTranslation("notFoundPage");
  const classes = useDefaultStyles();
  const token = getAuthToken();

  const child = (
    <Box p={6} textAlign="center">
      <Typography variant="h1">{"404 - " + t("title")}</Typography>
      <Box p={6}>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/"
        >
          {t("toStartPage")}
          <ChevronRightIcon className={classes.iconRight} />
        </Button>
      </Box>
    </Box>
  );
  if (!token) {
    return child;
  }
  return <BaseLayout>{child}</BaseLayout>;
};

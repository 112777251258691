import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
export const useRMSTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      tableLayout: "fixed",
    },
    td: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  }),
);

import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { insertMainCategory } from "../../api/categories";
import { clearMyLangKeys } from "../../utils/clearMyLangKeys";
import { insertSubCategory } from "../../api/subCategories";
import { RecipeCategories } from "../../api/types";
import { useEditLang } from "../../hooks/useEditLang";
import { useSimpleRequest } from "../../hooks/useSimpleRequest";
import { RecipeDataContext } from "../../provider/RecipeData";
import LoadingComponent from "../atoms/LoadingComponent";
import { CategoryAutoComplete } from "../molecules/CategoryAutoComplete";
import { ErrorObject } from "../../utils/typesCheck";

export interface AddCategoryDialogChipsProps {
  categories: RecipeCategories[];
  onAdd: (cat?: RecipeCategories) => void;
}

export const AddCategoryDialogChips: React.FC<AddCategoryDialogChipsProps> = ({
  categories,
  onAdd,
}) => {
  const [newMain, setNewMain] = useState("");
  const [newSub, setNewSub] = useState("");
  const { t } = useTranslation(["addCategory", "actions", "categoryPage"]);

  const [
    {
      mCat: { data: mCatData },
      sCat: { data: sCatData },
    },
    { refreshMainCategories, refreshSubCategories },
  ] = useContext(RecipeDataContext);
  const onChangeSub = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewSub(event.target.value);
  };
  const onChangeMain = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewMain(event.target.value);
  };
  const [{ loading, error }, setSaveState] = useSimpleRequest();
  const [language] = useEditLang();
  const onCreate = async () => {
    let mainCat = mCatData?.find(
      ({ name }) => name.toLowerCase() === newMain.toLowerCase()
    );
    let subCat = sCatData?.find(
      ({ name }) => name.toLowerCase() === newSub.toLowerCase()
    );
    let mainCatId = mainCat?.id;
    let subCatId = subCat?.id;

    setSaveState({ loading: true, error: undefined });
    try {
      if (!mainCatId && newMain) {
        mainCatId = await insertMainCategory({
          id: -1,
          name: newMain,
          ...clearMyLangKeys({ [language]: newMain }),
        });
        refreshMainCategories();
      }
      if (mainCatId) {
        if (!subCatId && newSub) {
          subCatId = await insertSubCategory({
            id: -1,
            categoryId: mainCatId,
            name: newSub,
            ...clearMyLangKeys({ [language]: newSub }),
            sortOrder: 0 // fiy a TS error, don't know id this is really necessary
          });
          refreshSubCategories();
        }
        if (subCatId) {
          onAdd({
            catId: mainCatId,
            subcatId: subCatId,
            // isTranslated: !!newMain && !!newSub,
          });
        }
      }
      setSaveState({ loading: false, error: undefined });
    } catch (_e: unknown) {
      const error = _e as ErrorObject;
      setSaveState({ loading: false, error  });
    }
  };

  return (
    <>
      <Dialog open onEscapeKeyDown={() => onAdd()}>
        <LoadingComponent isLoading={loading} errorText={error}>
          <div style={{ width: 500 }}>
            <AppBar elevation={0} style={{ position: "relative" }}>
              <Toolbar>
                <Typography variant="h6">{t("addCategoryTitle")}</Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <Box mt={2} mb={2}>
                <CategoryAutoComplete
                  editLang={language}
                  selected={categories}
                  onSelect={onAdd}
                />
              </Box>
              <Box mt={2} mb={2}>
                <TextField
                  fullWidth
                  value={newMain}
                  onChange={onChangeMain}
                  label={[
                    t("categoryPage:new"),
                    t("categoryPage:main"),
                    t("actions:create"),
                  ].join(" ")}
                />
              </Box>
              <Box mt={2} mb={2}>
                <TextField
                  fullWidth
                  value={newSub}
                  onChange={onChangeSub}
                  label={[
                    t("categoryPage:new"),
                    t("categoryPage:sub"),
                    ...(newMain ? [t("categoryPage:of"), newMain] : []),
                    t("actions:create"),
                  ].join(" ")}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onAdd()}>{t("actions:cancel")}</Button>
              <Button
                color="primary"
                disabled={!newSub || !newMain}
                onClick={() => onCreate()}
              >
                {t("actions:create")}
              </Button>
            </DialogActions>
          </div>
        </LoadingComponent>
      </Dialog>
    </>
  );
};

import { Checkbox, Container, Fab } from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { TFunction } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Users } from "../../api/types";
import { deleteAllSelectedUsers, updateUser } from "../../api/users";
import { API_URL, DEFAULT_DEBOUNCE_TIME } from "../../config";
import { useRequestURL } from "../../hooks/useRequestURL";
import { useSimpleRequest } from "../../hooks/useSimpleRequest";
import { DebouncedSearch } from "../atoms/DebouncedSearch";
import { FabContainer } from "../atoms/FabContainer";
import { ConfirmDialog } from "../Dialogs/ConfirmDialog";
import { UserEditDialog } from "../Dialogs/UserEditDialog";
import { BaseLayout } from "../layout/BaseLayout";
import { RMSTable, RMSTableData, RMSTableHeadCell } from "../RMSTable";

const parseUserData = ({
  users,
}: {
  users: Users[];
}): Array<RMSTableData<Users>> => {
  return users;
};

const getHeadCells = (
  t: TFunction,
  changeAdmin: (user: RMSTableData<Users>, event: React.MouseEvent) => void
): RMSTableHeadCell<RMSTableData<Users>>[] => [
  // {
  //   disablePadding: true,
  //   id: "id",
  //   label: t("id"),
  //   numeric: false,
  //   sortable: true,
  // },
  {
    disablePadding: true,
    id: "firstName",
    label: t("firstName"),
    numeric: false,
    sortable: true,
  },
  {
    disablePadding: true,
    id: "lastName",
    label: t("lastName"),
    numeric: false,
    sortable: true,
  },
  {
    disablePadding: true,
    id: "email",
    label: t("email"),
    numeric: false,
    sortable: true,
  },
  {
    disablePadding: true,
    sortable: true,
    id: "admin",
    label: t("admin"),
    numeric: false,
    formatter: (v: RMSTableData<Users>) => (
      <Checkbox
        color="default"
        checked={v.admin}
        onClick={changeAdmin.bind(null, v)}
      />
    ),
  },
];

const NewUser: Users = {
  id: -1,
  firstName: "",
  lastName: "",
  admin: false,
  email: "",
};

export const UsersPage: React.FC = () => {
  const [{ loading, data, error }, refresh] = useRequestURL({
    url: API_URL.USERS,
    method: "GET",
    parseData: parseUserData,
  });
  const [editUser, setEditUser] = useState<Users | undefined>(undefined);
  const [selected, setSelected] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [delReq, setDeleteRequests] = useSimpleRequest();

  const { t } = useTranslation(["usersTable", "actions"]);

  const onEditClick = (v?: RMSTableData<Users>) =>
    setEditUser(v || { ...NewUser });

  const changeAdmin = (user: RMSTableData<Users>, event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    updateUser({
      ...user,
      id: user.id,
      admin: user.admin === false,
      password: "",
    });
    refresh();
  };

  const onClose = (refreshData: boolean) => {
    setEditUser(undefined);
    if (refreshData) refresh();
  };

  const deleteUsers = (confirmed: boolean) => {
    setDeleteConfirm(false);
    if (confirmed) {
      setDeleteRequests({ loading: true, error: undefined });
      deleteAllSelectedUsers(selected).then((errorIds) => {
        setDeleteRequests({
          loading: false,
          error: errorIds.length
            ? {
                code: undefined,
                message: errorIds.length + t("usersCouldNotBeDelete"),
                hint: undefined,
              }
            : undefined,
        });
        refresh();
      });
    }
  };
  const rows = (data || []).filter(({ firstName, lastName, email }) => {
    return [firstName, lastName, email]
      .join("")
      .toLowerCase()
      .includes(searchValue.toLowerCase());
  });
  return (
    <BaseLayout>
      {editUser ? <UserEditDialog user={editUser} onClose={onClose} /> : null}
      <ConfirmDialog
        open={deleteConfirm}
        doIt={deleteUsers}
        text={t("confirmDelete", { count: selected.length })}
        okLabel={t("actions:deleteLabel")}
        cancelLabel={t("actions:cancel")}
      />{" "}
      <Container style={{ padding: 0 }}>
        <Box mb={3}>
          <DebouncedSearch
            debounceTime={DEFAULT_DEBOUNCE_TIME}
            updateSearchValue={setSearchValue}
            label={t("actions:search")}
          />
        </Box>
        <RMSTable
          isLoading={loading || delReq.loading}
          error={error || delReq.error}
          startOrderBy={"email"}
          hoverItemId={editUser?.id}
          cellDefinition={getHeadCells(t, changeAdmin)}
          onClick={onEditClick}
          setSelected={setSelected}
          selected={selected}
          rows={rows}
        />
        <FabContainer isFixed>
          {selected.length ? (
            <Fab color="secondary" onClick={() => setDeleteConfirm(true)}>
              <DeleteIcon />
            </Fab>
          ) : null}
          <Fab color="primary" onClick={onEditClick.bind(null, undefined)}>
            <AddIcon />
          </Fab>
        </FabContainer>
      </Container>
    </BaseLayout>
  );
};

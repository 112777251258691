import React from "react";
import { Box, Typography } from "@material-ui/core";

export interface ErrorBoxProps {
  title?: string;
  text: string;
}
export const ErrorBox: React.FC<ErrorBoxProps> = ({
  title,
  text,
  children,
}) => {
  return (
    <Box
      p={2}
      mt={2}
      mb={2}
      color="#f00"
      style={{ backgroundColor: "#fcc", border: "1px solid #f00" }}
    >
      {title ? <Typography variant="h3">{title}</Typography> : null}
      <Typography>{text}</Typography>
      {children}
    </Box>
  );
};

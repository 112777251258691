import { requestWithTimeout } from ".";
import { API_URL } from "../config";
import { clearMyLangKeys } from "../utils/clearMyLangKeys";
import { Ingredient } from "./types";

export interface IngredientsResponse {
  code: number;
  ingredients: Ingredient[];
}

export const addIngredient = async ({
  id, //remove me from request
  ...ingredient
}: Ingredient) => {
  return await requestWithTimeout<unknown, { ingredientId: number }>({
    url: `${API_URL.INGREDIENTS}`,
    method: "POST",
    body: clearMyLangKeys(ingredient),
  }).then(({ ingredientId }) => ingredientId);
};
export const updateIngredient = async ({
  id, //remove me from request
  ...ingredient
}: Ingredient) => {
  return await requestWithTimeout<unknown, { ingredientId: number }>({
    url: `${API_URL.INGREDIENTS}`,
    method: "PUT",
    body: {
      id,
      name: clearMyLangKeys(ingredient),
    },
  }).then(() => id);
};

export const deleteIngredient = async (id: number) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.INGREDIENTS}?id=${id}`,
    method: "DELETE",
  }).then(() => true);
};

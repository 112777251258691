import React from "react";

import { ConfirmDialog } from "./ConfirmDialog";
import { MIN_IMAGE_SIZE } from "../../config";
import { useTranslation } from "react-i18next";

export interface ImageErrorDialogProps {
  close: () => void;
  errorMessage?: any;
}

export const ImageErrorDialog: React.FC<ImageErrorDialogProps> = ({
  errorMessage,
  close,
}) => {
  const { t } = useTranslation("editError");
  let name = errorMessage
    ? errorMessage instanceof Error
      ? errorMessage.message.split(":")
      : errorMessage.split(":")
    : undefined;
  let fileName = name && name.length > 1 ? name[1] : "unknown";
  return (
    <ConfirmDialog
      open={!!errorMessage}
      okLabel={t("actions:cancel")}
      doIt={close}
      text={
        errorMessage && /tooSmall/.test(errorMessage)
          ? t("editError:tooSmall", { ...MIN_IMAGE_SIZE, fileName })
          : errorMessage && /tooBig/.test(errorMessage)
          ? t("editError:tooBig", { fileName })
          : t("errorOnPicUpload")
      }
    />
  );
};

import React, { useCallback, useState } from "react";
import { RequestProps, requestWithTimeout } from "../api";
import { ErrorObject } from "../utils/typesCheck";

window.CACHE = {};

export interface ReqState<T> {
  loading: boolean;
  error: ErrorObject | undefined;
  data: T | undefined;
}

export function useRequestURL<R, T>({
  body,
  url,
  method,
  isNotJSON,
  timeout,
  parseData,
  cacheResponse,
  withoutAUTH,
  initialLoading = true,
  resetStateOnRefresh = true,
}: RequestProps<R, T>): [ReqState<T>, () => void] {
  const [reqVersion, setReqVersion] = useState(0);
  const [{ loading, data, error }, setState] = useState<ReqState<T>>({
    loading: initialLoading,
    data: undefined,
    error: undefined,
  });

  const refresh = useCallback(() => {
    setReqVersion((r) => r + 1);
  }, []);

  React.useEffect(() => {
    if (resetStateOnRefresh)
      setState({ data, loading: initialLoading, error: undefined });
    requestWithTimeout<R, T>({
      url,
      body,
      method,
      withoutAUTH,
      cacheResponse,
      isNotJSON,
      timeout,
      parseData,
    })
      .then((data) => {
        setState({ data, loading: false, error: undefined });
      })
      .catch((e) => {
        console.error(e);
        setState({ data, loading: false, error: e });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, reqVersion]);

  return [{ loading, data, error }, refresh];
}

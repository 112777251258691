import { useMemo } from "react";
import { RecipeMainCategoriesResponse } from "../../api/categories";
import {
  RECIPE_LANGUAGES,
  CurrentMultiLangEntity,
  MultiLangEntityState,
  MultiLangEntity,
} from "../../api/types";
import { API_URL } from "../../config";
import { useEditLang } from "../../hooks/useEditLang";
import { ReqState, useRequestURL } from "../../hooks/useRequestURL";
import { getLangLabel } from "../../utils/getLangLabel";

const parseMainCatData = (
  data: RecipeMainCategoriesResponse
): MultiLangEntity[] => {
  return data.categories.map((category) => {
    const tmpCategory: MultiLangEntity<{}> = {
      id: category.id,
      "de-DE": category["de-DE"].name,
      "en-US": category["en-US"].name,
      "fr-FR": category["fr-FR"].name,
      "it-IT": category["it-IT"].name,
      "nl-NL": category["nl-NL"].name,
    };
    return tmpCategory;
  });
};

const mergeMainCatDataToMainCategoriesState = (
  language: RECIPE_LANGUAGES,
  categories: MultiLangEntity[]
): MultiLangEntityState => {
  // const defaultName = categories.find(({id, ...language})=>)
  return categories.map(({ id, ...langOb }) => {
    const main: CurrentMultiLangEntity = {
      id,
      name: getLangLabel(langOb, language),
      ...langOb,
    };
    return main;
  });
};

export const useMainCategories = (): [
  ReqState<MultiLangEntityState>,
  () => void
] => {
  const [language] = useEditLang();

  const [mCatUnparsed, refreshMainCategories] = useRequestURL({
    url: `${API_URL.CATEGORIES}`,
    method: "GET",
    resetStateOnRefresh: false,
    parseData: parseMainCatData,
  });

  const mCat: ReqState<MultiLangEntityState> = useMemo(() => {
    const { data, loading, error } = mCatUnparsed;
    return {
      loading,
      error,
      data: data
        ? mergeMainCatDataToMainCategoriesState(language, data)
        : undefined,
    };
  }, [language, mCatUnparsed]);

  return [mCat, refreshMainCategories];
};

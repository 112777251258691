import { IAppState, RecipeSearchState } from "../provider/AppState";
import { AuthUserInfo } from "./types";

enum LKeys {
  "AUTH" = "AUTH",
  "APP_STATE" = "APP_STATE",
}

const INITIAL_RECIPE_SEARCH: RecipeSearchState = {
  searchKey: "headline",
  searchValue: "",
  rowsPerPage: 10,
  offset: 0,
  page: 0,
};

export const parseAuthToken = (
  authToken?: string,
): AuthUserInfo | undefined => {
  try {
    if (!authToken) return undefined;
    const authInfo = JSON.parse(atob(authToken.split(".")[1])) as Omit<
      AuthUserInfo,
      "jwt"
    >;
    const isValid = Date.now() < authInfo.exp * 1000;

    if (!isValid) return undefined;
    return { ...authInfo, jwt: authToken };
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const INITIAL_APP_STATE: IAppState = { recipeSeach: INITIAL_RECIPE_SEARCH };
export const LocalStorage = {
  storeAuthToken: (jwtToken?: string) => {
    const parsedToken = parseAuthToken(jwtToken);
    if (!parsedToken) {
      localStorage.removeItem(LKeys.AUTH);
    } else {
      localStorage.setItem(LKeys.AUTH, parsedToken.jwt);
      document.location.replace("/");
    }
  },
  getAuthToken: () => {
    return localStorage.getItem(LKeys.AUTH) || undefined;
  },
  storeAppState: (appState: IAppState) => {
    localStorage.setItem(LKeys.APP_STATE, JSON.stringify(appState));
  },
  getAppState: () => {
    const appState = localStorage.getItem(LKeys.APP_STATE);
    try {
      if (appState) return JSON.parse(appState) as IAppState;
      else return INITIAL_APP_STATE;
    } catch (e) {
      console.error(e);
      return INITIAL_APP_STATE;
    }
  },
};

window.AUTH = parseAuthToken(LocalStorage.getAuthToken());

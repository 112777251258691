import { Box, Chip, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { DEFAULT_SPACING } from "../../styles/themes";

export interface ElementChipsProps<X> {
  elements: Array<ElementChip<X>>;
  onElementDelete?: (el: ElementChip<X>) => void;
  addElement: () => void;
  onEditElement?: (el: ElementChip<X>) => void;
}
export type ElementChip<X> = X & { key: string | number; label: string };
export function ElementChips<X>({
  elements: keywords,
  onElementDelete,
  addElement,
  onEditElement,
}: ElementChipsProps<X>) {
  return (
    <Box mt={2} mb={2}>
      {keywords.map((item) => {
        const { label, key } = item;
        return (
          <Chip
            key={key}
            style={{ marginRight: DEFAULT_SPACING }}
            label={label}
            onDelete={onElementDelete?.bind(null, item)}
            onClick={onEditElement?.bind(null, item)}
            icon={
              onEditElement ? (
                // <Avatar onClick={onEditElement.bind(null, item)}>
                <EditIcon />
              ) : // </Avatar>
              undefined
            }
          />
        );
      })}
      <IconButton onClick={addElement} size="small" color="primary">
        <AddIcon />
      </IconButton>
    </Box>
  );
}

import { Avatar, Box, Chip, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import React from "react";
import { useTranslation } from "react-i18next";
import { InLanguage, RECIPE_LANGUAGES } from "../../api/types";
import { RECIPE_LANGUAGES_ARRAY } from "../../config";
import { useEditLang } from "../../hooks/useEditLang";
import { DEFAULT_SPACING } from "../../styles/themes";

export interface AvailableLanguageChipsProps {
  unsavedChances: boolean;
  inLanguage: InLanguage[];
  translate: (addLang: RECIPE_LANGUAGES) => void;
}

export const AvailableLanguageChips: React.FC<AvailableLanguageChipsProps> = ({
  unsavedChances,
  inLanguage,
  translate,
}) => {
  const [editLang, setEditLang] = useEditLang();
  const { t } = useTranslation(["langEdit", "actions"]);

  const languageClick = (lang: RECIPE_LANGUAGES) => () => {
    if (unsavedChances) {
      window.ALERT({
        title: t("actions:warning"),
        text: t("actions:reallyCancelEdit"),
        okLabel: t("actions:no"),
        cancelLabel: t("actions:yes"),
        doIt: (noClicked) => {
          if (!noClicked) setEditLang(lang);
        },
      });
      return;
    } else {
      setEditLang(lang);
    }
  };
  const addLanguageClick = (lang: RECIPE_LANGUAGES) => () => {
    window.ALERT({
      title: t("newLang"),
      text: t("copyLangTo", { language: lang }),
      okLabel: t("actions:copy"),
      cancelLabel: t("actions:cancel"),
      doIt: (copyClicked) => {
        if (copyClicked) {
          if (unsavedChances) {
            window.ALERT({
              title: t("actions:warning"),
              text: t("actions:reallyCancelEdit"),
              okLabel: t("actions:no"),
              cancelLabel: t("actions:yes"),
              doIt: (noClicked) => {
                if (!noClicked) translate(lang);
              },
            });
          } else {
            translate(lang);
          }
        }
      },
    });
  };

  return (
    <>
      <Box mt={1.5}>
        {RECIPE_LANGUAGES_ARRAY.map((lang) => {
          const exists = !!inLanguage.find(({ language }) => language === lang);
          const avatar = exists ? undefined : (
            <Avatar>
              <AddIcon />
            </Avatar>
          );

          return (
            <Tooltip
              key={lang}
              title={t(exists ? "languageExist" : "languageNotExist") as string}
            >
              <Chip
                style={{
                  cursor: "pointer",
                  marginLeft: DEFAULT_SPACING,
                }}
                size="small"
                color={
                  editLang === lang
                    ? "secondary"
                    : exists
                    ? "primary"
                    : "default"
                }
                onClick={
                  editLang === lang
                    ? undefined
                    : exists
                    ? languageClick(lang)
                    : addLanguageClick(lang)
                }
                label={lang}
                avatar={avatar}
              />
            </Tooltip>
          );
        })}
      </Box>
    </>
  );
};

import { RECIPE_LANGUAGES_ARRAY } from "../config";
import { MultiLangObject } from "../api/types";

export const plainMultiLangObj = (): MultiLangObject => {
  const newObj: Partial<MultiLangObject> = {};
  RECIPE_LANGUAGES_ARRAY.forEach((lang) => {
    newObj[lang] = null;
  });
  return newObj as MultiLangObject;
};

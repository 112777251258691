import { Button, Tooltip } from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import Container from "@material-ui/core/Container/Container";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { TFunction } from "i18next";
import React, { ChangeEvent } from "react";

import { clearMyLangKeys } from "../../utils/clearMyLangKeys";
import {
  CurrentMultiLangEntity,
  MultiLangEntity,
  MultiLangEntityState,
  RECIPE_LANGUAGES,
} from "../../api/types";
import { DEFAULT_DEBOUNCE_TIME } from "../../config";
import { DEFAULT_SPACING } from "../../styles/themes";
import { createNewUniquId } from "../../utils/createNewUniquId";
import { DebouncedSearch } from "../atoms/DebouncedSearch";
import { EditEntityDialog } from "../Dialogs/EditEntityDialog";
import { RMSTable, RMSTableData, RMSTableHeadCell } from "../RMSTable";
import EditIcon from "@material-ui/icons/Edit";

type ListMultiLangEntity = CurrentMultiLangEntity & { actions?: any };

export interface MultiLangActions {
  deleteEntity: (id: number) => Promise<any>;
  updateEntity: (entity: MultiLangEntity) => Promise<number>;
  addEntity: (entity: MultiLangEntity) => Promise<number>;
}

interface ListMultiLangEntitiesProps extends MultiLangActions {
  t: TFunction;
  entityState: MultiLangEntityState;
  classes: Record<"iconLeft" | "iconRight", string>;
  refresh: () => void;
  searchValue: string;
  setSearchValue: (v: string) => void;
  onClick?: (name?: string) => void;
}
interface ListMultiLangEntitiesState {
  selected: number[];
  editEntity: MultiLangEntity | undefined;
}
export class ListMultiLangEntitiesClass extends React.Component<
  ListMultiLangEntitiesProps,
  ListMultiLangEntitiesState
> {
  constructor(props: ListMultiLangEntitiesProps) {
    super(props);
    this.state = {
      selected: [],
      editEntity: undefined,
    };
  }
  private getHeadCells = (): RMSTableHeadCell<
    RMSTableData<ListMultiLangEntity>
  >[] => {
    const { t, classes } = this.props;
    return [
      {
        disablePadding: true,
        width: "20%",
        id: "actions",
        label: t("actions:actions"),
        numeric: false,
        sortable: false,
        formatter: (r) => {
          return (
            <>
              <Tooltip title={t("actions:deleteLabel") as string}>
                <span
                  onClick={this.onDeleteClick.bind(null, r)}
                  style={{ cursor: "pointer", marginRight: DEFAULT_SPACING }}
                >
                  <DeleteIcon />
                </span>
              </Tooltip>
              <Tooltip title={t("actions:edit") as string}>
                <span
                  onClick={this.onEditClick.bind(null, r)}
                  style={{ cursor: "pointer", marginRight: DEFAULT_SPACING }}
                >
                  <EditIcon className={classes.iconRight} />
                </span>
              </Tooltip>
            </>
          );
        },
      },
      {
        disablePadding: true,
        width: "70%",
        id: "name",
        label: t("name"),
        numeric: false,
        sortable: false,
      },
    ];
  };
  private onAddClick = () => {
    const newUnit: MultiLangEntity = {
      id: createNewUniquId(),
      ...clearMyLangKeys({}),
    };
    this.setState({ editEntity: newUnit });
  };
  private onEditClick = ({
    id, // remove prop from item
    actions, // remove prop from item
    name, // remove prop from item
    ...langObj
  }: RMSTableData<ListMultiLangEntity>) => {
    this.setState({
      editEntity: {
        id,
        ...clearMyLangKeys(langObj),
      },
    });
  };
  private onClick = ({ name }: RMSTableData<ListMultiLangEntity>) => {
    if (this.props.onClick) this.props.onClick(name);
  };

  private setSelected = (newSelection: number[]) => {
    this.setState({ selected: newSelection });
  };

  onDeleteClick = (r: ListMultiLangEntity, e: React.MouseEvent) => {
    this.onDelete(e, [r.id]);
  };

  onDelete = (event: React.MouseEvent, items: number[]) => {
    event.stopPropagation();
    event.preventDefault();
    const { t, refresh } = this.props;
    window.ALERT({
      title: t("actions:deleteLabel"),
      text: t("deleteWarn", { length: items.length }),
      okLabel: t("actions:cancel"),
      cancelLabel: t("actions:ok"),
      doIt: (canceled: boolean) => {
        if (canceled) return true;
        Promise.all(items.map((id) => this.props.deleteEntity(id)))
          .catch(() => {
            window.ALERT({
              title: t("actions:deleteLabel"),
              text: t("deleteError"),
              okLabel: t("actions:ok"),
            });
          })
          .then(() => {
            refresh();
          });
      },
    });
  };

  onChangeEditFields = (l: RECIPE_LANGUAGES) => (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const trans = event.target.value;
    if (!this.state.editEntity) return;
    this.setState({
      editEntity: { ...this.state.editEntity, [l]: trans },
    });
  };
  onCloseEdit = (id?: number) => {
    this.setState({ editEntity: undefined });
    if (id !== undefined) {
      this.props.refresh();
    }
  };

  render() {
    const {
      searchValue,
      t,
      entityState,
      setSearchValue,
      classes,
      addEntity,
      updateEntity,
    } = this.props;

    const { selected, editEntity } = this.state;

    const rows: RMSTableData<ListMultiLangEntity>[] = entityState;
    console.log({ placeholder: t("searchPlaceholder") });
    return (
      <>
        {editEntity ? (
          <EditEntityDialog
            entity={editEntity}
            updateEntity={updateEntity}
            addEntity={addEntity}
            onClose={this.onCloseEdit}
            onChange={this.onChangeEditFields}
            t={t}
          />
        ) : null}
        <Container style={{ padding: 0 }}>
          <Box display="flex" mb={3}>
            <DebouncedSearch
              debounceTime={DEFAULT_DEBOUNCE_TIME * 2}
              updateSearchValue={setSearchValue}
              value={searchValue}
              label={t("actions:search")}
              txtFp={{
                variant: "standard",
                placeholder: t("searchPlaceholder"),
              }}
            />
          </Box>
          <RMSTable
            startOrderBy={"name"}
            hoverItemId={editEntity?.id}
            pagination={{
              labelRowsPerPage: t("tbl:labelRowsPerPage"),
            }}
            cellDefinition={this.getHeadCells()}
            onClick={this.onClick}
            setSelected={this.setSelected}
            selected={selected}
            rows={rows}
          />
          <Box pt={2} pb={2} textAlign="right" className="rightSpacingChildren">
            <Button
              variant="contained"
              color="secondary"
              disabled={selected.length === 0}
              onClick={(e) => this.onDelete(e, selected)}
            >
              <DeleteIcon className={classes.iconLeft} />
              {t("actions:deleteLabel")}{" "}
              {selected.length !== 0 ? `(${selected.length})` : ""}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.onAddClick}
            >
              <AddIcon className={classes.iconLeft} />
              {t("actions:create")}
            </Button>
          </Box>
        </Container>
      </>
    );
  }
}

// export const ListEntity: React.FC = () => {
//   const [
//     {
//       entityStates: { data, loading, error },
//     },
//     { refreshUnits },
//   ] = useContext(RecipeDataContext);
//   const { t } = useTranslation(["editEntity", "tbl"]);
//   const classes = useDefaultStyles();
//   const [searchValue, setSearchValue] = useState("");
//   const [editLang] = useEditLang();
//   const filteredUnits = React.useMemo(() => {
//     if (!data) return [];
//     if (!searchValue) return data;
//     return data.filter(
//       (entityState) =>
//         entityState[editLang]
//           ?.toLowerCase()
//           .indexOf(searchValue.toLowerCase()) !== -1,
//     );
//   }, [data, searchValue, editLang]);

//   return (
//     <LoadingComponent isLoading={loading} errorText={error}>
//       <ListMultiLangEntitiesClass
//         t={t}
//         setSearchValue={setSearchValue}
//         classes={classes}
//         searchValue={searchValue}
//         entityState={filteredUnits}
//         refresh={refreshUnits}
//       />
//     </LoadingComponent>
//   );
// };

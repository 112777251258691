import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  addIngredient,
  deleteIngredient,
  updateIngredient,
} from "../../api/ingredients";
import { useEditLang } from "../../hooks/useEditLang";
import { RecipeDataContext } from "../../provider/RecipeData";
import { useDefaultStyles } from "../../styles/themes";
import LoadingComponent from "../atoms/LoadingComponent";
import { ListMultiLangEntitiesClass } from "./ListMultiLangEntitiesClass";

export const ListIngredients: React.FC<{
  onClick?: (name?: string) => void;
}> = ({ onClick }) => {
  const [
    {
      ingrs: { data, loading, error },
    },
    { refreshIngredients },
  ] = useContext(RecipeDataContext);
  const classes = useDefaultStyles();
  const { t } = useTranslation(["editIngredient", "tbl"]);
  const [searchValue, setSearchValue] = useState("");
  const [editLang] = useEditLang();
  const filteredIngredients = React.useMemo(() => {
    if (!data) return [];
    if (!searchValue) return data;
    return data.filter(
      (ingredient) =>
        ingredient[editLang]
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1,
    );
  }, [data, searchValue, editLang]);

  return (
    <LoadingComponent isLoading={loading} errorText={error}>
      <ListMultiLangEntitiesClass
        t={t}
        classes={classes}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        entityState={filteredIngredients}
        refresh={refreshIngredients}
        onClick={onClick}
        deleteEntity={deleteIngredient}
        addEntity={addIngredient}
        updateEntity={updateIngredient}
      />
    </LoadingComponent>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { ReactNode } from "react";

export interface ConfirmDialogProps {
  open: boolean;
  doIt: (confirmed: boolean) => void;
  cancelLabel?: string;
  okLabel?: string;
  text: string | ReactNode;
  title?: string;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  text,
  cancelLabel,
  okLabel,
  doIt,
  open,
  title,
}) => {
  return (
    <Dialog
      open={open}
      disablePortal={true}
      onEscapeKeyDown={doIt.bind(null, false)}
      onClose={doIt.bind(null, false)}
    >
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      <DialogContent>
        {typeof text === "string" ? <Typography>{text}</Typography> : text}
      </DialogContent>
      <DialogActions>
        {cancelLabel ? (
          <Button onClick={doIt.bind(null, false)}>{cancelLabel}</Button>
        ) : null}
        {okLabel ? (
          <Button onClick={doIt.bind(null, true)} color="primary">
            {okLabel}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

import { TFunction } from "i18next";
import {
  MultiLangEntityState,
  RecipeDetails,
  RECIPE_LANGUAGES,
  SubCatExtendEntity,
} from "../api/types";
import { TRANSLATE_ME_TAG } from "../config";
import { ValidationResult } from "./saveRecipe";

export const validateRecipe = (
  {
    recipeInstructions,
    ingredientsSections,
    name,
    description,
    headline,
    author,
    recipeYield,
    keywords,
    categories,
    associatedProducts,
    cookTime,
    prepTime,
    imageBas64,
    imageFile,
    nutrition: { calories, carbs, fat, protein },
  }: RecipeDetails,
  t: TFunction,
  mdata: MultiLangEntityState<{}>,
  sdata: MultiLangEntityState<SubCatExtendEntity>,
  language: RECIPE_LANGUAGES,
  isPublishing?: boolean
): ValidationResult => {
  let e: string[] = [];
  const w: string[] = [];
  const pw: string[] = [];
  if (!name) {
    e.push(`${t("name")}: ${t("editError:empty")}`);
  }
  if (name.startsWith(TRANSLATE_ME_TAG)) {
    pw.push(`${t("name")}: ${t("editError:translateMe")}`);
  }

  if (!headline) {
    e.push(`${t("headline")}: ${t("editError:empty")}`);
  }
  if (headline.startsWith(TRANSLATE_ME_TAG)) {
    pw.push(`${t("headline")}: ${t("editError:translateMe")}`);
  }

  if (!description) {
    w.push(`${t("description")}: ${t("editError:empty")}`);
  }
  if (description.startsWith(TRANSLATE_ME_TAG)) {
    pw.push(`${t("description")}: ${t("editError:translateMe")}`);
  }

  if (!author) {
    w.push(`${t("author")}: ${t("editError:empty")}`);
  }

  if (!imageBas64 && !imageFile) {
    e.push(`${t("recipePicture")}: ${t("editError:empty")}`);
  }

  if (recipeYield < 1) {
    e.push(`${t("recipeYield")}: ${t("editError:empty")}`);
  }

  if (!cookTime || cookTime.substr(0, 1) === "-" || cookTime === "P0D") {
    e.push(`${t("cookTime")}: ${t("editError:empty")}`);
  }
  if (!prepTime || prepTime.substr(0, 1) === "-" || prepTime === "P0D") {
    e.push(`${t("prepTime")}: ${t("editError:empty")}`);
  }

  // if (calories <= 0) {
  //   pw.push(`${t("calories")}: ${t("editError:empty")}`);
  // }
  // if (carbs <= 0) {
  //   w.push(`${t("carbohydrateContent")}: ${t("editError:empty")}`);
  // }
  // if (fat <= 0) {
  //   w.push(`${t("fatContent")}: ${t("editError:empty")}`);
  // }
  // if (protein <= 0) {
  //   w.push(`${t("proteinContent")}: ${t("editError:empty")}`);
  // }

  if (!ingredientsSections.length) {
    e.push(`${t("ingredients")}: ${t("editError:emptyArray")}`);
  } else {
    /* prevent changing status when there's more than one section and one of the sections does not have a title */

    if (
      ingredientsSections.length > 1 &&
      ingredientsSections.find(
        (ingredientsSection) => ingredientsSection.title === ""
      )
    ) {
      e.push(`${t("ingredients:sectionTitle")}: ${t("editError:emptyArray")}`);
    }

    /* check if one of the sections is empty */
    ingredientsSections.forEach((section) => {
      if (!section.ingredients.length) {
        e.push(`${t("ingredients")}: ${t("editError:emptyArray")}`);
      }

      /* check each ingredient of each section */
      section.ingredients.forEach(
        ({ name, amount, unit, ingredientId }, index) => {
          if (ingredientId < 0)
            e.push(
              `${t("editError:ingredient")} ${index + 1}: ${t("iname")} ${t(
                "editError:empty"
              )}`
            );
          if (!name || name.startsWith(TRANSLATE_ME_TAG)) {
            pw.push(
              `${t("editError:ingredient")} ${index + 1}: ${t("iname")} ${t(
                "editError:translateMe"
              )}`
            );
          }
          if (!unit || !unit.id) {
            console.log({ unit });
            w.push(
              `${t("editError:ingredient")} ${index + 1}: ${t("iunit")} ${t(
                "editError:empty"
              )}`
            );
          } else if (!unit.name || unit.name.startsWith(TRANSLATE_ME_TAG)) {
            console.log("think im here");
            w.push(
              `${t("editError:ingredient")} ${index + 1}: ${t("iunit")} ${t(
                "editError:translateMe"
              )}`
            );
          }
        }
      );
    });
  }

  if (recipeInstructions.length === 0) {
    pw.push(`${t("steps")}: ${t("editError:emptyArray")}`);
  } else {
    recipeInstructions.forEach(({ images, text, name, deleted }, index) => {
      if (deleted) return;
      if (!name) {
        w.push(
          `${t("recipeStep:step")} ${index + 1}: ${t("recipeStep:name")} ${t(
            "editError:empty"
          )}`
        );
      }
      if (name.startsWith(TRANSLATE_ME_TAG)) {
        pw.push(
          `${t("recipeStep:step")} ${index + 1}/${t("recipeStep:name")}: ${t(
            "editError:translateMe"
          )}`
        );
      }
      if (!text) {
        e.push(
          `${t("recipeStep:step")} ${index + 1}: ${t("recipeStep:text")} ${t(
            "editError:empty"
          )}`
        );
      }
      if (text.startsWith(TRANSLATE_ME_TAG)) {
        pw.push(
          `${t("recipeStep:step")} ${index + 1}/${t("recipeStep:text")}: ${t(
            "editError:translateMe"
          )}`
        );
      }
      if (images.length === 0) {
        w.push(
          `${t("recipeStep:step")} ${index + 1}/${t("recipeStep:images")}: ${t(
            "editError:emptyArray"
          )}`
        );
      }
    });
  }
  if (!keywords.length) {
    e.push(`${t("keywords")}: ${t("editError:emptyArray")}`);
  } else {
    keywords.forEach(({ name }, index) => {
      if (name.startsWith(TRANSLATE_ME_TAG)) {
        pw.push(`${t("keywords")} ${index + 1}: ${t("editError:translateMe")}`);
      }
    });
  }

  if (!categories.length) {
    e.push(`${t("categories")}: ${t("editError:emptyArray")}`);
  } else {
    categories.forEach(({ catId, subcatId }, index) => {
      const mCat = mdata.find(({ id }) => catId === id);
      const sCat = sdata.find(({ id }) => subcatId === id);
      if (!mCat || !mCat[language] || !sCat || !sCat[language]) {
        pw.push(
          `${t("categories")} ${index + 1}: ${t("editError:translateMe")}`
        );
      }
    });
    // categories.forEach(({ categoryName, subcategoryName }, index) => {
    //   if (
    //     !categoryName ||
    //     categoryName.startsWith(TRANSLATE_ME_TAG) ||
    //     !subcategoryName ||
    //     subcategoryName.startsWith(TRANSLATE_ME_TAG)
    //   ) {
    //     pw.push(
    //       `${t("categories")} ${index + 1}: ${t("editError:translateMe")}`,
    //     );
    //   }
    // });
  }
  if (!associatedProducts.length) {
    w.push(`${t("products")}: ${t("editError:emptyArray")}`);
  }
  /* ignore errors in case the recipe is not being published */
  if (!isPublishing) {
    e = [];
  }
  /* recheck if the recipe name exists */
  if (!name) {
    e.push(`${t("name")}: ${t("editError:empty")}`);
  }
  return { errors: e, warnings: [...w, ...pw], publishWarn: pw };
};

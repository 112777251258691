import LocalDiningIcon from "@material-ui/icons/LocalDining";
import CategoryIcon from "@material-ui/icons/Category";
// import FastfoodIcon from "@material-ui/icons/Fastfood";
import KitchenIcon from "@material-ui/icons/Kitchen";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { PrivateRoute } from "./components/atoms/PrivateRoute";
import { EditRecipePage } from "./components/pages/EditRecipePage";
import { ListCategoriesPage } from "./components/pages/ListCategoriesPage";
import { ListKeywordsPage } from "./components/pages/ListKeywordsPage";
import { ListRecipesPage } from "./components/pages/ListRecipesPage";
import { LoginPage } from "./components/pages/LoginPage";
import { NotFoundPage } from "./components/pages/NotFoundPage";
import { UsersPage } from "./components/pages/UsersPage";
import { ListIngUnitsPage } from "./components/pages/ListIngUnitsPage";
export interface AppRouteProps extends RouteProps {
  authRequired: boolean;
  component: React.FC;
}

const AppPaths: AppRouteProps[] = [
  { path: "/", component: ListRecipesPage, exact: true, authRequired: true },
  {
    path: "/categories",
    component: ListCategoriesPage,
    exact: true,
    authRequired: true,
  },
  {
    path: "/keywords",
    component: ListKeywordsPage,
    exact: true,
    authRequired: true,
  },
  {
    path: "/ingredients-units",
    component: ListIngUnitsPage,
    exact: true,
    authRequired: true,
  },
  {
    path: "/edit/recipe/:mainId",
    component: EditRecipePage,
    exact: true,
    authRequired: true,
  },
  { path: "/users", component: UsersPage, exact: true, authRequired: true },
  { path: "/login", component: LoginPage, exact: true, authRequired: false },
  { path: "*", component: NotFoundPage, exact: true, authRequired: false },
];
export const APP_ROUTES = AppPaths.map((routeProps) => {
  if (routeProps.authRequired) {
    return (
      <PrivateRoute
        key={((routeProps.path as unknown) as string) || "dummy"}
        path={(routeProps.path as unknown) as string}
        component={routeProps.component}
        exact={!!routeProps.exact}
      ></PrivateRoute>
    );
  }
  return <Route {...routeProps} key={(routeProps.path as unknown) as string} />;
});

export const MenuItems = [
  {
    langKey: "recipes",
    Icon: LocalDiningIcon,
    path: "/",
    admin: false,
  },
  {
    langKey: "categories",
    Icon: CategoryIcon,
    path: "/categories",
    admin: false,
  },
  {
    langKey: "keywords",
    Icon: LocalOfferIcon,
    path: "/keywords",
    admin: false,
  },
  {
    langKey: "ingredients-units",
    Icon: KitchenIcon,
    path: "/ingredients-units",
    admin: false,
  },
  {
    langKey: "users",
    Icon: SupervisorAccountIcon,
    path: "/users",
    admin: true,
  },
];

export const getAddRecipeUrl = () => `/edit/recipe/-1`;
export const getListRecipeUrl = () => `/`;
export const getEditRecipeUrl = (mainId: number) => `/edit/recipe/${mainId}`;
export const getEditCategroryUrl = (id: string) => `/edit/category/${id}`;
export const getEditSubCategroryUrl = (id: string) => `/edit/subcategory/${id}`;

import { RMSTableData } from "../components/RMSTable";

export enum RECIPE_STATE {
  // CreateState,
  // placed = "placed",
  // approved = "approved",
  // delivered = "delivered",
  // EditState,
  published = "published",
  pending = "pending",
}

export interface UserAuth {
  jwtToken: string;
}
export interface Users {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  admin?: boolean;
}

export enum RECIPE_LANGUAGES {
  "en-US" = "en-US",
  "de-DE" = "de-DE",
  "fr-FR" = "fr-FR",
  "it-IT" = "it-IT",
  "nl-NL" = "nl-NL",
}

export type RecipeCategories = {
  catId: number;
  subcatId?: number;
  // isTranslated: boolean;
};

export type UpdateRecipeCategories = {
  catId: number;
  subcatId?: number;
  action: "add" | "delete";
};
export interface UpdateKeywords {
  keyId: number;
  action: "add" | "delete";
}

export type UpdateAction = "add" | "update" | "delete";
export interface UpdateRecipeIngredients {
  id?: number;
  description: string;
  amount: number;
  action: UpdateAction;
  unitId: number;
  ingredientId: number;
}
export interface UpdateRecipeIngredientsSection {
  id: string;
  title: string;
  ingredients?: UpdateRecipeIngredients[];
}

export interface UploadableImage {
  base64orUrl: string;
  uploadFile?: File;
  deleted: boolean;
}
export interface RecipeSteps {
  name: string;
  text: string;
  id: number;
  stepNumber: number;
  deleted: boolean;
  images: UploadableImage[];
}

export interface RecipeIngredientUnit {
  id: number;
  name: string;
}

export interface RecipeIngredients {
  id: number;
  ingredientId: number;
  name: string;
  amount: number;
  unit: RecipeIngredientUnit;
  description?: string;
}

export interface RecipeNutrition {
  calories: number;
  carbs: number;
  fat: number;
  protein: number;
}

export interface InLanguage {
  id: number;
  language: string;
}

export interface UpdateMainRecipeDetails extends RecipeNutrition {
  prepTime: string;
  cookTime: string;
  totalTime: string;
  yield: number;
  products: string;
}

export interface RecipeKeywords {
  id: number;
  name: string;
}
export interface SubRecipeDetails {
  name: string;
  headline: string;
  description: string;
  keywords: RecipeKeywords[];
  author: string;
  status: RECIPE_STATE;
  categories: RecipeCategories[];
  ingredientsSections: RecipeIngredientsSection[];
}

export interface RecipeIngredientsSection {
  id: string;
  title: string;
  ingredients: RecipeIngredients[];
}
export interface SubReadonlyRecipeDetails {
  dateCreated: string;
  dateModified: string;
  datePublished: string;
  inLanguage: InLanguage[];
  recipeInstructions: RecipeSteps[];
}

export interface MainRecipeDetails {
  prepTime: string;
  cookTime: string;
  totalTime: string;
  recipeYield: number;
  nutrition: RecipeNutrition;
  associatedProducts: string;
}
export interface RecipeDetails
  extends MainRecipeDetails,
    SubRecipeDetails,
    SubReadonlyRecipeDetails {
  mainId: number;
  recipeId: number;
  imageBas64: string;
  imageFile?: File;
}
export type RecipeSearchDetails = Omit<
  RecipeDetails,
  | "imageFile"
  | "imageBas64"
  | "ingredients"
  | "categories"
  | "recipeInstructions"
  | "inLanguage"
> & {
  image?: {
    lastModified: string;
    path: string;
  };
  inLanguage: string[];
};

export type UpdateRecipeDetails = Omit<
  Partial<SubRecipeDetails>,
  "categories" | "ingredientsSections" | "keywords"
> & {
  categories?: UpdateRecipeCategories[];
  ingredientsSections?: UpdateRecipeIngredientsSection[];
  keywords?: UpdateKeywords[];
};

export interface AuthUserInfo {
  user: string;
  isAdmin: boolean;
  iat: number;
  exp: number;
  jwt: string;
}

export type MultiLangObject = {
  [key in RECIPE_LANGUAGES]: null | string;
};
export type MultiLangEntity<X = {}> = MultiLangObject & { id: number } & X;

export type LanguageObjectWithCount = {
  [key in RECIPE_LANGUAGES]: { name: string; recipesCount: number };
};
export type CategoriesEntity<X = {}> = LanguageObjectWithCount & {
  id: number;
} & X;

export type Ingredient = MultiLangEntity;
export type Keyword = MultiLangEntity;
export type Unit = MultiLangEntity;

export type CurrentMultiLangEntity<X = {}> = MultiLangEntity<X> & {
  name: string;
};

export type CurrentMultiLangEntityWithRecipesCount<X = {}> =
  CategoriesEntity<X> & {
    name: string;
  };

export type MultiLangEntityState<X = {}> = RMSTableData<
  CurrentMultiLangEntity<X>
>[];

export type SubCatExtendEntity = { categoryId: number, sortOrder: number };

import { useMemo } from "react";
import { KeywordsResponse } from "../../api/keywords";
import {
  Keyword,
  RECIPE_LANGUAGES,
  MultiLangEntityState,
  CurrentMultiLangEntity,
} from "../../api/types";
import { API_URL } from "../../config";
import { useEditLang } from "../../hooks/useEditLang";
import { ReqState, useRequestURL } from "../../hooks/useRequestURL";
import { getLangLabel } from "../../utils/getLangLabel";

const parseKeywordData = (data: KeywordsResponse): Keyword[] => data.keywords;
const mergeKeywordDataToKeywordState = (
  language: RECIPE_LANGUAGES,
  keywords: Keyword[],
): MultiLangEntityState => {
  // const defaultName = categories.find(({id, ...language})=>)
  return keywords.map(({ id, ...langOb }) => {
    const main: CurrentMultiLangEntity = {
      id,
      name: getLangLabel(langOb, language),
      ...langOb,
    };
    return main;
  });
};
export const useKeywords = (): [ReqState<MultiLangEntityState>, () => void] => {
  const [language] = useEditLang();

  const [keywords, refreshKeywords] = useRequestURL({
    url: `${API_URL.KEYWORDS}`,
    method: "GET",
    resetStateOnRefresh: false,
    parseData: parseKeywordData,
  });

  const kwrds: ReqState<MultiLangEntityState> = useMemo(() => {
    const { data, loading, error } = keywords;
    return {
      loading,
      error,
      data: data ? mergeKeywordDataToKeywordState(language, data) : undefined,
    };
  }, [language, keywords]);

  return [kwrds, refreshKeywords];
};

import { Checkbox, TableBody } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { CSSProperties } from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { RMSTableData, RMSTableHeadCell } from ".";
import { useRMSTableStyles } from "./styles";

export interface RMSTableBodyProbs<X, T extends RMSTableData<X>> {
  rows: T[];
  hoverItemId?: T["id"] | undefined;
  onClick?: (v: T) => void;
  selected?: Array<T["id"]>;
  headCells: RMSTableHeadCell<T>[];

  emptyRows: number;
  handleClick: (event: React.MouseEvent<unknown>, name: T["id"]) => void;
}

export const RMSTableBody = function <X, T extends RMSTableData<X>>({
  rows,
  emptyRows,
  onClick,
  headCells,
  hoverItemId,
  handleClick,
  selected,
}: RMSTableBodyProbs<X, T>) {
  const classes = useRMSTableStyles();
  const { t } = useTranslation("tbl");
  const isSelected = (name: T["id"]) =>
    selected ? selected.indexOf(name) !== -1 : false;

  let disablePaddingAll = true;
  headCells.forEach(({ disablePadding }) => {
    disablePaddingAll = disablePadding && disablePaddingAll;
  });
  const oneHeight = disablePaddingAll ? 46 : 52;

  return (
    <TableBody>
      {!rows.length ? (
        <TableRow>
          <TableCell
            colSpan={headCells.length + (selected ? 1 : 0)}
            padding={disablePaddingAll ? "none" : "default"}
            style={{ textAlign: "center", height: oneHeight }}
          >
            {t("noData")}
          </TableCell>
        </TableRow>
      ) : (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const hoverStyle: CSSProperties =
            hoverItemId && row.id === hoverItemId
              ? { backgroundColor: "#ddd" }
              : {};
          return (
            <TableRow
              key={row.id}
              style={{
                ...{ height: oneHeight },
                ...(onClick
                  ? { cursor: "pointer", ...hoverStyle }
                  : hoverStyle),
              }}
              onClick={(event: React.MouseEvent) => {
                if (onClick) {
                  onClick(row);
                } else {
                  handleClick(event, row.id);
                }
              }}
            >
              {selected ? (
                <TableCell padding={"none"}>
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    style={{ padding: 9 }} // This is a material-ui bug, first render have it, second not
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick(event, row.id);
                    }}
                  />
                </TableCell>
              ) : null}
              {headCells.map(({ id, formatter, disablePadding }) => {
                return (
                  <TableCell
                    key={(id as unknown) as string}
                    component="td"
                    className={classes.td}
                    // padding={"default"}
                    padding={disablePadding ? "none" : "default"}
                    // scope="row"
                  >
                    {formatter ? formatter(row, id) : row[id]}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      )}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: oneHeight * (emptyRows - (rows.length === 0 ? 1 : 0)),
          }}
        >
          <TableCell colSpan={headCells.length + (selected ? 1 : 0)} />
        </TableRow>
      )}
    </TableBody>
  );
};

import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddToPhotos from "@material-ui/icons/AddToPhotos";
import DeleteIcon from "@material-ui/icons/Delete";
import LanguageIcon from "@material-ui/icons/Language";
import { TFunction } from "i18next";
import React, { ChangeEvent, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { addIngredient, updateIngredient } from "../../../api/ingredients";
import {
  Ingredient,
  MultiLangEntityState,
  RecipeDetails,
  RecipeIngredients,
  RecipeIngredientsSection,
  RecipeIngredientUnit,
  RECIPE_LANGUAGES,
  Unit,
} from "../../../api/types";
import { addUnit, updateUnit } from "../../../api/units";
import { TRANSLATE_ME_TAG } from "../../../config";
import { useEditLang } from "../../../hooks/useEditLang";
import { RecipeDataContext } from "../../../provider/RecipeData";
import { useDefaultStyles } from "../../../styles/themes";
import {
  createNewUniquId,
  NEW_ID_RANGE,
} from "../../../utils/createNewUniquId";
import { getLangLabel } from "../../../utils/getLangLabel";
import { EditEntityDialog } from "../../Dialogs/EditEntityDialog";
import {
  AutoCompleteItem,
  CurrentMultiLangEntityAutoComplete,
} from "../../molecules/CurrentMultiLangEntityAutoComplete";
import { NutritionFields } from "../NutritionFields";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@material-ui/icons/Close";

export interface IngredientTabProps {
  recipe: RecipeDetails;
  setRecipeState: React.Dispatch<React.SetStateAction<RecipeDetails>>;
}

interface CustomGridProp {
  name: ReactNode;
  unit: ReactNode;
  amount: ReactNode;
  action?: ReactNode;
  description: ReactNode;
  backgroundColor: string;
}
export const CustomGrid: React.FC<CustomGridProp> = ({
  name,
  unit,
  amount,
  action,
  description,
  backgroundColor,
}) => {
  return (
    <div
      style={{
        backgroundColor,
        display: "flex",
        padding: "8px 16px",
      }}
    >
      <Box pr={2} alignItems="center" flex={1} display="flex" children={name} />
      <Box pr={2} style={{ flex: 1 }} children={description} />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        pr={2}
        style={{ flex: 0.4 }}
        children={amount}
      />
      <Box pr={2} style={{ flex: 0.5 }} children={unit} />
      <Box pr={2} style={{ flexBasis: 40 }} children={action} />
    </div>
  );
};

interface IngredientsSectionHeaderProps {
  sectionTitle: string;
  sectionIndex: number;
  t: TFunction;
  onDeleteSection: (index: number) => () => void;
  onUpdateSectionTitle: (
    index: number
  ) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
const IngredientsSectionHeader: React.FC<IngredientsSectionHeaderProps> = ({
  sectionTitle,
  sectionIndex,
  t,
  onDeleteSection,
  onUpdateSectionTitle,
}) => {
  return (
    <div
      style={{
        display: "flex",
        paddingBottom: "15px",
      }}
    >
      <Box style={{ flex: 1 }}>
        <TextField
          size="small"
          onBlur={onUpdateSectionTitle(sectionIndex)}
          fullWidth
          required
          defaultValue={sectionTitle}
          placeholder={t("ingredients:sectionTitle")}
        />
      </Box>

      <Box>
        <Tooltip title={t("actions:deleteLabel") as string}>
          <IconButton
            onClick={onDeleteSection(sectionIndex)}
            color="primary"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
};

interface IngredientTabState {
  newIngredient?: Ingredient;
  deleteTriggered?: boolean;
}

interface IngredientTabPropsClass extends IngredientTabProps {
  t: TFunction;
  editLang: RECIPE_LANGUAGES;
  setEditIngredient: (
    i?: Ingredient & { sectionIndex: number; ingredientIndex: number }
  ) => void;
  setEditUnit: (
    i?: Unit & { sectionIndex: number; ingredientIndex: number }
  ) => void;
  ingrs: MultiLangEntityState<{}>;
  units: MultiLangEntityState<{}>;
  classes: Record<
    "iconLeft" | "iconRight" | "noUpperCase" | "noPadding",
    string
  >;
}

export class IngredientTabClass extends React.Component<
  IngredientTabPropsClass,
  IngredientTabState
> {
  constructor(props: IngredientTabPropsClass) {
    super(props);
    this.state = {};
  }

  addNewIngredientStep = (sectionIndex: number) => () => {
    const newRecipeIngredientsSections = [
      ...this.props.recipe.ingredientsSections,
    ];

    newRecipeIngredientsSections[sectionIndex].ingredients = [
      ...newRecipeIngredientsSections[sectionIndex].ingredients,
      {
        id: createNewUniquId(),
        ingredientId: createNewUniquId(),
        name: "",
        amount: 0,
        unit: { id: createNewUniquId(), name: "" },
        description: "",
      },
    ];
    this.props.setRecipeState({
      ...this.props.recipe,
      ingredientsSections: newRecipeIngredientsSections,
    });
  };

  addNewIngredientsSection = () => {
    const newRecipeIngredientsSections = [
      ...this.props.recipe.ingredientsSections,
    ];

    newRecipeIngredientsSections.push({
      id: uuidv4(),
      title: "",
      ingredients: [],
    });

    this.props.setRecipeState({
      ...this.props.recipe,
      ingredientsSections: newRecipeIngredientsSections,
    });
  };

  onChangeYield = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log({ value: event.target.value });
    const value = parseInt(event.target.value, 10) || 0;

    this.props.setRecipeState((thisState) => ({
      ...thisState,
      recipeYield: value,
    }));
  };

  deleteIngredient = (ingredientIndex: number, sectionIndex: number) => () => {
    this.props.setRecipeState((thisState) => {
      const newRecipeIngredientsSections = [...thisState.ingredientsSections];
      newRecipeIngredientsSections[sectionIndex].ingredients.splice(
        ingredientIndex,
        1
      );
      return {
        ...thisState,
        ingredientsSections: newRecipeIngredientsSections,
      };
    });
  };

  onSelectIngredient =
    (ingredientIndex: number, sectionIndex: number) =>
    (ingredient: AutoCompleteItem | null) => {
      const {
        recipe: { ingredientsSections },
        ingrs,
        editLang,
        setRecipeState,
        setEditIngredient,
      } = this.props;
      // reset on blur to old ingredientId
      if (
        ingredient === null &&
        ingredientsSections[sectionIndex].ingredients[ingredientIndex] &&
        ingredientsSections[sectionIndex].ingredients[ingredientIndex]
          .ingredientId < NEW_ID_RANGE
      ) {
        const oldId =
          (ingredientsSections[sectionIndex].ingredients[ingredientIndex]
            .ingredientId +
            NEW_ID_RANGE) *
          -1;
        const oldIng = ingrs.find(({ id }) => id === oldId);
        if (oldIng) {
          setRecipeState((thisState) => {
            const newRecipeIngredientsSections = [
              ...thisState.ingredientsSections,
            ];
            newRecipeIngredientsSections[sectionIndex].ingredients[
              ingredientIndex
            ] = {
              ...newRecipeIngredientsSections[sectionIndex].ingredients[
                ingredientIndex
              ],
              ingredientId: oldId,
              name: getLangLabel(oldIng, editLang),
            };
            return {
              ...thisState,
              ingredientsSections: newRecipeIngredientsSections,
            };
          });
          return;
        }
      }
      if (ingredient && ingredient.id < 0) {
        const { name, ...newIngredient } = ingredient;
        setEditIngredient({ ...newIngredient, sectionIndex, ingredientIndex });
        return;
      }

      setRecipeState((thisState) => {
        if (
          thisState.ingredientsSections[sectionIndex].ingredients[
            ingredientIndex
          ].ingredientId > 0 &&
          ingredient === null
        ) {
          return {
            ...thisState,
            ingredientsSections: thisState.ingredientsSections,
          };
        } else {
          const newRecipeIngredientsSections = [
            ...thisState.ingredientsSections,
          ];
          newRecipeIngredientsSections[sectionIndex].ingredients[
            ingredientIndex
          ] = {
            ...newRecipeIngredientsSections[sectionIndex].ingredients[
              ingredientIndex
            ],
            id: newRecipeIngredientsSections[sectionIndex].ingredients[
              ingredientIndex
            ].id,
            ingredientId: ingredient?.id || createNewUniquId(),
            name: ingredient ? ingredient.name : "",
          };

          return {
            ...thisState,
            ingredients: newRecipeIngredientsSections,
          };
        }
      });
    };
  onSelectUnit =
    (ingredientIndex: number, sectionIndex: number) =>
    (unit: AutoCompleteItem | null) => {
      const {
        setRecipeState,
        setEditUnit,
        recipe: { ingredientsSections },
        units,
        editLang,
      } = this.props;
      // reset on blur to old unitId
      if (
        unit === null &&
        ingredientsSections[sectionIndex].ingredients[ingredientIndex] &&
        ingredientsSections[sectionIndex].ingredients[ingredientIndex].unit.id <
          NEW_ID_RANGE
      ) {
        const oldUId =
          (ingredientsSections[sectionIndex].ingredients[ingredientIndex].unit
            .id +
            NEW_ID_RANGE) *
          -1;
        const oldUnit = units.find(({ id }) => id === oldUId);
        if (oldUnit) {
          setRecipeState((thisState) => {
            const newRecipeIngredientsSections = [
              ...thisState.ingredientsSections,
            ];
            newRecipeIngredientsSections[sectionIndex].ingredients[
              ingredientIndex
            ] = {
              ...newRecipeIngredientsSections[sectionIndex].ingredients[
                ingredientIndex
              ],
              unit: {
                id: oldUId,
                name: getLangLabel(oldUnit, editLang),
              },
            };
            return {
              ...thisState,
              ingredientsSections: newRecipeIngredientsSections,
            };
          });
          return;
        }
      }
      if (unit && unit.id < 0) {
        const { name, ...newUnit } = unit;
        setEditUnit({ ...newUnit, sectionIndex, ingredientIndex });
      }
      setRecipeState((thisState) => {
        if (
          thisState.ingredientsSections[sectionIndex].ingredients[
            ingredientIndex
          ].unit.id > 0 &&
          unit === null
        ) {
          return {
            ...thisState,
            ingredientsSections: thisState.ingredientsSections,
          };
        }
        const newRecipeIngredientsSections = [...thisState.ingredientsSections];
        newRecipeIngredientsSections[sectionIndex].ingredients[
          ingredientIndex
        ] = {
          ...newRecipeIngredientsSections[sectionIndex].ingredients[
            ingredientIndex
          ],
          unit: {
            id: unit ? unit.id : createNewUniquId(),
            name: unit ? unit.name : "",
          },
        };
        return {
          ...thisState,
          ingredientsSections: newRecipeIngredientsSections,
        };
      });
    };

  onChangeValue =
    (ingredientIndex: number, sectionIndex: number) =>
    (key: keyof RecipeIngredients) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.stopPropagation();
      event.preventDefault();
      const newValue: string | number =
        key !== "amount"
          ? event.target.value
          : parseFloat(event.target.value.replace(",", "."));
      this.props.setRecipeState((thisState) => {
        const newRecipeIngredientsSections = [...thisState.ingredientsSections];
        newRecipeIngredientsSections[sectionIndex].ingredients[
          ingredientIndex
        ] = {
          ...newRecipeIngredientsSections[sectionIndex].ingredients[
            ingredientIndex
          ],
          [key]: newValue,
        };
        return {
          ...thisState,
          ingredientsSections: newRecipeIngredientsSections,
        };
      });
    };

  resetIngredient = (ingredientIndex: number, sectionIndex: number) => () => {
    this.props.setRecipeState((thisState) => {
      const newRecipeIngredientsSections = [...thisState.ingredientsSections];
      newRecipeIngredientsSections[sectionIndex].ingredients[ingredientIndex] =
        {
          ...newRecipeIngredientsSections[sectionIndex].ingredients[
            ingredientIndex
          ],
          ingredientId:
            newRecipeIngredientsSections[sectionIndex].ingredients[
              ingredientIndex
            ].ingredientId *
              -1 -
            NEW_ID_RANGE,
        };
      return {
        ...thisState,
        ingredientsSections: newRecipeIngredientsSections,
      };
    });
  };

  resetUnit =
    (
      ingredientIndex: number,
      sectionIndex: number,
      action: "delete" | "reset"
    ) =>
    () => {
      if (action === "delete") this.setState({ deleteTriggered: true });
      this.props.setRecipeState((thisState) => {
        const newRecipeIngredientsSections = [...thisState.ingredientsSections];

        newRecipeIngredientsSections[sectionIndex].ingredients[
          ingredientIndex
        ] = {
          ...newRecipeIngredientsSections[sectionIndex].ingredients[
            ingredientIndex
          ],
          unit: {
            id:
              newRecipeIngredientsSections[sectionIndex].ingredients[
                ingredientIndex
              ].unit.id *
                -1 -
              NEW_ID_RANGE,
            name: newRecipeIngredientsSections[sectionIndex].ingredients[
              ingredientIndex
            ].unit.name,
          },
        };
        return {
          ...thisState,
          ingredients: newRecipeIngredientsSections,
        };
      });
    };

  onIngredientTranslateClick =
    (sectionIndex: number, ingredientIndex: number, id: number) => () => {
      const { ingrs, setEditIngredient } = this.props;
      const item = ingrs.find(({ id: did }) => did === id);
      if (!item) return null;
      const { name, ...langObjWithId } = item;
      setEditIngredient({
        sectionIndex,
        ingredientIndex,
        ...langObjWithId,
      });
    };
  onUnitTranslateClick =
    (sectionIndex: number, ingredientIndex: number, id: number) => () => {
      const { units, setEditUnit } = this.props;
      const item = units.find(({ id: did }) => did === id);
      if (!item) return null;
      const { name, ...langObjWithId } = item;
      setEditUnit({
        sectionIndex,
        ingredientIndex,
        ...langObjWithId,
      });
    };

  renderIngredientName = (
    { ingredientId, name }: RecipeIngredients,
    ingredientIndex: number,
    sectionIndex: number
  ) => {
    const { ingrs, t, editLang, classes } = this.props;
    const ingredient = ingrs.find(({ id }) => id === ingredientId);
    return ingredientId > 0 ? (
      <>
        <Tooltip title={t("actions:editTranslation") as string}>
          <IconButton
            onClick={this.onIngredientTranslateClick(
              sectionIndex,
              ingredientIndex,
              ingredientId
            )}
            classes={{ root: classes.noPadding }}
          >
            <LanguageIcon className={classes.iconLeft} />
          </IconButton>
        </Tooltip>
        <span
          style={{ cursor: "text" }}
          onClick={this.resetIngredient(ingredientIndex, sectionIndex)}
        >
          {ingredient ? getLangLabel(ingredient, editLang) : TRANSLATE_ME_TAG}
        </span>
      </>
    ) : (
      <CurrentMultiLangEntityAutoComplete
        data={ingrs}
        addLabel={t("actions:create") + ": "}
        editLang={editLang}
        onSelect={this.onSelectIngredient(ingredientIndex, sectionIndex)}
      />
    );
  };

  renderUnitName = (
    unit: RecipeIngredientUnit | undefined,
    ingredientIndex: number,
    sectionIndex: number
  ) => {
    const { id, name } = unit || { id: createNewUniquId(), name: "" };
    const { units, t, editLang, classes } = this.props;
    return id > 0 ? (
      <>
        <Tooltip title={t("actions:editTranslation") as string}>
          <IconButton
            onClick={this.onUnitTranslateClick(
              sectionIndex,
              ingredientIndex,
              id
            )}
            className={[classes.iconLeft, classes.noPadding].join(" ")}
          >
            <LanguageIcon />
          </IconButton>
        </Tooltip>
        <span
          style={{ cursor: "text" }}
          onClick={this.resetUnit(ingredientIndex, sectionIndex, "reset")}
        >
          {name || TRANSLATE_ME_TAG}
        </span>
        <Tooltip title={t("actions:delete") as string}>
          <IconButton
            className={[classes.iconRight, classes.noPadding].join(" ")}
            onClick={this.resetUnit(ingredientIndex, sectionIndex, "delete")}
          >
            <CloseIcon fontSize="small" style={{ cursor: "pointer" }} />
          </IconButton>
        </Tooltip>
      </>
    ) : (
      <CurrentMultiLangEntityAutoComplete
        data={units}
        addLabel={t("actions:create") + ": "}
        editLang={editLang}
        onSelect={this.onSelectUnit(ingredientIndex, sectionIndex)}
        deleteTriggered={this.state.deleteTriggered}
      />
    );
  };

  onDeleteSection = (sectionIndex: number) => () => {
    this.props.setRecipeState((thisState) => {
      const newRecipeIngredientsSections = [...thisState.ingredientsSections];
      newRecipeIngredientsSections.splice(sectionIndex, 1);
      return {
        ...thisState,
        ingredientsSections: newRecipeIngredientsSections,
      };
    });
  };

  onUpdateSectionTitle =
    (sectionIndex: number) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.stopPropagation();
      event.preventDefault();
      const newValue: string = event.target.value;
      this.props.setRecipeState((thisState) => {
        const newRecipeIngredientsSections = [...thisState.ingredientsSections];
        newRecipeIngredientsSections[sectionIndex].title = newValue;
        return {
          ...thisState,
          ingredientsSections: newRecipeIngredientsSections,
        };
      });
    };

  renderIngredientsSection =
    (sectionsCount: number) =>
    (section: RecipeIngredientsSection, index: number) => {
      const { id, title, ingredients } = section;
      const { t } = this.props;
      const lastItem = index + 1 === sectionsCount;
      const customStyle = sectionsCount > 1 ? { backgroundColor: "#eee" } : {};
      return (
        <Box key={`${id}-${index}`}>
          <Box key={id} p={1} style={customStyle}>
            {sectionsCount > 1 ? (
              <IngredientsSectionHeader
                key={index}
                sectionTitle={title}
                sectionIndex={index}
                onDeleteSection={this.onDeleteSection}
                onUpdateSectionTitle={this.onUpdateSectionTitle}
                t={t}
              />
            ) : null}
            <CustomGrid
              name={<Typography color="primary">{t("iname")}</Typography>}
              amount={<Typography color="primary">{t("iamount")}</Typography>}
              unit={<Typography color="primary">{t("iunit")}</Typography>}
              description={
                <Typography color="primary">{t("idescription")}</Typography>
              }
              backgroundColor={"#ccc"}
            />
            {ingredients.map(this.renderIngredientLine(index))}
          </Box>
          <Box mt={2} mb={1} textAlign="center">
            {lastItem ? (
              <>
                {" "}
                <IconButton
                  color="primary"
                  onClick={this.addNewIngredientsSection}
                  size="small"
                  style={{ marginRight: "20px" }}
                  title="add new teilgericht"
                >
                  <AddToPhotos />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={this.addNewIngredientStep(index)}
                  size="small"
                  style={{ marginLeft: "20px" }}
                  title="add new ingredient"
                >
                  <AddCircleIcon />
                </IconButton>
              </>
            ) : (
              <IconButton
                color="primary"
                onClick={this.addNewIngredientStep(index)}
                size="small"
                style={{ marginLeft: "20px" }}
                title="add new ingredient"
              >
                <AddCircleIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      );
    };

  forceAmount =
    (ingredientIndex: number, sectionIndex: number, amountToForce: number) =>
    () => {
      /**
       * assign:
       * 0 --> to reset the amount field
       * 1  --> or any value greater than one to switch to edit mode and add amount
       */
      this.props.setRecipeState((thisState) => {
        const newRecipeIngredientsSections = [...thisState.ingredientsSections];

        newRecipeIngredientsSections[sectionIndex].ingredients[
          ingredientIndex
        ] = {
          ...newRecipeIngredientsSections[sectionIndex].ingredients[
            ingredientIndex
          ],
          amount: amountToForce,
        };
        return {
          ...thisState,
          ingredients: newRecipeIngredientsSections,
        };
      });
    };

  renderAmount = (
    ingredientIndex: number,
    sectionIndex: number,
    amount: number
  ) => {
    const { t, classes } = this.props;
    return amount > 0 ? (
      <>
        <TextField
          size="small"
          type="number"
          onChange={this.onChangeValue(ingredientIndex, sectionIndex)("amount")}
          fullWidth
          required
          InputProps={{
            inputProps: { min: 0 },
          }}
          defaultValue={amount}
        />
        <Tooltip title={t("actions:delete") as string}>
          <IconButton
            className={[classes.iconRight, classes.noPadding].join(" ")}
            onClick={this.forceAmount(ingredientIndex, sectionIndex, 0)}
          >
            <CloseIcon fontSize="small" style={{ cursor: "pointer" }} />
          </IconButton>
        </Tooltip>
      </>
    ) : (
      <span
        style={{ cursor: "pointer" }}
        onClick={this.forceAmount(ingredientIndex, sectionIndex, 1)}
      >
        {t("actions:add")}
      </span>
    );
  };

  renderIngredientLine =
    (sectionIndex: number) => (ing: RecipeIngredients, index: number) => {
      const { id, amount, description, unit } = ing;
      return (
        <CustomGrid
          key={id}
          backgroundColor={index % 2 ? "#efefef" : "#e3e3e3"}
          name={this.renderIngredientName(ing, index, sectionIndex)}
          amount={this.renderAmount(index, sectionIndex, amount)}
          unit={this.renderUnitName(unit, index, sectionIndex)}
          description={
            <TextField
              size="small"
              onBlur={this.onChangeValue(index, sectionIndex)("description")}
              fullWidth
              required
              defaultValue={description}
            />
          }
          action={
            <IconButton
              onClick={this.deleteIngredient(index, sectionIndex)}
              color="primary"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          }
        />
      );
    };

  render() {
    const { recipe, setRecipeState, t } = this.props;
    const { recipeYield, ingredientsSections } = recipe;
    return (
      <>
        <Box mb={2}>
          <Typography variant="h3">{t("nutrition")}</Typography>
          <NutritionFields recipe={recipe} setState={setRecipeState} />
        </Box>
        <Box mb={2}>
          <Typography variant="h3">{t("ingredients")}</Typography>

          <TextField
            label={t("recipeYield")}
            onChange={this.onChangeYield}
            fullWidth
            InputProps={{
              inputProps: { min: 0 },
            }}
            type={"number"}
            required
            autoFocus
            margin="normal"
            defaultValue={recipeYield}
          />
        </Box>
        {ingredientsSections.length > 0
          ? ingredientsSections.map(
              this.renderIngredientsSection(ingredientsSections.length)
            )
          : this.addNewIngredientsSection()}
      </>
    );
  }
}
export const IngredientTab: React.FC<IngredientTabProps> = ({
  recipe,
  setRecipeState,
}) => {
  const { t } = useTranslation(["editRecipe", "actions"]);
  const [editLang] = useEditLang();
  const classes = useDefaultStyles();
  const [
    {
      ingrs: { data: ingrs },
      units: { data: units },
    },
    { refreshIngredients, refreshUnits },
  ] = useContext(RecipeDataContext);
  const [editIngredient, setEditIngredient] = useState<
    Ingredient & { sectionIndex: number; ingredientIndex: number }
  >();
  const [editUnit, setEditUnit] = useState<
    Unit & { sectionIndex: number; ingredientIndex: number }
  >();

  const onCloseEditIngredient = (newId?: number) => {
    setEditIngredient(undefined);
    if (newId === undefined || !editIngredient) return;
    const newIngredientsSections = [...recipe.ingredientsSections];
    const { id, sectionIndex, ingredientIndex, ...langObj } = editIngredient;

    newIngredientsSections[sectionIndex].ingredients[ingredientIndex] = {
      ...newIngredientsSections[sectionIndex].ingredients[ingredientIndex],
      ingredientId: newId,
      name: getLangLabel(langObj, editLang),
    };

    setRecipeState({
      ...recipe,
      ingredientsSections: newIngredientsSections,
    });
    refreshIngredients();
  };

  const onCloseEditUnit = (newId?: number) => {
    setEditUnit(undefined);
    if (newId === undefined || !editUnit) return;
    const newIngredientsSections = [...recipe.ingredientsSections];
    const { id, sectionIndex, ingredientIndex, ...langObj } = editUnit;
    newIngredientsSections[sectionIndex].ingredients[ingredientIndex] = {
      ...newIngredientsSections[sectionIndex].ingredients[ingredientIndex],
      unit: {
        id: newId,
        name: getLangLabel(langObj, editLang),
      },
    };
    setRecipeState({
      ...recipe,
      ingredientsSections: newIngredientsSections,
    });
    refreshUnits();
  };

  const { t: TeditIngredient } = useTranslation("editIngredient");
  const { t: TeditUnit } = useTranslation("editUnit");
  const onChangeIngredientLang =
    (l: RECIPE_LANGUAGES) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const v = event.target.value;
      setEditIngredient((ingredient) =>
        ingredient ? { ...ingredient, [l]: v } : undefined
      );
    };
  const onChangeUnitLang =
    (l: RECIPE_LANGUAGES) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const v = event.target.value;
      setEditUnit((unit) => (unit ? { ...unit, [l]: v } : undefined));
    };
  return (
    <>
      {editIngredient ? (
        <EditEntityDialog
          onChange={onChangeIngredientLang}
          t={TeditIngredient}
          entity={editIngredient}
          onClose={onCloseEditIngredient}
          addEntity={addIngredient}
          updateEntity={updateIngredient}
        />
      ) : null}
      {editUnit ? (
        <EditEntityDialog
          onChange={onChangeUnitLang}
          t={TeditUnit}
          entity={editUnit}
          onClose={onCloseEditUnit}
          addEntity={addUnit}
          updateEntity={updateUnit}
        />
      ) : null}
      <IngredientTabClass
        editLang={editLang}
        classes={classes}
        t={t}
        ingrs={ingrs || []}
        units={units || []}
        recipe={recipe}
        setEditIngredient={setEditIngredient}
        setEditUnit={setEditUnit}
        setRecipeState={setRecipeState}
      />
    </>
  );
};

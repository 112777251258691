import { AlertTitle } from "@material-ui/lab";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React from "react";

export const AlertComponent: React.FC<AlertProps & { title?: string }> = ({
  children,
  title,
  ...props
}) => {
  return (
    <MuiAlert elevation={0} {...props}>
      {title ? <AlertTitle>{title}</AlertTitle> : null}
      {children}
    </MuiAlert>
  );
};

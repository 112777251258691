import { makeStyles, Theme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Error";
import React, { FunctionComponent, useEffect, useState } from "react";
import { usePrevious } from "../../hooks/usePrevious";
import { WrapAPIError } from "./WrappApiErrors";
import { ErrorObject } from "../../utils/typesCheck";
const full: CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    loadingWrapper: {
      position: "relative",
      height: "100%",
      minHeight: 50,
    },
    loadingOverlay: {
      ...full,
      cursor: "not-allowed",
      opacity: 0.6,
      zIndex: 1,
      backgroundColor: theme.palette.grey["400"],
    },
    errorOverlay: {
      backgroundColor: theme.palette.error.light,
    },
    progressWrapper: {
      ...full,
      zIndex: 2,
      width: 50,
      height: 50,
      margin: "auto",
    },
    errorWrapper: {
      ...full,
      zIndex: 2,

      // width: 150,
      // height: 150,
      // margin: "auto",
      cursor: "pointer",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    errorInnerWrapper: {
      wordBreak: "break-word",
      textAlign: "center",
    },
    errorIcon: {
      fontSize: 50,
      color: "#c00",
    },
    errorText: {
      fontSize: 18,
      color: "#c00",
    },
    hidden: {
      display: "none",
    },
  };
});

export interface ILoadingComponentProps {
  isLoading: boolean;
  errorText?: ErrorObject;
  style?: CSSProperties;
  loadingOverlayStyle?: CSSProperties;
}

const LoadingComponent: FunctionComponent<ILoadingComponentProps> = ({
  isLoading,
  errorText,
  style,
  children,
  loadingOverlayStyle,
}) => {
  const [showError, setShowError] = useState(true);
  const classes = useStyles();
  const isError = showError && !!errorText;

  const prevErrorText = usePrevious(errorText);
  useEffect(() => {
    if (errorText && !showError && !prevErrorText) {
      setShowError(true);
    }
  }, [errorText, prevErrorText, showError]);

  const closeError = React.useCallback(() => {
    setShowError(false);
  }, [setShowError]);
  return (
    <div className={classes.loadingWrapper} style={style}>
      {children}
      {(isLoading && (
        <div className={classes.progressWrapper}>
          <CircularProgress size={50} />
        </div>
      )) ||
        ""}
      {(isError && (
        <div className={classes.errorWrapper} onClick={closeError}>
          <div className={classes.errorInnerWrapper}>
            <ErrorIcon className={classes.errorIcon} />
            <br />
            <Typography className={classes.errorText}>
              <WrapAPIError error={errorText!} />
            </Typography>
          </div>
        </div>
      )) ||
        ""}
      {(isLoading || isError) && (
        <div
          style={loadingOverlayStyle}
          className={[
            classes.loadingOverlay,
            (isError && classes.errorOverlay) || "",
          ].join(" ")}
          onClick={closeError}
        />
      )}
    </div>
  );
};

export default LoadingComponent;

import { requestWithTimeout } from ".";
import { API_URL } from "../config";
import { clearMyLangKeys } from "../utils/clearMyLangKeys";
import {
  CurrentMultiLangEntity,
  SubCatExtendEntity,
  CurrentMultiLangEntityWithRecipesCount,
} from "./types";

export interface RecipeSubCategoriesResponse {
  subcategories: CurrentMultiLangEntityWithRecipesCount<SubCatExtendEntity>[];
  code: number;
}

export const insertSubCategory = async ({
  id, //remove me from request
  name, //remove me from request
  categoryId,
  ...category
}: CurrentMultiLangEntity<SubCatExtendEntity>) => {
  return await requestWithTimeout<unknown, { subcategoryId: number }>({
    url: `${API_URL.SUBCATEGORIES}`,
    method: "POST",
    body: {
      name: clearMyLangKeys(category),
      categoryId,
    },
  }).then(({ subcategoryId }) => subcategoryId);
};
export const updateSubCategory = async ({
  categoryId,
  id, //remove me from request
  name, //remove me from request
  ...category
}: CurrentMultiLangEntity<SubCatExtendEntity>) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.SUBCATEGORIES}`,
    method: "PUT",
    body: {
      name: clearMyLangKeys(category),
      id,
    },
  }).then(() => categoryId);
};

export async function updateSubCatSortOrder(subCatId: number, sortOrder: string) {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.SUBCATEGORIES_SORT_ORDER}`,
    method: "PUT",
    body: {
      sortOrder,
      id: subCatId,
    },
  })
}

export const deleteSubCategory = async (id: number) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.SUBCATEGORIES}?id=${id}`,
    method: "DELETE",
  }).then(() => true);
};

export const deleteAllSubCategories = (selected: number[]) =>
  Promise.all(
    selected.map((id) =>
      deleteSubCategory(id)
        .then(() => true)
        .catch(() => false)
    )
  ).then((results) => results.filter((e) => e !== true));

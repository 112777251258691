import { API_URL } from "../config";
import { requestWithTimeout } from ".";
import { UserAuth } from "./types";

export const doLogin = async (email: string, password: string) => {
  // if (email === "ub@nuuk.de") {
  //   throw new Error("wrong mail");
  // }
  return await requestWithTimeout<UserAuth, UserAuth>({
    url: API_URL.LOGIN,
    method: "POST",
    body: { email, password },
    withoutAUTH: true,
    notRedirectOnAuthFail: true,
  });
};

export const doPwdReset = async (email: string): Promise<true> => {
  return await requestWithTimeout({
    url: API_URL.PWD_RESET,
    method: "POST",
    body: { email },
  }).then(() => true);
};

import { TFunction } from "i18next";
import { uploadImage } from "../api/images";
import { addNewStep, NewStep } from "../api/steps";
import { UploadableImage } from "../api/types";
import { createNewUniquId } from "./createNewUniquId";

export const createNewStep = async (
  newStep: NewStep,
  images: UploadableImage[],
  errorsRef: string[],
  t: TFunction,
  doIt = true,
  debug = true,
) => {
  const imageFiles: File[] = [];
  const cloneImage: string[] = [];
  images.forEach((image) => {
    if (image.uploadFile) imageFiles.push(image.uploadFile);
    else cloneImage.push(image.base64orUrl);
  });
  const stepId = doIt
    ? await addNewStep({
        ...newStep,
        defaultImages: cloneImage.length ? cloneImage : undefined,
      })
    : createNewUniquId();

  if (debug) console.debug("Would addNewStep" + JSON.stringify(newStep));
  if (imageFiles.length) {
    if (debug) console.debug("Would uploadImages " + imageFiles.length);
    if (doIt) {
      // go ahead on errors
      try {
        await uploadImage(imageFiles, { stepId });
      } catch (ex) {
        console.error(ex);
        errorsRef.push(t("editError:errorOnPicUpload"));
      }
    }
  }
  return stepId;
};

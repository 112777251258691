import React from "react";
import {
  ConfirmDialog,
  ConfirmDialogProps,
} from "./components/Dialogs/ConfirmDialog";
export type GlobalAlertProps = Omit<ConfirmDialogProps, "open" | "doIt"> & {
  doIt?: (okClicked: boolean) => void;
};

interface GlobalAlertState {
  show: boolean;
  alert?: GlobalAlertProps;
}

export class GlobalAlert extends React.Component<{}, GlobalAlertState> {
  constructor(props: {}) {
    super(props);
    this.state = { show: false, alert: undefined };
  }

  componentDidMount() {
    window.ALERT = this.setAlert;
  }

  public setAlert = (alert: GlobalAlertProps) => {
    this.setState({ alert, show: true });
  };

  render() {
    const { alert, show } = this.state;
    return (
      <ConfirmDialog
        open={!!alert && show}
        text={alert?.text}
        title={alert?.title}
        cancelLabel={alert?.cancelLabel}
        okLabel={alert?.okLabel}
        doIt={(okClicked) => {
          this.setState({ show: false });
          if (alert && alert.doIt) {
            alert.doIt(okClicked);
          }
        }}
      />
    );
  }
}
// export const GlobalAlert = withTranslation("actions")(GlobalAlertClass);
declare global {
  interface Window {
    ALERT: (props: GlobalAlertProps) => void;
  }
}

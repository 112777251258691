import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { RECIPE_STATE } from "../../api/types";
import { SplitButton, SplitButtonOptions } from "../atoms/SplitButton";

export interface RecipeStateSelectProps {
  selectedKey?: RECIPE_STATE;
  setSelectedState: (state: RECIPE_STATE) => void;
  disabledStates: RECIPE_STATE[];
  disabled: boolean;
}

const getRecipeStateOptions = (
  t: TFunction,
  disabled: Array<RECIPE_STATE>,
): SplitButtonOptions[] =>
  Object.keys(RECIPE_STATE).map((state) => {
    return {
      label: t(state),
      key: state,
      disabled: disabled.includes(state as RECIPE_STATE),
    };
  });

export const RecipeStateSelect: React.FC<RecipeStateSelectProps> = ({
  selectedKey,
  setSelectedState,
  disabledStates,
  disabled,
}) => {
  const { t } = useTranslation("recipeStates");
  const setSelected = (key: string) => {
    setSelectedState(key as RECIPE_STATE);
  };
  // const onChange = (
  //   event: React.ChangeEvent<{
  //     name?: string | undefined;
  //     value: unknown;
  //   }>,
  //   child: React.ReactNode,
  // ) => {
  //   setSelectedState(event.target.value as RECIPE_STATE);
  // };
  // const childs = Object.keys(RECIPE_STATE).map((state) => {
  //   return (
  //     <MenuItem key={state} value={state}>
  //       {t(state)}
  //     </MenuItem>
  //   );
  //   // return {
  //   //   label: t(state),
  //   //   key: state,
  //   //   disabled: disabled.includes(state as RECIPE_STATE),
  //   // };
  // });
  // return (
  //   <div
  //     style={{
  //       display: "flex",
  //       alignItems: "center",
  //       justifyContent: "flex-end",
  //     }}
  //   >
  //     <Box mr={2}>
  //       <Typography color="primary">{t("stateLabel")}: </Typography>
  //     </Box>
  //     <Select value={selectedKey} onChange={onChange}>
  //       {childs}
  //     </Select>
  //   </div>
  // );
  return (
    <SplitButton
      disabled={disabled}
      selectedKey={(selectedKey as string) || ""}
      setSelected={setSelected}
      buttonLabel={t("stateLabel")}
      options={getRecipeStateOptions(t, disabledStates)}
    />
  );
};

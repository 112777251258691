import React from "react";
import { DEFAULT_SPACING, CONTENT_PADDING } from "../../styles/themes";

export const FabContainer: React.FC<{ isFixed: boolean }> = ({
  children,
  isFixed,
}) => {
  return (
    <>
      <div
        style={{
          height: DEFAULT_SPACING * 7 + CONTENT_PADDING,
        }}
      ></div>
      <div
        className="rightSpacingChildren"
        style={{
          position: isFixed ? "fixed" : "initial",
          bottom: CONTENT_PADDING,
          right: CONTENT_PADDING,
          height: DEFAULT_SPACING * 7,
        }}
      >
        {children}
      </div>
    </>
  );
};

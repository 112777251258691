import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getAuthToken } from "../../api";

export const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = (props) => {
  const isAuthenticated = !!getAuthToken();

  return isAuthenticated ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    // <Redirect to={`/login?redirectTo=${document.location.href}`} />
    <Redirect to={`/login`} />
  );
};

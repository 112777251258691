import { Box, FormLabel } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  RecipeCategories,
  RecipeDetails,
  RecipeKeywords,
} from "../../../api/types";
import { AssociatedProductsChips } from "../AssociatedProducts";
import { CategoryChips } from "../CategoryChips";
import { KeywordChips } from "../KeywordChips";

export interface LinkTabProps {
  recipe: RecipeDetails;
  setState: React.Dispatch<React.SetStateAction<RecipeDetails>>;
}
export function LinkTab({
  setState,
  recipe: { keywords, associatedProducts, categories },
}: LinkTabProps) {
  const { t } = useTranslation("editRecipe");
  const updateKeywords = (newKeywords: RecipeKeywords[]) => {
    setState((thisState) => ({
      ...thisState,
      keywords: newKeywords,
    }));
  };
  const updateCategories = (newCategories: RecipeCategories[]) => {
    setState((thisState) => ({
      ...thisState,
      categories: newCategories,
    }));
  };

  const updateProducts = (newProducts: string) => {
    setState((thisState) => ({
      ...thisState,
      associatedProducts: newProducts,
    }));
  };

  return (
    <>
      <Box mt={2} mb={1}>
        <FormLabel>
          {t("keywords")}
          <span>*</span>
        </FormLabel>
        <KeywordChips keywords={keywords} updateKeywords={updateKeywords} />
      </Box>
      <Box mt={2} mb={1}>
        <FormLabel>
          {t("categories")}
          <span>*</span>
        </FormLabel>
        <CategoryChips
          categories={categories}
          updateCategories={updateCategories}
        />
      </Box>
      <Box mt={2} mb={1}>
        <FormLabel>{t("products")}</FormLabel>
        <AssociatedProductsChips
          products={associatedProducts}
          updateProducts={updateProducts}
        />
      </Box>
    </>
  );
}

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useTranslation } from "react-i18next";
import { clearMyLangKeys } from "../../utils/clearMyLangKeys";
import { CurrentMultiLangEntity, RECIPE_LANGUAGES } from "../../api/types";
import { createNewUniquId } from "../../utils/createNewUniquId";
import { RMSTableData } from "../RMSTable";

export type AutoCompleteItem = RMSTableData<CurrentMultiLangEntity<{}>>;

export interface CurrentMultiLangEntityAutoCompleteProps {
  onSelect: (i: AutoCompleteItem | null) => void;
  data: AutoCompleteItem[];
  addLabel?: string;
  label?: string;
  doNotBlur?: boolean;
  value?: {
    id: number;
    title: string;
    item: RMSTableData<CurrentMultiLangEntity<{}>>;
  };
  editLang: RECIPE_LANGUAGES;
  deleteTriggered?: boolean;
}
export const CurrentMultiLangEntityAutoComplete: React.FC<CurrentMultiLangEntityAutoCompleteProps> =
  ({
    onSelect,
    editLang,
    addLabel,
    value,
    label,
    doNotBlur,
    data: dataRaw,
    deleteTriggered,
  }) => {
    const { t } = useTranslation("actions");

    const onChange = (
      event: React.ChangeEvent<{}>,
      value: { id: number; item: AutoCompleteItem; title: string } | null
    ) => {
      if (!value) {
        onSelect(null);
        return;
      }
      onSelect(value.item);
    };

    const data = React.useMemo(
      () =>
        (dataRaw || []).map((item) => ({
          id: item.id,
          title: item.name,
          item,
        })),
      [dataRaw]
    );

    const onBlur = (deleteTriggered: boolean | undefined) => () => {
      if (!doNotBlur && !deleteTriggered) onSelect(null);
    };
    return (
      <Autocomplete
        size="small"
        openText={t("open")}
        loadingText={t("loading")}
        noOptionsText={t("noOptionsText")}
        clearText={t("deleteLabel")}
        onChange={onChange}
        value={value}
        options={data}
        getOptionLabel={(option) => option.title}
        filterOptions={(options, params) => {
          const filtered = options.filter(
            ({ title }) =>
              title.toLowerCase().indexOf(params.inputValue.toLowerCase()) !==
              -1
          );
          const exactMatch = filtered.find(
            ({ title }) => title === params.inputValue
          );
          // Suggest the creation of a new value
          if (addLabel && !exactMatch && params.inputValue !== "") {
            const id = createNewUniquId();
            filtered.push({
              title: addLabel + params.inputValue,
              id,
              item: {
                id,
                name: params.inputValue,
                ...clearMyLangKeys({}),
                [editLang]: params.inputValue,
              },
            });
          }

          return filtered;
        }}
        fullWidth
        renderInput={(params) => (
          <TextField
            // style={{ paddingTop: 6, paddingBottom: 7 }}
            label={label}
            onBlur={onBlur(deleteTriggered)}
            autoFocus
            {...params}
            placeholder={t("search")}
          />
        )}
      />
    );
  };

import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_GRID_SPACING } from "../../styles/themes";
import { BaseLayout } from "../layout/BaseLayout";
import { ListIngredients } from "../molecules/ListIngredients";
import { ListRecipes } from "../molecules/ListRecipes";
import { ListUnits } from "../molecules/ListUnits";

export const ListIngUnitsPage: React.FC = () => {
  const { t } = useTranslation("menu");
  const [rsearchValue, setRSearchValue] = useState<string>();
  return (
    <BaseLayout>
      <Grid container spacing={DEFAULT_GRID_SPACING}>
        <Grid item xs={6}>
          <Typography variant="h3">{t("ingredients")}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h3">{t("units")}</Typography>
        </Grid>
        <Grid item xs={6}>
          <ListIngredients onClick={setRSearchValue} />
        </Grid>
        <Grid item xs={6}>
          <ListUnits />
        </Grid>
      </Grid>
      {rsearchValue ? (
        <ListRecipes
          searchValue={rsearchValue}
          rowsPerPage={5}
          offset={0}
          page={0}
          searchKey={"ingredient"}
        />
      ) : null}
    </BaseLayout>
  );
};

import React, { useContext, useState } from "react";
import {
  RecipeCategories,
  CurrentMultiLangEntity,
  SubCatExtendEntity,
} from "../../api/types";
import { RecipeDataContext } from "../../provider/RecipeData";
import { AddCategoryDialogChips } from "../Dialogs/AddCategoryDialog";
import { SubAndMainCategoryEditDialog } from "../Dialogs/SubAndMainCategoryEditDialog";
import { ElementChip, ElementChips } from "./ElementChips";

export interface CategoryChipsProps {
  categories: RecipeCategories[];
  updateCategories: (categories: RecipeCategories[]) => void;
}

export const CategoryChips: React.FC<CategoryChipsProps> = ({
  categories,
  updateCategories,
}) => {
  const [
    {
      mCat: { data: mdata },
      sCat: { data: sdata },
    },
    { refreshMainCategories, refreshSubCategories },
  ] = useContext(RecipeDataContext);
  const [openAdd, setOpenAdd] = useState(false);
  const categoryElements = React.useMemo((): Array<
    ElementChip<RecipeCategories>
  > => {
    return categories.map(({ subcatId, catId }) => {
      let name: string = "";
      let key: string = catId + (subcatId !== undefined ? "-" + subcatId : "");
      const mCatName = (mdata || []).find(({ id }) => id === catId)?.name;
      const sCatName = (subcatId !== undefined
        ? (sdata || []).find(({ id }) => id === subcatId)
        : undefined
      )?.name;
      if (mCatName) {
        name += mCatName;
      }
      if (sCatName) {
        name += "/" + sCatName;
      }

      return {
        label: name,
        key,
        subcatId,
        catId,
      };
    });
  }, [categories, mdata, sdata]);

  const addCategory = () => {
    setOpenAdd(true);
  };
  const onCategoryDelete = ({
    catId: c,
    subcatId: s,
  }: ElementChip<RecipeCategories>) => {
    const newCategorys = categories.filter(
      ({ catId, subcatId }) => catId !== c || subcatId !== s,
    );
    updateCategories(newCategorys);
  };
  const onAddCategory = (newCategory?: RecipeCategories) => {
    if (newCategory) updateCategories([...categories, newCategory]);
    setOpenAdd(false);
  };

  const [{ main, sub }, setEditCategories] = useState<{
    main?: CurrentMultiLangEntity;
    sub?: CurrentMultiLangEntity<SubCatExtendEntity>;
  }>({});

  const onEditElement = (item: ElementChip<RecipeCategories>) => {
    if (!item.subcatId) {
      return;
    }
    const main = mdata?.find(({ id }) => id === item.catId);
    const sub = sdata?.find(({ id }) => id === item.subcatId);
    setEditCategories({ main, sub });
  };

  const onEditClose = (isSaved: boolean) => {
    if (isSaved) {
      refreshMainCategories();
      refreshSubCategories();
    }
    setEditCategories({});
  };

  return (
    <>
      {main && sub ? (
        <SubAndMainCategoryEditDialog
          main={main}
          sub={sub}
          onClose={onEditClose}
        />
      ) : null}
      {openAdd ? (
        <AddCategoryDialogChips categories={categories} onAdd={onAddCategory} />
      ) : null}
      <ElementChips
        elements={categoryElements}
        onElementDelete={onCategoryDelete}
        addElement={addCategory}
        onEditElement={onEditElement}
      />
    </>
  );
};

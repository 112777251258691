import React, { useState, ChangeEvent } from "react";

import {
  Dialog,
  DialogContent,
  TextField,
  AppBar,
  Toolbar,
  Typography,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
  Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Users } from "../../api/types";
import LoadingComponent from "../atoms/LoadingComponent";
import { updateUser, insertUser, InsertUser } from "../../api/users";
import { TFunction } from "i18next";
import { useSimpleRequest } from "../../hooks/useSimpleRequest";

export interface UserEditDialogProps {
  user: Users;
  onClose: (refreshData: boolean) => void;
}
const validateEmailRegexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
export const validateEmail = (email: string) => {
  return validateEmailRegexp.test(String(email).toLowerCase());
};

const validateForm = (
  isNew: boolean,
  newValue: InsertUser,
  t: TFunction
): [keyof InsertUser, string] | true => {
  if (isNew) {
    if (!newValue.firstName) {
      return ["firstName", t("validate:notEmpty")];
    }
    if (!newValue.lastName) {
      return ["lastName", t("validate:notEmpty")];
    }
    if (!newValue.password) {
      return ["password", t("validate:notEmpty")];
    }
    if (!newValue.email) {
      return ["email", t("validate:notEmpty")];
    }
  }
  if (newValue.password) {
    if (newValue.password.length < 8) {
      return ["password", t("validate:pwdToShort")];
    }
  }
  if (!validateEmail(newValue.email)) {
    return ["email", t("validate:invalid")];
  }
  return true;
};

export const UserEditDialog: React.FC<UserEditDialogProps> = ({
  user,
  onClose,
}) => {
  const { t } = useTranslation(["editUser", "actions", "validate"]);
  const [fieldError, setFieldError] = useState<
    [keyof InsertUser, string] | undefined
  >(undefined);
  const [editedUser, setUser] = useState<InsertUser>({
    ...user,
    password: "",
  });
  const [{ loading, error }, setSaveState] = useSimpleRequest();

  const toggleAdmin = () => {
    setUser({ ...editedUser, admin: editedUser.admin ? false : true });
  };
  const onChange = (
    key: keyof InsertUser,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    if (editedUser) {
      setUser({ ...editedUser, [key]: value });
    }
  };
  const isNew = user ? user.id < 0 : false;

  const onSave = () => {
    const result = validateForm(isNew, editedUser, t);
    if (result === true) {
      setSaveState({ loading: true, error: undefined });
      (!isNew ? updateUser(editedUser) : insertUser(editedUser))
        .then(() => {
          setSaveState({ loading: false, error: undefined });
          onClose(true);
        })
        .catch((e) => {
          setSaveState({ loading: false, error: e });
        });
    } else {
      setFieldError(result);
    }
  };

  return (
    <Dialog open={true} onEscapeKeyDown={() => onClose(false)}>
      <AppBar elevation={0} style={{ position: "relative" }}>
        <Toolbar>
          <Typography variant="h6">
            {!isNew ? t("actions:edit") : t("actions:create")}
          </Typography>
        </Toolbar>
      </AppBar>
      <LoadingComponent isLoading={loading} errorText={error}>
        <DialogContent>
          <TextField
            margin="normal"
            label={t("firstName")}
            required={isNew}
            error={fieldError && fieldError[0] === "firstName"}
            helperText={fieldError && fieldError[1]}
            name="firstName"
            autoComplete="off"
            value={editedUser.firstName}
            onChange={onChange.bind(null, "firstName")}
            fullWidth
          />
          <TextField
            margin="normal"
            label={t("lastName")}
            name="lastName"
            required={isNew}
            error={fieldError && fieldError[0] === "lastName"}
            helperText={fieldError && fieldError[1]}
            autoComplete="off"
            value={editedUser.lastName}
            onChange={onChange.bind(null, "lastName")}
            fullWidth
          />
          <TextField
            margin="normal"
            label={t("email")}
            name="mail"
            error={fieldError && fieldError[0] === "email"}
            helperText={fieldError && fieldError[1]}
            required={isNew}
            autoComplete="new-mail"
            disabled={!isNew}
            value={editedUser.email}
            onChange={onChange.bind(null, "email")}
            fullWidth
          />
          <TextField
            margin="normal"
            type="password"
            name="password"
            required={isNew}
            error={fieldError && fieldError[0] === "password"}
            helperText={fieldError && fieldError[1]}
            autoComplete="new-password"
            label={t(isNew ? "password" : "changePassword")}
            value={editedUser.password}
            onChange={onChange.bind(null, "password")}
            fullWidth
          />
          <Box mt={2} mb={2} onClick={toggleAdmin}>
            <FormControlLabel
              control={
                <Switch
                  name="admin"
                  checked={!!editedUser.admin}
                  color="primary"
                />
              }
              label={t("isAdmin")}
              labelPlacement="end"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)}>{t("actions:cancel")}</Button>
          <Button onClick={() => onSave()} color="primary">
            {!isNew ? t("actions:save") : t("actions:create")}
          </Button>
        </DialogActions>
      </LoadingComponent>
    </Dialog>
  );
};

import {
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import Box from "@material-ui/core/Box/Box";
import Container from "@material-ui/core/Container/Container";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import RefreshIcon from "@material-ui/icons/Refresh";
import * as H from "history";
import { TFunction } from "i18next";
import moment from "moment";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SetterOrUpdater } from "recoil";
import {
  deleteRecipeMain,
  deleteSubRecipe,
  togglePublishState,
} from "../../api/recipes";
import {
  CurrentMultiLangEntity,
  MultiLangEntityState,
  RecipeCategories,
  RecipeSearchDetails,
  RECIPE_STATE,
  SubCatExtendEntity,
} from "../../api/types";
import { getAddRecipeUrl, getEditRecipeUrl } from "../../AppNavigation";
import { DEFAULT_DEBOUNCE_TIME } from "../../config";
import {
  ListRecipeSearchDetails,
  useRecipeSearch,
  UseRecipeSearchState,
} from "../../hooks/useRecipeSearch";
import { ReqState } from "../../hooks/useRequestURL";
import { IAppState, SearchKey } from "../../provider/AppState";
import { RecipeDataContext } from "../../provider/RecipeData";
import { DEFAULT_SPACING } from "../../styles/themes";
import { AlertComponent } from "../atoms/Alert";
import { DebouncedSearch } from "../atoms/DebouncedSearch";
import { FabContainer } from "../atoms/FabContainer";
import LoadingComponentImage from "../atoms/LoadingComponentImage";
import { ConfirmDialog } from "../Dialogs/ConfirmDialog";
import { BaseLayout } from "../layout/BaseLayout";
import {
  CategoryAutoComplete,
  CategoryAutoCompleteOption,
} from "../molecules/CategoryAutoComplete";
import {
  AutoCompleteItem,
  CurrentMultiLangEntityAutoComplete,
} from "../molecules/CurrentMultiLangEntityAutoComplete";
import { RecipeStateSelect } from "../molecules/RecipeStateSelect";
import { RMSTableData, RMSTableHeadCell, RMSTableLazyLoad } from "../RMSTable";

const LightTooltip = withStyles(() => ({
  tooltip: {
    minWidth: 400,
    minHeight: 300,
    padding: 0,
  },
}))(Tooltip);

const disablePadding = true;

const SEARCH_KEYS: SearchKey[] = [
  "title",
  "headline",
  "description",
  "author",
  "status",
  "ingredient",
  "category",
  "keyword",
  "product",
];

interface ListRecipesPageProps {
  searchState: UseRecipeSearchState;
  successColor: string;
  errorColor: string;
  setSearchState: SetterOrUpdater<IAppState>;
  t: TFunction;
  refresh: () => void;
  ingrs: ReqState<MultiLangEntityState<{}>>;
  kwrds: ReqState<MultiLangEntityState<{}>>;
  history: H.History<H.History.UnknownFacade>;
  mdata: ReqState<MultiLangEntityState<{}>>;
  sdata: ReqState<MultiLangEntityState<SubCatExtendEntity>>;
}
interface ListRecipePageState {
  // deleteRecipe: ListRecipeSearchDetails;
  showDeleteWarn?: RecipeSearchDetails;
  deleteAll: boolean;
}
class ListRecipesPageClass extends React.Component<
  ListRecipesPageProps,
  ListRecipePageState
> {
  constructor(props: ListRecipesPageProps) {
    super(props);
    this.state = {
      deleteAll: false,
    };
  }
  private getHeadCells = (): RMSTableHeadCell<
    RMSTableData<ListRecipeSearchDetails>
  >[] => {
    const { t, successColor, errorColor } = this.props;
    return [
      {
        disablePadding: true,
        width: "20%",
        id: "actions",
        label: t("actions"),
        numeric: false,
        sortable: false,
        formatter: (r) => {
          const { mainId, status } = r;
          const pending = status === "pending";
          const color = pending ? errorColor : successColor;
          const title = t((pending ? "publish" : "unpublish") as string);
          if (r.image) {
            const preLoad = new Image();
            preLoad.src = r.image.path;
          }
          const imagePreview = r.image ? (
            <LightTooltip
              title={
                <LoadingComponentImage
                  wrapperStyle={{
                    width: 400,
                    height: 300,
                    display: "flex",
                    backgroundColor: "#333",
                  }}
                  src={r.image.path}
                />
              }
            >
              <span style={{ marginRight: DEFAULT_SPACING }}>
                <ImageSearchIcon />
              </span>
            </LightTooltip>
          ) : null;
          return (
            <div
              style={{
                height: 45,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Tooltip title={t("actions:edit") as string}>
                <span
                  onClick={this.onEditClick.bind(null, r)}
                  style={{ marginRight: DEFAULT_SPACING, cursor: "pointer" }}
                >
                  <EditIcon />
                </span>
              </Tooltip>
              <Tooltip title={title}>
                <span
                  onClick={this.publish.bind(null, mainId, pending)}
                  style={{
                    marginRight: DEFAULT_SPACING,
                    color,
                    cursor: "pointer",
                  }}
                >
                  {!pending ? <LockOpenIcon /> : <LockIcon />}
                </span>
              </Tooltip>
              <Tooltip title={t("actions:deleteLabel") as string}>
                <span
                  onClick={this.onDeleteClick.bind(null, r)}
                  style={{ cursor: "pointer", marginRight: DEFAULT_SPACING }}
                >
                  <DeleteIcon />
                </span>
              </Tooltip>
              {imagePreview}
            </div>
          );
        },
      },
      {
        disablePadding,
        width: "30%",
        id: "name",
        label: t("name"),
        numeric: false,
        sortable: false,
      },
      {
        disablePadding,
        width: "35%",
        id: "headline",
        label: t("headline"),
        numeric: false,
        sortable: false,
      },
      {
        disablePadding,
        width: "15%",
        id: "dateModified",
        label: t("dateModified"),
        numeric: false,
        sortable: false,
        formatter: (r) => {
          return r.dateModified
            ? moment(r.dateModified).format(t("date:fulldateFormat"))
            : null;
        },
      },
    ];
  };
  private handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    this.props.setSearchState((appState) => ({
      ...appState,
      recipeSeach: { ...appState.recipeSeach, page: newPage },
    }));
  };

  private handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    this.props.setSearchState((appState) => ({
      ...appState,
      recipeSeach: { ...appState.recipeSeach, page: 0, rowsPerPage },
    }));
  };

  private onAddClick = () => {
    this.props.history.push(getAddRecipeUrl());
  };
  private onEditClick = (v: RMSTableData<ListRecipeSearchDetails>) => {
    this.props.history.push(getEditRecipeUrl(v.mainId));
  };
  private setSearchValue = (v: string, searchId?: number | undefined) => {
    this.props.setSearchState((appState) => ({
      ...appState,
      recipeSeach: {
        ...appState.recipeSeach,
        page: 0,
        searchValue: v,
        searchId,
      },
    }));
  };
  private publish = async (
    mainId: number,
    pending: boolean,
    event: React.MouseEvent,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    const {
      refresh,
      searchState: { language },
      t,
    } = this.props;
    try {
      const errors = await togglePublishState(
        mainId,
        language,
        t,
        this.props.mdata.data || [],
        this.props.sdata.data || [],
      );
      if (!errors.length) {
        refresh();
      } else {
        window.ALERT({
          text: (
            <AlertComponent
              variant="outlined"
              severity="error"
              title={t("couldNotPublished")}
              style={{ marginBottom: DEFAULT_SPACING * 2 }}
            >
              {errors.map((e, i) => (
                <span key={i}>
                  {e}
                  <br />
                </span>
              ))}
            </AlertComponent>
          ),
        });
      }
    } catch (x) {
      console.error(x);
    }
  };
  private changeSearchKey = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newSearchKey = event.target.value as SearchKey;
    this.props.setSearchState((appState) => ({
      ...appState,
      recipeSeach: {
        ...appState.recipeSeach,
        page: 0,
        searchValue: "",
        searchKey: newSearchKey,
      },
    }));
  };

  private setSelectedState = (state: RECIPE_STATE) => {
    this.props.setSearchState((appState) => ({
      ...appState,
      recipeSeach: {
        ...appState.recipeSeach,
        page: 0,
        searchKey: "status",
        searchValue: state,
      },
    }));
  };

  onDeleteClick = (r: RecipeSearchDetails, event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ showDeleteWarn: r, deleteAll: false });
  };

  onSelectIngredient = (ingredient: AutoCompleteItem | null) => {
    if (ingredient) this.setSearchValue(ingredient.name, ingredient.id);
    else this.setSearchValue("", undefined);
  };
  onSelectCategory = (
    cat: RecipeCategories,
    subCat: CurrentMultiLangEntity<SubCatExtendEntity>,
  ) => {
    // this.setSearchValue(cat.subcatId ? cat.subcatId.toString() : "-1");
    if (cat && cat.subcatId && subCat) {
      // subCat?.name
      this.setSearchValue(subCat?.name, cat.subcatId);
    } else this.setSearchValue("", undefined);
  };

  renderSearchField = () => {
    const {
      searchState: { language: editLang, searchKey, searchValue, searchId },
      t,
      ingrs,
      sdata,
      kwrds,
      mdata,
    } = this.props;
    if (searchKey === "keyword") {
      const item =
        searchId !== undefined
          ? (kwrds.data || []).find(({ id }) => id === searchId)
          : undefined;
      const value = item ? { id: item.id, title: item.name, item } : undefined;
      return !kwrds.loading ? (
        <CurrentMultiLangEntityAutoComplete
          data={kwrds.data || []}
          editLang={editLang}
          label={t("keyword")}
          doNotBlur
          value={value}
          onSelect={this.onSelectIngredient}
        />
      ) : (
        <CircularProgress />
      );
    }
    if (searchKey === "ingredient") {
      const item = searchId
        ? (ingrs.data || []).find(({ id }) => id === searchId)
        : undefined;
      const value = item ? { id: item.id, title: item.name, item } : undefined;
      return !ingrs.loading ? (
        <CurrentMultiLangEntityAutoComplete
          data={ingrs.data || []}
          editLang={editLang}
          label={t("ingredient")}
          doNotBlur
          value={value}
          onSelect={this.onSelectIngredient}
        />
      ) : (
        <CircularProgress />
      );
    }
    if (searchKey === "category") {
      const sitem = searchId
        ? (sdata.data || []).find(({ id }) => id === searchId)
        : undefined;
      const mitem = sitem
        ? (mdata.data || []).find(({ id }) => id === sitem.categoryId)
        : undefined;
      const value: CategoryAutoCompleteOption | undefined =
        sitem && mitem
          ? {
              label: sitem.name,
              groupBy: mitem.name,
              main: mitem,
              sub: sitem,
            }
          : undefined;
      return !sdata.loading && !mdata.loading ? (
        <CategoryAutoComplete
          editLang={editLang}
          selected={[]}
          value={value}
          variant={"standard"}
          onSelect={this.onSelectCategory}
        />
      ) : (
        <CircularProgress />
      );
    }
    if (searchKey === "status")
      return (
        <Box mt={2}>
          <RecipeStateSelect
            disabled={false}
            disabledStates={[]}
            setSelectedState={this.setSelectedState}
            selectedKey={searchValue as RECIPE_STATE}
          />
        </Box>
      );
    return (
      <DebouncedSearch
        debounceTime={DEFAULT_DEBOUNCE_TIME * 2}
        updateSearchValue={this.setSearchValue}
        value={searchValue}
        label={t("actions:search")}
        txtFp={{
          variant: "standard",
          placeholder: t(searchKey),
        }}
      />
    );
  };
  closeDeleteLanguageConfirm = (doIt: boolean) => {
    const { showDeleteWarn, deleteAll } = this.state;
    const { refresh } = this.props;

    if (doIt && showDeleteWarn) {
      if (deleteAll || showDeleteWarn.inLanguage.length <= 1) {
        deleteRecipeMain(showDeleteWarn.mainId).then(() => {
          refresh();
        });
      } else {
        deleteSubRecipe(showDeleteWarn.recipeId).then(() => {
          refresh();
        });
      }
    }
    this.setState({ showDeleteWarn: undefined });
  };
  render() {
    const { showDeleteWarn, deleteAll } = this.state;
    const {
      refresh,
      searchState: {
        loading,
        data,
        error,
        language: editLang,
        page,
        searchKey,
        rowsPerPage,
      },
      t,
    } = this.props;

    const searchKeyItems = SEARCH_KEYS.map((sk) => {
      return (
        <MenuItem value={sk} key={sk}>
          {t(sk)}
        </MenuItem>
      );
    });
    return (
      <BaseLayout>
        <ConfirmDialog
          open={!!showDeleteWarn}
          doIt={this.closeDeleteLanguageConfirm}
          cancelLabel={t("actions:cancel")}
          okLabel={t("actions:deleteLabel")}
          text={
            <>
              <Typography>
                {t("langEdit:deleteLangWarn", { language: editLang })}
              </Typography>
              {showDeleteWarn && showDeleteWarn.inLanguage.length > 1 ? (
                <Box mt={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={deleteAll}
                        onChange={() =>
                          this.setState({ deleteAll: !deleteAll })
                        }
                        name="checkedA"
                      />
                    }
                    label={t("langEdit:deleteLangCheckbox", {
                      length: showDeleteWarn.inLanguage.length,
                    })}
                  />
                </Box>
              ) : null}
            </>
          }
          title={t("langEdit:deleteLang")}
        />
        <Container style={{ padding: 0 }}>
          <Box display="flex" mb={3}>
            <div style={{ flexBasis: 150 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("searchKey")}
                </InputLabel>
                <Select
                  value={searchKey}
                  onChange={this.changeSearchKey}
                  displayEmpty
                  variant="standard"
                >
                  {searchKeyItems}
                </Select>
              </FormControl>
            </div>
            <div style={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
              {this.renderSearchField()}
            </div>
            <div>
              <IconButton onClick={refresh}>
                <RefreshIcon />
              </IconButton>
            </div>
          </Box>
          <RMSTableLazyLoad
            error={error}
            rows={
              data
                ? (data.recipes as RMSTableData<ListRecipeSearchDetails>[])
                : []
            }
            rowCount={data ? data.count : 0}
            startOrderBy={"status"}
            pagination={{
              labelRowsPerPage: t("tbl:labelRowsPerPage"),
            }}
            isLoading={loading}
            cellDefinition={this.getHeadCells()}
            // setSelected={() => {}}
            // selected={undefined}
            page={page}
            rowsPerPage={rowsPerPage}
            isLazyLoad
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <FabContainer isFixed>
            <Fab color="primary" onClick={this.onAddClick}>
              <AddIcon />
            </Fab>
          </FabContainer>
        </Container>
      </BaseLayout>
    );
  }
}

export const ListRecipesPage: React.FC = () => {
  const [searchState, setSearchState, refresh] = useRecipeSearch();
  const { t } = useTranslation([
    "editRecipe",
    "tbl",
    "langEdit",
    "recipeStates",
    "date",
  ]);
  const {
    palette: {
      success: { main: successColor },
      error: { main: errorColor },
    },
  } = useTheme();
  const [{ mCat, sCat, ingrs, kwrds }] = useContext(RecipeDataContext);

  const history = useHistory();
  return (
    <ListRecipesPageClass
      {...{
        history,
        searchState,
        ingrs: ingrs || [],
        setSearchState,
        mdata: mCat,
        sdata: sCat,
        kwrds,
        t,
        refresh,
        successColor,
        errorColor,
      }}
    />
  );
};

import { requestWithTimeout } from ".";
import { API_URL } from "../config";
import { clearMyLangKeys } from "../utils/clearMyLangKeys";
import { CategoriesEntity, CurrentMultiLangEntity } from "./types";

export interface RecipeMainCategoriesResponse {
  categories: CategoriesEntity[];
  code: number;
}

export const insertMainCategory = async ({
  id, //remove me from request
  name, //remove me from request
  ...category
}: CurrentMultiLangEntity) => {
  return await requestWithTimeout<unknown, { categoryId: number }>({
    url: `${API_URL.CATEGORIES}`,
    method: "POST",
    body: clearMyLangKeys(category),
  }).then(({ categoryId }) => categoryId);
};
export const updateCategory = async ({
  id, //remove me from request
  name, //remove me from request
  ...category
}: CurrentMultiLangEntity) => {
  return await requestWithTimeout<unknown, { categoryId: number }>({
    url: `${API_URL.CATEGORIES}`,
    method: "PUT",
    body: { name: clearMyLangKeys(category), id },
  }).then(() => id);
};

export const deleteMainCategory = async (id: number) => {
  return await requestWithTimeout<unknown, unknown>({
    url: `${API_URL.CATEGORIES}?id=${id}`,
    method: "DELETE",
  }).then(() => true);
};

export const deleteAllMainCategories = (selected: number[]) =>
  Promise.all(
    selected.map((id) =>
      deleteMainCategory(id)
        .then(() => true)
        .catch(() => false)
    )
  ).then((results) => results.filter((e) => e !== true));

import { MIN_IMAGE_SIZE } from "../config";

const imageCheck = (src: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.onerror = (error) => reject(error);
    img.onload = () => resolve(img);
    img.src = src;
  });

const fileRead = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

export const imgFileToBase64 = async (file: File) => {
  const imgContent = await fileRead(file);
  const img = await imageCheck(imgContent);
  console.log("image size", file.size);
  if (file.size / (1024 * 1024) > 4) {
    throw new Error("tooBig:" + file.name);
  }
  if (img.width < MIN_IMAGE_SIZE.width || img.height < MIN_IMAGE_SIZE.height) {
    throw new Error("tooSmall:" + file.name);
  }
  return img.src;
};

import { Button, Menu, MenuItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import moment from "moment";
import React, { ReactNode, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { logout } from "../../api";
import { RECIPE_LANGUAGES } from "../../api/types";
import { MenuItems } from "../../AppNavigation";
import { RECIPE_LANGUAGES_ARRAY } from "../../config";
import { useEditLang } from "../../hooks/useEditLang";
import { AppStateContext } from "../../provider/AppState";
import { CONTENT_PADDING, useDefaultStyles } from "../../styles/themes";
import { DrawerItem } from "../atoms/DrawerItem";
import { LangSelector } from "../atoms/LangSelector";
const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      flexGrow: 1,
      padding: CONTENT_PADDING,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  })
);

interface BaseLayoutProps {
  children?: ReactNode;
}

export const getMenuItems = (pathname: string, isAdmin: boolean) => {
  return MenuItems.filter(
    ({ admin }) => (admin === true && isAdmin) || !admin
  ).map(({ langKey, Icon, path }) => {
    return (
      <DrawerItem
        selected={pathname === path}
        key={langKey}
        langKey={langKey}
        Icon={Icon}
        path={path}
      />
    );
  });
};

export const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  const classes = useStyles();
  const dclasses = useDefaultStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation("translation");
  const [editLang] = useEditLang();
  const x = useContext(AppStateContext);
  if (!x) throw Error("no context");
  const [, setAppState] = x;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { pathname } = useLocation();

  const logoutExpires = window.AUTH
    ? moment(window.AUTH.exp * 1000).diff(moment(), "minutes")
    : undefined;

  const drawerItems = getMenuItems(pathname, !!window.AUTH?.isAdmin);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    i18n.changeLanguage(editLang.split("-")[0]);
  }, [editLang, i18n]);
  const setEditLang = (lang: RECIPE_LANGUAGES) => {
    setAppState((appState) => ({ ...appState, language: lang }));
    i18n.changeLanguage(editLang.split("-")[0]);
  };

  const drawer = (
    <>
      <Toolbar />
      <div className={classes.drawerContainer}>{drawerItems}</div>
    </>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            style={{ flex: 1 }}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{ flex: 1 }}>
            {t("appBarTitle")}
          </Typography>

          <LangSelector
            onChange={(e) => setEditLang(e.target.value as RECIPE_LANGUAGES)}
            languages={RECIPE_LANGUAGES_ARRAY}
            selLang={editLang}
          />
          {logoutExpires && logoutExpires < 15 ? (
            <Button onClick={logout} style={{ color: "#f77" }}>
              {t("logoutExpires", { minutes: logoutExpires })}
            </Button>
          ) : null}
          <IconButton
            onClick={handleClick}
            aria-controls="simple-menu"
            aria-haspopup="true"
            style={{
              flexBasis: 30,
              color: "#fff",
            }}
          >
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={logout}>
            <ExitToAppIcon className={dclasses.iconLeft} fontSize="small" />
            Logout
          </MenuItem>
        </Menu>
      </AppBar>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <nav>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Hidden>
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
};

/* eslint-disable no-use-before-define */
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  RecipeCategories,
  CurrentMultiLangEntity,
  SubCatExtendEntity,
  RECIPE_LANGUAGES,
} from "../../api/types";
import { RecipeDataContext } from "../../provider/RecipeData";
import { RMSTableData } from "../RMSTable";

export interface CategoryAutoCompleteOption {
  label: string;
  main: CurrentMultiLangEntity;
  sub: CurrentMultiLangEntity<SubCatExtendEntity>;
  groupBy?: string;
}

export interface CategoryAutoCompleteProps {
  onSelect: (
    cat: RecipeCategories,
    subCat: CurrentMultiLangEntity<SubCatExtendEntity>,
  ) => void;
  selected: RecipeCategories[];
  editLang: RECIPE_LANGUAGES;
  value?: CategoryAutoCompleteOption;
  variant?: "outlined" | "standard";
}
export const CategoryAutoComplete: React.FC<CategoryAutoCompleteProps> = ({
  onSelect,
  selected,
  value,
  variant = "outlined",
}) => {
  const { t } = useTranslation(["addCategory", "actions"]);
  const [
    {
      mCat: { data: mdata, loading: mL },
      sCat: { data: sdata, loading: sL },
    },
  ] = useContext(RecipeDataContext);
  const loading = mL || sL;

  const options: CategoryAutoCompleteOption[] = React.useMemo(() => {
    const mainCats: {
      [id: string]: RMSTableData<CurrentMultiLangEntity<{}>>;
    } = {};
    const result: CategoryAutoCompleteOption[] = [];
    mdata?.forEach((el) => {
      mainCats[el.id] = el;
    });
    sdata?.forEach((el) => {
      if (
        !selected.find(
          ({ catId, subcatId }) =>
            subcatId === el.id && catId === el.categoryId,
        )
      )
        result.push({
          label: el.name,
          groupBy: mainCats[el.categoryId].name,
          main: mainCats[el.categoryId],
          sub: el,
        });
    });
    return result.sort((a, b) => -b.label.localeCompare(a.label));
  }, [mdata, sdata, selected]);

  if (!mdata || !sdata) return null;

  const onChange = (
    event: React.ChangeEvent<{}>,
    value: CategoryAutoCompleteOption | null,
  ) => {
    if (!value) return;
    // if (value.main) {
    //   onSelect({ catId: value.main.id });
    // }
    if (value.sub) {
      onSelect(
        {
          catId: value.sub.categoryId,
          subcatId: value.sub.id,
        },
        value.sub,
      );
    }
  };

  return (
    <Autocomplete
      size="small"
      openText={t("actions:open")}
      loadingText={t("actions:loading")}
      noOptionsText={t("noOptionsText")}
      clearText={t("actions:deleteLabel")}
      loading={loading}
      onChange={onChange}
      getOptionSelected={(
        option: CategoryAutoCompleteOption,
        value: CategoryAutoCompleteOption,
      ) => {
        return (
          option.main.id === value.main.id && option.sub.id === value.sub.id
        );
      }}
      options={options.sort((a, b) => -b.groupBy!.localeCompare(a.groupBy!))}
      groupBy={(option) => option.groupBy!}
      getOptionLabel={(option) => option.label}
      fullWidth
      value={value}
      style={{ minWidth: 320 }}
      renderInput={(params) => (
        // @ts-ignore: next-line
        <TextField
          autoFocus
          {...params}
          label={t("catSearch")}
          variant={variant}
        />
      )}
    />
  );
};

import { MultiLangObject, RECIPE_LANGUAGES } from "../api/types";
import { RECIPE_LANGUAGES_ARRAY, TRANSLATE_ME_TAG } from "../config";

export const getLangLabel = (
  langObj: MultiLangObject,
  lang: RECIPE_LANGUAGES,
) => {
  const defaultName = RECIPE_LANGUAGES_ARRAY.find(
    (tran) => langObj[tran] && !langObj[tran]!.startsWith(TRANSLATE_ME_TAG),
  );
  return (
    langObj[lang] ||
    (defaultName ? TRANSLATE_ME_TAG + langObj[defaultName] : "") ||
    TRANSLATE_ME_TAG
  );
};

import { useMediaQuery, useTheme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import { TableCellProps } from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination, {
  LabelDisplayedRowsArgs,
} from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect } from "react";
import LoadingComponent from "../atoms/LoadingComponent";
import { RMSTableBody } from "./RMSTableBody";
import { EnhancedTableHead } from "./RMSTableHead";
import { RMSTablePaginationActions } from "./RMSTablePaginationActions";
import { useRMSTableStyles } from "./styles";
import { getComparator, stableSort } from "./utils";
import { ErrorObject } from "../../utils/typesCheck";

export interface RMSTableProps<X, T extends RMSTableData<X>> {
  rows: T[];
  startOrderBy: keyof T;
  hoverItemId?: T["id"] | undefined;
  cellDefinition: RMSTableHeadCell<T>[];
  onClick?: (v: T) => void;
  pagination?: {
    labelRowsPerPage: string;
  };
  error?: ErrorObject;
  isLoading?: boolean;
  startPageSize?: number;
  selected?: Array<T["id"]>;
  setSelected?: (newSelection: Array<T["id"]>) => void;
}

export function RMSTable<X, T extends RMSTableData<X>>({
  rows,
  startOrderBy,
  pagination,
  hoverItemId,
  cellDefinition: headCells,
  error,
  isLoading,
  startPageSize,
  selected,
  setSelected,
  onClick,
}: RMSTableProps<X, T>) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    pagination ? startPageSize || 10 : rows.length
  );
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <RMSTableLazyLoad
      rows={rows}
      startOrderBy={startOrderBy}
      cellDefinition={headCells}
      hoverItemId={hoverItemId}
      onClick={onClick}
      pagination={pagination}
      page={page}
      rowCount={rows.length}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      selected={selected}
      setSelected={setSelected}
      error={error}
      isLoading={isLoading}
    />
  );
}

export interface RMSTableLazyLoadProps<X, T extends RMSTableData<X>>
  extends RMSTableProps<X, T> {
  page: number;
  rowCount: number;
  rowsPerPage: number;
  // setPage: React.Dispatch<React.SetStateAction<number>>;
  // setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  isLazyLoad?: boolean;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export function RMSTableLazyLoad<X, T extends RMSTableData<X>>({
  rows,
  startOrderBy,
  pagination,
  cellDefinition: headCells,
  page,
  rowCount,
  isLoading,
  hoverItemId,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleChangePage,
  isLazyLoad,
  onClick,
  error,
  selected,
  setSelected,
}: RMSTableLazyLoadProps<X, T>) {
  const classes = useRMSTableStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof T>(startOrderBy);

  const emptyRows =
    rowsPerPage === 0
      ? 0
      : isLazyLoad
      ? rowsPerPage - rows.length
      : rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  useEffect(() => {}, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!selected || !setSelected) return;
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected([...selected, ...newSelecteds]);
      return;
    }
    setSelected([]);
  };

  const pageSelected = selected
    ? rows.filter(({ id }) => selected.includes(id)).length
    : undefined;

  const handleClick = (event: React.MouseEvent<unknown>, name: T["id"]) => {
    if (!selected || !setSelected) return;
    const selectedIndex = selected.indexOf(name);
    let newSelected: Array<T["id"]> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const dataSorted = stableSort(rows, getComparator(order, orderBy) as any);
  const data =
    rowsPerPage === 0 || isLazyLoad
      ? dataSorted
      : dataSorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const footerHeight = 52;
  const theme = useTheme();
  const hideLabel = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <LoadingComponent
      errorText={error}
      style={{ height: "auto", minHeight: 80 }}
      loadingOverlayStyle={{
        bottom: pagination ? footerHeight : 0,
      }}
      isLoading={!!isLoading}
    >
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <EnhancedTableHead
            numSelected={pageSelected}
            order={order}
            headCells={headCells}
            orderBy={orderBy as string}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <RMSTableBody
            rows={data}
            onClick={onClick}
            hoverItemId={hoverItemId}
            selected={selected}
            headCells={headCells}
            emptyRows={emptyRows}
            handleClick={handleClick}
          />
          {pagination ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelRowsPerPage={
                    !hideLabel ? pagination.labelRowsPerPage : ""
                  }
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  colSpan={headCells.length + (selected ? 1 : 0)}
                  count={rowCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelDisplayedRows={({
                    from,
                    to,
                    count,
                  }: LabelDisplayedRowsArgs) => {
                    return `${from}-${to} / ${count}`;
                  }}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={RMSTablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : null}
        </Table>
      </TableContainer>
    </LoadingComponent>
  );
}

export type Order = "asc" | "desc";

export interface RMSTableHeadCell<T> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  numeric: boolean;
  sortable: boolean;
  align?: TableCellProps["align"];
  width?: string;
  formatter?: (v: T, prop: keyof T) => JSX.Element | string | null;
}

export type RMSTableData<X> = {
  id: string | number;
} & X;

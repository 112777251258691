import { useEffect, useState } from "react";
import { getPlainNewRecipe, getRecipe } from "../api/recipes";
import { RecipeDetails } from "../api/types";
import { SimpleReq, useSimpleRequest } from "./useSimpleRequest";

export const useEditRecipe = (
  language: string,
  mainId: number
): [SimpleReq<RecipeDetails>, () => void] => {
  const [simpleRequest, setSimpleRequest] = useSimpleRequest<RecipeDetails>();
  const [refresh, forceRefresh] = useState(0);
  useEffect(() => {
    if (Number.isNaN(mainId)) {
      setSimpleRequest({
        loading: false,
        data: undefined,
        error: { code: 404, message: "404", hint: undefined },
      });
      return;
    }
    if (mainId === -1) {
      setSimpleRequest({
        loading: false,
        data: getPlainNewRecipe(language),
        error: undefined,
      });
      return;
    }
    setSimpleRequest((sr) => ({
      loading: true,
      data: undefined, // do not store old data, whe have to unmount mount editRecipe
      error: undefined,
    }));

    getRecipe(language, mainId)
      .then((rD) => {
        setSimpleRequest({ loading: false, data: rD, error: undefined });
      })
      .catch((e) => {
        setSimpleRequest({ loading: false, data: undefined, error: e });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, mainId, refresh]);

  return [simpleRequest, () => forceRefresh(refresh + 1)];
};

// import { useEffect, useState } from "react";
// import { getRecipe } from "../api/recipes";
// import { RecipeDetails } from "../api/types";
// import { SimpleReq, useSimpleRequest } from "./useSimpleRequest";

// export const useEditRecipe = (
//   language: string,
//   recipeId: number,
// ): [
//   SimpleReq<RecipeDetails>,
//   React.Dispatch<React.SetStateAction<number>,
// ] => {

//   const [refresh, forceRefresh] = useState(0);
//   const [simpleRequest, setSimpleRequest] = useSimpleRequest<RecipeDetails>();
//   useEffect(() => {
//     if (Number.isNaN(recipeId)) {
//       setSimpleRequest({ loading: false, data: undefined, error: "404" });
//       return;
//     }
//     setSimpleRequest({ loading: true, data: undefined, error: undefined });

//     getRecipe(language, recipeId)
//       .then((rD) => {
//         setSimpleRequest({ loading: false, data: rD, error: undefined });
//       })
//       .catch((e) => {
//         setSimpleRequest({ loading: false, data: undefined, error: e.message });
//       });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [language, recipeId, refresh]);

//   return [simpleRequest, () => forceRefresh(r=>r++)];
// };

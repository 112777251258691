import { Box, Grid, TextField } from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { RecipeDetails, RecipeNutrition } from "../../api/types";
import { DEFAULT_GRID_SPACING } from "../../styles/themes";

export interface NutritionFieldsProps {
  recipe: RecipeDetails;
  setState: React.Dispatch<React.SetStateAction<RecipeDetails>>;
}
export function NutritionFields({
  setState,
  recipe: {
    nutrition: { calories, carbs, fat, protein },
  },
}: NutritionFieldsProps) {
  const onChange = (
    key: keyof RecipeNutrition,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let value = parseFloat(event.target.value.replace(",", "."));
    setState((thisState) => ({
      ...thisState,
      nutrition: { ...thisState.nutrition, [key]: value },
    }));
  };

  const { t } = useTranslation("editRecipe");

  return (
    <Grid container spacing={DEFAULT_GRID_SPACING}>
      <Grid item xs={3}>
        <Box pr={2}>
          <TextField
            label={t("calories")}
            fullWidth
            required
            type="number"
            margin="normal"
            InputProps={{
              inputProps: { min: 0 },
            }}
            value={calories}
            onChange={onChange.bind(null, "calories")}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box pr={2}>
          <TextField
            label={t("carbohydrateContent")}
            fullWidth
            required
            type="number"
            margin="normal"
            InputProps={{
              inputProps: { min: 0 },
            }}
            value={carbs}
            onChange={onChange.bind(null, "carbs")}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box pr={2}>
          <TextField
            label={t("fatContent")}
            fullWidth
            required
            type="number"
            margin="normal"
            InputProps={{
              inputProps: { min: 0 },
            }}
            value={fat}
            onChange={onChange.bind(null, "fat")}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box pr={2}>
          <TextField
            label={t("proteinContent")}
            fullWidth
            required
            type="number"
            margin="normal"
            InputProps={{
              inputProps: { min: 0 },
            }}
            value={protein}
            onChange={onChange.bind(null, "protein")}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

import { useMemo } from "react";
import { RecipeSubCategoriesResponse } from "../../api/subCategories";
import {
  CurrentMultiLangEntity,
  SubCatExtendEntity,
  RECIPE_LANGUAGES,
  MultiLangEntityState,
} from "../../api/types";
import { API_URL } from "../../config";
import { useEditLang } from "../../hooks/useEditLang";
import { ReqState, useRequestURL } from "../../hooks/useRequestURL";
import { getLangLabel } from "../../utils/getLangLabel";

const parseSubCatData = (
  data: RecipeSubCategoriesResponse
): CurrentMultiLangEntity<SubCatExtendEntity>[] => {
  return data.subcategories.map((subcategory) => {
    const tmpCategory = {
      id: subcategory.id,
      categoryId: subcategory.categoryId,
      name: subcategory.name,
      "de-DE": subcategory["de-DE"].name,
      "en-US": subcategory["en-US"].name,
      "fr-FR": subcategory["fr-FR"].name,
      "it-IT": subcategory["it-IT"].name,
      "nl-NL": subcategory["nl-NL"].name,
      sortOrder: subcategory.sortOrder, // mock until api sends sortOrder
    };
    return tmpCategory;
  });
};

const mergeSubCatDataToSubCategoriesState = (
  language: RECIPE_LANGUAGES,
  subcategories: CurrentMultiLangEntity<SubCatExtendEntity>[]
): MultiLangEntityState<SubCatExtendEntity> => {
  return subcategories.map(({ id, categoryId, ...langOb }) => {
    const sub: CurrentMultiLangEntity<SubCatExtendEntity> = {
      ...langOb,
      id,
      categoryId,
      name: getLangLabel(langOb, language),
    };
    return sub;
  });
};
export const useSubCategories = (): [
  ReqState<MultiLangEntityState<SubCatExtendEntity>>,
  () => void
] => {
  const [language] = useEditLang();

  const [sCatUnparsed, refreshSubCategories] = useRequestURL({
    url: `${API_URL.SUBCATEGORIES}`,
    method: "GET",
    resetStateOnRefresh: false,
    parseData: parseSubCatData,
  });

  const sCat: ReqState<
    MultiLangEntityState<SubCatExtendEntity>
  > = useMemo(() => {
    const { data, loading, error } = sCatUnparsed;
    return {
      loading,
      error,
      data: data
        ? mergeSubCatDataToSubCategoriesState(language, data)
        : undefined,
    };
  }, [language, sCatUnparsed]);

  return [sCat, refreshSubCategories];
};

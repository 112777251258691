import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  Fab,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import * as H from "history";
import { TFunction } from "i18next";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import moment from "moment";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { translateRecipe } from "../../api/recipes";
import {
  MultiLangEntityState,
  RecipeDetails,
  RECIPE_LANGUAGES,
  RECIPE_STATE,
  SubCatExtendEntity,
} from "../../api/types";
import { getEditRecipeUrl, getListRecipeUrl } from "../../AppNavigation";
import { TRANSLATE_ME_TAG } from "../../config";
import { useEditLang } from "../../hooks/useEditLang";
import { RecipeDataContext } from "../../provider/RecipeData";
import { DEFAULT_SPACING } from "../../styles/themes";
import { saveRecipe } from "../../utils/saveRecipe";
import { validateRecipe } from "../../utils/validateRecipe";
import { AlertComponent } from "../atoms/Alert";
import { FabContainer } from "../atoms/FabContainer";
import LoadingComponent from "../atoms/LoadingComponent";
import { RecipeStateSelect } from "../molecules/RecipeStateSelect";
import { AvailableLanguageChips } from "./AvailableLanguageChips";
import { DurationTab } from "./TabContent/DurationTab";
import { IngredientTab } from "./TabContent/IngredientTab";
import { LinkTab } from "./TabContent/LinkTab";
import { MetaTab } from "./TabContent/MetaTab";
import { RecipeStepsTab } from "./TabContent/RecipeStepsTab";

export interface RecipeEditFormProps {
  recipe: RecipeDetails;
  refresh?: () => void;
  isLoading: boolean;
}

interface RecipeEditFormState {
  recipe: RecipeDetails;
  loading: boolean;
  // showValidationWarn: boolean;
  errors: string[] | { code: number; message: string; hint: string }[];
  tabValue: number;
  hasChanged: boolean;
}

interface RecipeEditFormClassProps extends RecipeEditFormProps {
  t: TFunction;
  history: H.History<H.LocationState>;
  language: RECIPE_LANGUAGES;
  mdata: MultiLangEntityState<{}>;
  sdata: MultiLangEntityState<SubCatExtendEntity>;
  setEditLang: (lang: RECIPE_LANGUAGES) => void;
}

class RecipeEditFormClass extends React.Component<
  RecipeEditFormClassProps,
  RecipeEditFormState
> {
  historyListener: H.UnregisterCallback | undefined;
  constructor(props: RecipeEditFormClassProps) {
    super(props);
    const recipe = cloneDeep(this.props.recipe);
    this.state = {
      recipe,
      errors: [],
      loading: false,
      tabValue: 0,
      hasChanged: recipe.recipeId === -1 || recipe.mainId === -1,
    };
  }

  componentDidUpdate = () => {
    window.ROUTING.setWarnWhenNavigate(this.state.hasChanged);
  };
  componentWillUnmount() {
    window.ROUTING.setWarnWhenNavigate(false);
  }
  render() {
    const { t, isLoading } = this.props;
    const { tabValue, errors, recipe, hasChanged } = this.state;

    const loading = this.state.loading || isLoading;

    const steps = recipe.recipeInstructions;
    return (
      <>
        <LoadingComponent isLoading={loading}>
          {!loading ? (
            <Container style={{ padding: 0 }}>
              <Box mb={2}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Box mb={2} textOverflow="ellipsis" display="grid">
                      <Typography variant="h2" noWrap>
                        {recipe.name || t("newRecipe")}
                      </Typography>
                    </Box>
                    <Box textOverflow="ellipsis" display="grid">
                      <Typography noWrap variant="caption">
                        {[
                          `${t("dateCreated")}: ` +
                            (!recipe.dateCreated
                              ? "-"
                              : moment(recipe.dateCreated).format(
                                  t("date:fulldateFormat")
                                )),
                          `${t("datePublished")}: ` +
                            (!recipe.datePublished
                              ? "-"
                              : moment(recipe.datePublished).format(
                                  t("date:fulldateFormat")
                                )),
                          `${t("dateModified")}: ` +
                            (!recipe.dateModified
                              ? "-"
                              : moment(recipe.dateModified).format(
                                  t("date:fulldateFormat")
                                )),
                        ].join(" / ")}
                      </Typography>
                    </Box>
                  </div>
                  <div style={{ minWidth: 375 }}>
                    <div style={{ textAlign: "right" }}>
                      <RecipeStateSelect
                        selectedKey={recipe.status}
                        setSelectedState={this.setSelectedState}
                        disabledStates={[]}
                        disabled={recipe.recipeId === -1}
                      />
                    </div>
                    {recipe.recipeId !== -1 ? (
                      <AvailableLanguageChips
                        unsavedChances={hasChanged}
                        inLanguage={recipe.inLanguage}
                        translate={this.translate}
                      />
                    ) : null}
                  </div>
                </div>
              </Box>
              <Collapse in={!!errors.length}>
                <AlertComponent
                  variant="filled"
                  severity="error"
                  title={t("editError:errorOnSave", {
                    date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                  })}
                  onClose={() => this.setState({ errors: [] })}
                  style={{ marginBottom: DEFAULT_SPACING * 2 }}
                >
                  {errors.map((error, index) => (
                    <div key={index.toString()}>
                      {typeof error === "object" ? error.message : error}
                    </div>
                  ))}
                </AlertComponent>
              </Collapse>
              <Paper>
                <AppBar elevation={1} color="transparent" position="static">
                  <Tabs
                    value={tabValue}
                    onChange={this.handleChangeTab}
                    aria-label="simple tabs example"
                  >
                    <Tab label={t("meta")} />
                    <Tab label={t("ingredientsAndNutrtion")} />
                    <Tab label={t("steps")} />
                    <Tab label={t("contentLink")} />
                    <Tab label={t("duration")} />
                  </Tabs>
                </AppBar>
                <Box p={3}>
                  {tabValue === 0 ? (
                    <MetaTab recipe={recipe} setState={this.setRecipeState} />
                  ) : null}
                  {tabValue === 1 ? (
                    <IngredientTab
                      recipe={recipe}
                      setRecipeState={this.setRecipeState}
                    />
                  ) : null}
                  {tabValue === 2 ? (
                    <RecipeStepsTab
                      steps={steps}
                      setState={this.setRecipeState}
                    />
                  ) : null}
                  {tabValue === 3 ? (
                    <LinkTab recipe={recipe} setState={this.setRecipeState} />
                  ) : null}
                  {tabValue === 4 ? (
                    <DurationTab
                      recipe={recipe}
                      setState={this.setRecipeState}
                    />
                  ) : null}
                </Box>
              </Paper>
            </Container>
          ) : null}
        </LoadingComponent>
        <FabContainer isFixed>
          <Fab color="default" onClick={this.onCancel}>
            <CloseIcon />
          </Fab>
          <Fab
            color="primary"
            disabled={loading}
            onClick={this.validateAndSave}
          >
            {!loading ? <SaveIcon /> : <CircularProgress />}
          </Fab>
        </FabContainer>
      </>
    );
  }

  setLoading = (loading: boolean) => {
    this.setState({ loading });
  };
  setSaveErrors = (errors: string[]) => {
    this.setState({ errors });
  };

  translate = (addLang: RECIPE_LANGUAGES) => {
    this.setState({ loading: true, errors: [] });
    translateRecipe(this.state.recipe, addLang, TRANSLATE_ME_TAG)
      .then(() => {
        this.props.setEditLang(addLang);
        this.props.history.replace(getEditRecipeUrl(this.state.recipe.mainId));
        this.setState({ loading: false, errors: [] });
      })
      .catch((error) => {
        this.setState({ loading: false, errors: [error] });
      });
  };

  setRecipeState = <K extends keyof RecipeDetails>(
    state:
      | ((
          prevState: Readonly<RecipeDetails>
        ) => Pick<RecipeDetails, K> | RecipeDetails | null)
      | (Pick<RecipeDetails, K> | RecipeDetails | null)
  ) => {
    let newRecipePartitial: Pick<RecipeDetails, K> | RecipeDetails | null;
    if (typeof state === "function") {
      newRecipePartitial = state(this.state.recipe);
    } else {
      newRecipePartitial = state;
    }
    const recipe: RecipeDetails = {
      ...this.state.recipe,
      ...newRecipePartitial,
    };

    this.setState({
      recipe,
      hasChanged:
        this.state.recipe.recipeId === -1 ||
        this.state.recipe.mainId === -1 ||
        !isEqual(this.props.recipe, recipe),
    });
  };

  cancelEdit = (okClicked: boolean) => {
    if (okClicked) {
      return;
    }
    this.props.history.push(getListRecipeUrl());
  };
  onCancel = () => {
    const { t } = this.props;
    if (this.state.hasChanged) {
      window.ALERT({
        title: t("actions:warning"),
        text: t("actions:reallyCancelEdit"),
        okLabel: t("actions:no"),
        cancelLabel: t("actions:yes"),
        doIt: this.cancelEdit,
      });
    } else {
      this.cancelEdit(false);
    }
  };

  validateAndSave = () => {
    const { t, language, mdata, sdata } = this.props;
    const { recipe } = this.state;

    const validation = validateRecipe(recipe, t, mdata, sdata, language);
    const validationError = !!validation?.errors.length;
    const validationWarn = !!validation?.warnings.length;

    if (validationError) {
      window.ALERT({
        title: t("editError:resolveError"),
        text: (
          <AlertComponent
            variant="outlined"
            severity="error"
            // title={t("editError:resolveError")}
            // onClose={() => setValidationWarn(false)}
            style={{ marginBottom: DEFAULT_SPACING * 2 }}
          >
            {validation?.errors.map((message, index) => (
              <div key={index.toString()}>{message}</div>
            ))}
          </AlertComponent>
        ),
        okLabel: t("actions:cancel"),
      });
    } else if (validationWarn) {
      window.ALERT({
        doIt: (ignore) => {
          if (ignore) {
            this.onSave();
          }
        },
        title: t("editError:resolveWarn"),
        cancelLabel: t("actions:cancel"),
        okLabel: t("actions:ignoreAndSage"),
        text: (
          <AlertComponent
            variant="outlined"
            severity="warning"
            style={{ marginBottom: DEFAULT_SPACING * 2 }}
          >
            {validation?.warnings.map((message, index) => (
              <div key={index.toString()}>{message}</div>
            ))}
          </AlertComponent>
        ),
      });
    } else {
      // everything is alright
      this.onSave();
    }
  };

  // DO NOT ME CALL DIRECT, ONLY CALL ME FROM this.validateAndSave
  onSave = () => {
    const { hasChanged, recipe } = this.state;
    if (!hasChanged) return;
    this.setState({ loading: true, errors: [] });
    saveRecipe(
      recipe,
      this.props.recipe,
      this.props.language,
      this.props.t,
      true
    )
      .then(({ errors, mainId }) => {
        this.setState({ loading: false, errors });

        let nextStep: () => void;

        if (this.props.recipe.recipeId === -1) {
          nextStep = () => {
            this.props.history.replace(getEditRecipeUrl(mainId));
            this.setState({ errors: [] });
          };
        } else {
          nextStep = () => {
            if (this.props.refresh) this.props.refresh();
            this.setState({ errors: [] });
          };
        }
        if (errors.length) {
          setTimeout(nextStep, 2000);
        } else {
          setTimeout(nextStep, 200);
        }
      })
      .catch((error) => {
        this.setState({ loading: false, errors: [error] });
      });
  };

  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabValue: newValue });
  };

  setSelectedState = (newState: RECIPE_STATE) => {
    const { t, language, mdata, sdata } = this.props;
    const { recipe } = this.state;

    const validation = validateRecipe(recipe, t, mdata, sdata, language, true);
    const validationPubWarn = !!validation?.publishWarn.length;
    const validationErrors = !!validation?.errors.length;
    if (
      newState === RECIPE_STATE.published &&
      (validationPubWarn || validationErrors)
    ) {
      window.ALERT({
        text: (
          <AlertComponent
            variant="outlined"
            severity="error"
            title={this.props.t("couldNotPublished")}
            style={{ marginBottom: DEFAULT_SPACING * 2 }}
          >
            {[...validation.errors, ...validation.publishWarn].map((e, i) => (
              <span key={i}>
                {e}
                <br />
              </span>
            ))}
          </AlertComponent>
        ),
      });
    } else {
      this.setState({
        recipe: {
          ...this.state.recipe,
          status: newState,
        },
        hasChanged: true,
      });
    }
  };
}

export const RecipeEditForm: React.FC<RecipeEditFormProps> = (props) => {
  const { t } = useTranslation([
    "editRecipe",
    "tbl",
    "actions",
    "editError",
    "date",
  ]);
  const history = useHistory();
  const [language, setEditLang] = useEditLang();
  const [
    {
      mCat: { data: mdata },
      sCat: { data: sdata },
    },
  ] = useContext(RecipeDataContext);

  return (
    <RecipeEditFormClass
      t={t}
      mdata={mdata || []}
      sdata={sdata || []}
      language={language}
      setEditLang={setEditLang}
      history={history}
      {...props}
    />
  );
};

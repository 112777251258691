import {
  TextField,
  MenuItem,
  TextFieldProps,
  Menu,
  Button,
} from "@material-ui/core";
import moment, { unitOfTime } from "moment-timezone";
import React, { ChangeEvent, useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useDefaultStyles } from "../../styles/themes";
import { useTranslation } from "react-i18next";
export interface DurationFieldProps {
  value: string;
  setDuration?: (valueAsDuration: string) => void;
  tprops: TextFieldProps;
  unitFix?: "minute" | "hour";
}
export const DurationField: React.FC<DurationFieldProps> = ({
  value,
  setDuration,
  tprops,
  unitFix,
}) => {
  const { t } = useTranslation("durationField");
  const classes = useDefaultStyles();
  const momentDuration = moment.duration(value);
  const inminute = momentDuration.as("minute");
  const inHours = inminute / 60;

  const [unit, setUnit] = useState<unitOfTime.Base>(
    unitFix
      ? unitFix
      : Math.ceil(inHours) === inHours && inHours !== 0
      ? "hour"
      : "minute"
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const valueAsNumber = moment.duration(value).as(unit);

  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = moment
      .duration(parseFloat(event.target.value.replace(",", ".")), unit)
      .toISOString();
    if (setDuration) {
      setDuration(newValue);
    }
  };

  const handleUnitChange = (value: unitOfTime.Base) => {
    setUnit(value);
    setAnchorEl(null);
  };
  return (
    <TextField
      {...tprops}
      inputProps={{
        style: { width: "auto" },
      }}
      InputProps={{
        inputProps: { min: 0 },
        endAdornment: (
          <>
            <Button
              size="small"
              disabled={
                (tprops.InputProps && tprops.InputProps.readOnly) ||
                tprops.disabled === true
              }
              style={{ textTransform: "none" }}
              onClick={handleClick}
            >
              {t(unit)}
              {open ? (
                <ArrowDropUpIcon className={classes.iconRight} />
              ) : (
                <ArrowDropDownIcon className={classes.iconRight} />
              )}
            </Button>
            <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
              <MenuItem
                onClick={handleUnitChange.bind(null, "minute")}
                value={"minute"}
              >
                {t("minute")}
              </MenuItem>
              <MenuItem
                onClick={handleUnitChange.bind(null, "hour")}
                value={"hour"}
              >
                {t("hour")}
              </MenuItem>
            </Menu>
          </>
        ),
      }}
      type={"number"}
      onChange={onChange}
      value={valueAsNumber}
    />
  );
};

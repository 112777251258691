import React, { createContext, useEffect } from "react";
import { MultiLangEntityState, SubCatExtendEntity } from "../api/types";
import { REFRESH_CATEGORIES_AFTER } from "../config";
import { ReqState } from "../hooks/useRequestURL";
import { useIngredients } from "./recipeProviderHooks.ts/useIngredients";
import { useKeywords } from "./recipeProviderHooks.ts/useKeywords";
import { useMainCategories } from "./recipeProviderHooks.ts/useMainCategories";
import { useSubCategories } from "./recipeProviderHooks.ts/useSubCategories";
import { useUnits } from "./recipeProviderHooks.ts/useUnits";

export interface RecipeData {
  mCat: ReqState<MultiLangEntityState>;
  sCat: ReqState<MultiLangEntityState<SubCatExtendEntity>>;
  kwrds: ReqState<MultiLangEntityState>;
  ingrs: ReqState<MultiLangEntityState>;
  units: ReqState<MultiLangEntityState>;
}
export type RecipeDataContextConsumerProps = [
  RecipeData,
  {
    refreshMainCategories: () => void;
    refreshSubCategories: () => void;
    refreshKeywords: () => void;
    refreshIngredients: () => void;
    refreshUnits: () => void;
  },
];

export const RecipeDataContext = createContext<RecipeDataContextConsumerProps>([
  {
    mCat: { loading: true, data: [], error: undefined },
    sCat: { loading: true, data: [], error: undefined },
    kwrds: { loading: true, data: [], error: undefined },
    ingrs: { loading: true, data: [], error: undefined },
    units: { loading: true, data: [], error: undefined },
  },
  {
    refreshMainCategories: () => {},
    refreshSubCategories: () => {},
    refreshKeywords: () => {},
    refreshIngredients: () => {},
    refreshUnits: () => {},
  },
]);

export const RecipeDataContextProvider: React.FC = ({ children }) => {
  const [mCat, refreshMainCategories] = useMainCategories();
  const [sCat, refreshSubCategories] = useSubCategories();
  const [kwrds, refreshKeywords] = useKeywords();
  const [ingrs, refreshIngredients] = useIngredients();
  const [units, refreshUnits] = useUnits();

  useEffect(() => {
    const intervall = setInterval(() => {
      if (process.env.NODE_ENV === "development") {
        console.debug("refreshing all data dependencies");
      }
      refreshMainCategories();
      refreshSubCategories();
      refreshKeywords();
      refreshIngredients();
      refreshUnits();
    }, REFRESH_CATEGORIES_AFTER);
    return () => {
      clearInterval(intervall);
    };
  }, [
    refreshMainCategories,
    refreshSubCategories,
    refreshKeywords,
    refreshIngredients,
    refreshUnits,
  ]);

  return (
    <RecipeDataContext.Provider
      value={[
        { mCat, sCat, kwrds, ingrs, units },
        {
          refreshMainCategories,
          refreshSubCategories,
          refreshKeywords,
          refreshIngredients,
          refreshUnits,
        },
      ]}
    >
      {children}
    </RecipeDataContext.Provider>
  );
};

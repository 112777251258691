import React from "react";

import { Button, Tooltip } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

export interface UploadButtonProps {
  title: string;
  color?: string;
  disabled?: boolean;
  multiple?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string; // A uniqe id foreach upload
}
export const UploadButton: React.FC<UploadButtonProps> = ({
  title,
  color,
  onChange,
  disabled,
  multiple,
  id,
}) => {
  return (
    <Tooltip title={title}>
      <span>
        <input
          onChange={onChange}
          accept="image/*"
          style={{ display: "none" }}
          id={id}
          multiple={multiple}
          type="file"
        />
        <label htmlFor={id}>
          <Button style={{ color }} disabled={disabled} component="span">
            <AddCircleIcon />
          </Button>
        </label>
      </span>
    </Tooltip>
  );
};

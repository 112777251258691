import { requestWithTimeout } from ".";
import { API_URL } from "../config";
import { RecipeSteps } from "./types";

export interface NewStep {
  recipeId: number;
  // stepNumber: number;
  name: string;
  description: string;
  language: string;
  defaultImages?: string[];
}

export const addNewStep = async (newStep: NewStep) => {
  return await requestWithTimeout<unknown, { stepId: number }>({
    url: `${API_URL.STEPS}`,
    method: "POST",
    body: newStep,
  }).then(({ stepId }) => stepId);
};

interface ResponseRecipeSteps {
  images: Array<{ path: string }>;
  name: string;
  text: string;
  id: number;
  stepNumber: number;
  deleted: boolean;
}

const parseStep = (
  { name, text, images, id }: ResponseRecipeSteps,
  stepNumber: number
): RecipeSteps => ({
  name,
  text,
  id,
  deleted: false,
  images: images.map(({ path }) => ({ base64orUrl: path, deleted: false })),
  stepNumber: stepNumber + 1,
});
const parseSteps = ({ steps }: { steps: ResponseRecipeSteps[] }) =>
  steps.map(parseStep);

export const getStepsForRecipe = async (recipeId: number) => {
  return await requestWithTimeout({
    url: `${API_URL.STEPS}?recipeId=${recipeId}`,
    parseData: parseSteps,
  });
};
export const deleteStep = async (stepId: number) => {
  return await requestWithTimeout({
    url: `${API_URL.STEPS}?id=${stepId}`,
    method: "DELETE",
  });
};

export interface UpdateStep {
  stepId: number;
  name?: string;
  description?: string;
}
export const updateStep = async (step: UpdateStep) => {
  return await requestWithTimeout<unknown, { stepId: number }>({
    url: `${API_URL.STEPS}`,
    method: "PUT",
    body: step,
  }).then(({ stepId }) => stepId);
};
export const updateStepOrder = async (steps: number[]) => {
  return await requestWithTimeout<unknown, { stepId: number }>({
    url: `${API_URL.STEPS_ORDER}`,
    method: "PUT",
    body: { stepIds: steps.filter((step) => step) },
  });
};

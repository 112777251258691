import React, { ChangeEvent, useContext, useState } from "react";

import { ElementChips, ElementChip } from "./ElementChips";
import {
  CurrentMultiLangEntity,
  Keyword,
  RecipeKeywords,
  RECIPE_LANGUAGES,
} from "../../api/types";
import { EditEntityDialog } from "../Dialogs/EditEntityDialog";
import { useTranslation } from "react-i18next";
import { getLangLabel } from "../../utils/getLangLabel";
import {
  AutoCompleteItem,
  CurrentMultiLangEntityAutoComplete,
} from "../molecules/CurrentMultiLangEntityAutoComplete";
import { useEditLang } from "../../hooks/useEditLang";
import { addKeyword, updateKeyword } from "../../api/keywords";
import { RecipeDataContext } from "../../provider/RecipeData";
import { plainMultiLangObj } from "../../utils/plainMultiLangObj";

export interface KeywordChipsProps {
  keywords: RecipeKeywords[];
  updateKeywords: (keywords: RecipeKeywords[]) => void;
}

export const KeywordChips: React.FC<KeywordChipsProps> = ({
  keywords,
  updateKeywords,
}) => {
  const [addAKeyword, setAddKeyword] = useState(false);
  const [
    {
      kwrds: { data: keywordDataRaw },
    },
    { refreshKeywords },
  ] = useContext(RecipeDataContext);
  const [editKeyword, setEditKeyword] = useState<Keyword>();
  const [editLang] = useEditLang();
  const { t: TeditKeyword } = useTranslation(["editKeyword", "actions"]);
  const keywordData = keywordDataRaw || [];

  const keyWordElements: ElementChip<CurrentMultiLangEntity>[] = React.useMemo(
    () =>
      keywords.map((k) => {
        let keyw = keywordData.find(({ id }) => id === k.id);
        if (!keyw) {
          keyw = { id: k.id, ...plainMultiLangObj(), name: k.name };
        }
        return {
          label: keyw.name,
          key: k.id,
          ...keyw,
        };
      }),
    [keywordData, keywords],
  );

  const onAddKeyword = () => {
    setAddKeyword(true);
  };

  const onKeywordDelete = ({ id }: ElementChip<CurrentMultiLangEntity>) => {
    const newKeywords = keywords.filter(({ id: kid }) => id !== kid);
    updateKeywords(newKeywords);
  };

  const onChangeUnitLang = (l: RECIPE_LANGUAGES) => (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const v = event.target.value;
    setEditKeyword((keyword) => (keyword ? { ...keyword, [l]: v } : undefined));
  };
  const onCloseEditKeyword = (newId?: number, isNew?: boolean) => {
    if (newId === undefined || !editKeyword) {
      setEditKeyword(undefined);
      return;
    }
    const name = getLangLabel(editKeyword, editLang);
    let newKeywords = [...keywords];
    if (isNew) {
      newKeywords.push({ id: newId, name });
    } else {
      newKeywords = newKeywords.map((k) => {
        if (k.id === newId) {
          return { id: newId, name };
        }
        return k;
      });
    }
    updateKeywords(newKeywords);
    refreshKeywords();
    setEditKeyword(undefined);
  };

  const onEditElement = (el: ElementChip<CurrentMultiLangEntity>) => {
    const kw = keywordData.find(({ id }) => id === el.id);
    setEditKeyword(kw);
  };
  const onSelectKeyword = (i: AutoCompleteItem | null) => {
    setAddKeyword(false);
    if (!i) return;
    if (i.id < 0) {
      // Create new Keyword
      addKeyword(i).then((keywordId) => {
        updateKeywords([...keywords, { id: keywordId, name: i[editLang]! }]);
        refreshKeywords();
      });
    } else {
      updateKeywords([...keywords, { id: i.id, name: i.name }]);
    }
  };
  return (
    <>
      {editKeyword ? (
        <EditEntityDialog
          onChange={onChangeUnitLang}
          t={TeditKeyword}
          entity={editKeyword}
          onClose={onCloseEditKeyword}
          addEntity={addKeyword}
          updateEntity={updateKeyword}
        />
      ) : null}
      <ElementChips
        elements={keyWordElements}
        onEditElement={onEditElement}
        onElementDelete={onKeywordDelete}
        addElement={onAddKeyword}
      />
      {addAKeyword ? (
        <CurrentMultiLangEntityAutoComplete
          onSelect={onSelectKeyword}
          data={keywordData}
          addLabel={TeditKeyword("actions:create") + ": "}
          editLang={editLang}
        />
      ) : null}
    </>
  );
};

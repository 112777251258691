import React, { useState, ChangeEvent, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useDefaultStyles } from "../../styles/themes";
import { doLogin, doPwdReset } from "../../api/login";
import { ErrorBox } from "../atoms/ErrorBox";
import { SuccessBox } from "../atoms/SuccessBox";
import { storeAuthToken } from "../../api";
import { ErrorObject } from "../../utils/typesCheck";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: 500,
    },
  })
);
type FormType = "login" | "pwdReset" | "pwdResetSend";
export const LoginPage = () => {
  const { t } = useTranslation("login");

  const classes = useStyles();
  const classesDefault = useDefaultStyles();

  const [loading, setLoading] = useState(false);
  const [formType, setFormType] = useState<FormType>("login");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    code: undefined,
    message: "",
    hint: undefined,
  });

  useEffect(() => {
    // we have a valid auth!
    if (window.AUTH) {
      document.location.replace("/");
    }
  }, []);

  const onChange = (
    typ: "usr" | "pwd",
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (typ === "usr") {
      setUsername(event.target.value);
    }
    if (typ === "pwd") {
      setPassword(event.target.value);
    }
  };

  const doIt = () => {
    setLoading(true);
    setError({ code: undefined, message: "", hint: undefined });
    if (formType === "pwdReset") {
      doPwdReset(username)
        .then(() => {
          setFormType("pwdResetSend");
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      doLogin(username, password)
        .then(({ jwtToken }) => {
          storeAuthToken(jwtToken);
        })
        .catch((e) => {
          let overwriteError: ErrorObject | undefined = undefined;

          if (e.message.startsWith("404")) {
            overwriteError = {
              code: undefined,
              message: t("wrongEmail"),
              hint: undefined,
            };
          } else if (e.message.startsWith("401")) {
            overwriteError = {
              code: undefined,
              message: t("wrongPassword"),
              hint: undefined,
            };
          }
          setError(overwriteError || e);
          setLoading(false);
        });
    }
  };
  const doItWithEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      doIt();
    }
  };
  const toggleFormType = (nFormType?: FormType) => {
    setFormType(nFormType || formType === "pwdReset" ? "login" : "pwdReset");
  };

  const loginDisabled = !username || !password || !!loading;

  return (
    <Dialog
      open={true}
      classes={{
        paper: classes.paper,
      }}
      TransitionComponent={Transition}
      keepMounted
    >
      <AppBar elevation={0} style={{ position: "relative" }}>
        <Toolbar>
          <Typography variant="h6">
            {formType === "login" ? t("loginTitle") : t("pwdForgotten")}
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        {formType !== "pwdResetSend" ? (
          <>
            {error.message ? <ErrorBox text={error.message} /> : null}
            <Box>
              <Typography>
                {formType === "login" ? t("loginText") : t("pwdResetText")}
              </Typography>
            </Box>
            <TextField
              value={username}
              margin="normal"
              variant="standard"
              label={t("eMail")}
              onChange={onChange.bind(null, "usr")}
              placeholder={t("eMail")}
              fullWidth
              autoFocus
              onKeyDown={doItWithEnter}
            />
            {formType === "login" ? (
              <TextField
                onChange={onChange.bind(null, "pwd")}
                value={password}
                type="password"
                margin="normal"
                variant="standard"
                label={t("password")}
                placeholder={t("password")}
                fullWidth
                onKeyDown={doItWithEnter}
              />
            ) : null}
          </>
        ) : (
          <SuccessBox text={t("pwdResetSend")}>
            <Box textAlign="right" mt={2}>
              <Button onClick={toggleFormType.bind(null, "login")}>
                {t("loginButton")}
                <ChevronRightIcon className={classesDefault.iconRight} />
              </Button>
            </Box>
          </SuccessBox>
        )}
      </DialogContent>
      {formType !== "pwdResetSend" ? (
        <DialogActions>
          {/* <Button
            disabled={loading}
            variant="text"
            onClick={toggleFormType.bind(null, undefined)}
            color="secondary"
          >
            {formType === "login" ? t("pwdForgotten") : t("loginTitle")}
          </Button> */}
          <Button
            variant="contained"
            disabled={loginDisabled}
            onClick={doIt}
            color="primary"
          >
            {loading ? (
              <>
                <CircularProgress
                  className={classesDefault.iconLeft}
                  size={20}
                />
                {t("loading")}
              </>
            ) : (
              t("loginButton")
            )}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

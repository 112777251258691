import {Box, Button, Input} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {TFunction} from "i18next";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {deleteAllSubCategories, updateSubCatSortOrder} from "../../api/subCategories";
import {CurrentMultiLangEntity, MultiLangEntityState, SubCatExtendEntity,} from "../../api/types";
import {ReqState} from "../../hooks/useRequestURL";
import {useSimpleRequest} from "../../hooks/useSimpleRequest";
import {useDefaultStyles} from "../../styles/themes";
import {ConfirmDialog} from "../Dialogs/ConfirmDialog";
import {CategoryEditDialog} from "../Dialogs/SingleCategoryEditDialog";
import {RMSTable, RMSTableData, RMSTableHeadCell} from "../RMSTable";


/**
 * This very custom components aim's to ignoring the hole convetions of the project cause there is no good one!
 * @constructor
 */
type SortOrderInputProps = {
  subCategory: RMSTableData<CurrentMultiLangEntity<SubCatExtendEntity>>;
  refresh: () => void;
}

function SortOrderInput({subCategory, refresh}: SortOrderInputProps) {
  const [loading, setLoading] = useState(false);

  async function saveAndRefresh(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const sortOrder = e.target.value;
    if (+sortOrder !== +subCategory.sortOrder) {
      setLoading(true);
      await updateSubCatSortOrder(subCategory.id, sortOrder).finally(() => setLoading(false));
      await refresh()
    }
  }

  return (
    <Input
      name="sortOrder"
      style={{width: '60px'}}
      defaultValue={subCategory.sortOrder}
      type="number"
      onBlur={saveAndRefresh}
      disabled={loading}
    />
  )
}

const getHeadCells = (
  t: TFunction,
  classes: Record<any, string>,
  onClick: (
    v: CurrentMultiLangEntity<SubCatExtendEntity>,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void,
  refresh: () => void,
): RMSTableHeadCell<RMSTableData<CurrentMultiLangEntity<SubCatExtendEntity>>>[] => [
  {
    disablePadding: true,
    id: "id",
    width: "20%",
    label: t("actions:actions"),
    numeric: false,
    sortable: false,
    formatter: (v) => (
      <Button
        style={{ padding: 0, textTransform: "none" }}
        onClick={onClick.bind(null, v)}
      >
        <EditIcon className={classes.iconRight} />
      </Button>
    ),
  },
  {
    disablePadding: true,
    id: "name",
    label: t("name"),
    numeric: false,
    width: "40%",
    sortable: true,
  }, {
    disablePadding: true,
    id: "sortOrder",
    label: t("sortOrder"),
    numeric: true,
    width: "30%",
    sortable: true,
    formatter: (v) => (
      <SortOrderInput subCategory={v} refresh={refresh}/>
    ),
  },
];

export interface ListSubCategoriesProps {
  mainCatForSubCat: CurrentMultiLangEntity;
  selCategory: CurrentMultiLangEntity<SubCatExtendEntity> | undefined;
  setSelectedCategoryId: (
    id?: number,
    cat?: RMSTableData<CurrentMultiLangEntity<SubCatExtendEntity>>
  ) => void;
  reqState: [ReqState<MultiLangEntityState<SubCatExtendEntity>>, () => void];
}

export const ListSubCategories: React.FC<ListSubCategoriesProps> = ({
  selCategory,
  reqState,
  mainCatForSubCat,
  setSelectedCategoryId,
}) => {
  const classes = useDefaultStyles();
  // todo refresh should be required
  const [{ loading, data, error }, refresh] = reqState;

  const [openEditDialog, setOpenEditDialog] = React.useState(false);

  const { t } = useTranslation(["editSubCategory", "tbl", "actions"]);
  const onEditClick = (
    v: RMSTableData<CurrentMultiLangEntity<SubCatExtendEntity>>
  ) => {
    setSelectedCategoryId(v.id, v);
  };
  const onAddClick = () => {
    setSelectedCategoryId(-1);
    setOpenEditDialog(true);
  };

  const onSaveClose = (savedId?: number) => {
    if (savedId !== undefined) {
      refresh();
      setSelectedCategoryId(savedId);
    }
    setOpenEditDialog(false);
  };

  const [delReq, setDeleteRequests] = useSimpleRequest();

  const deleteSubCategories = (confirmed: boolean) => {
    setDeleteConfirm(false);
    if (confirmed) {
      setDeleteRequests({ loading: true, error: undefined });
      deleteAllSubCategories(selected).then((errorIds) => {
        setDeleteRequests({
          loading: false,
          error: errorIds.length
            ? {
                code: undefined,
                message: errorIds.length + t("couldNotBeDelete"),
                hint: undefined,
              }
            : undefined,
        });
        refresh();
      });
    }
  };

  const [selected, setSelected] = React.useState<number[]>([]);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  const rows = (data || []).filter(
    ({ categoryId }) => categoryId === mainCatForSubCat.id
  );

  return (
    <>
      {selCategory && openEditDialog ? (
        <CategoryEditDialog
          category={selCategory}
          mainCatForSubCat={mainCatForSubCat}
          onClose={onSaveClose}
        />
      ) : null}
      <ConfirmDialog
        open={deleteConfirm}
        doIt={deleteSubCategories}
        text={t("confirmDelete", { count: selected.length })}
        okLabel={t("actions:deleteLabel")}
        cancelLabel={t("actions:cancel")}
      />
      <RMSTable
        isLoading={loading || delReq.loading}
        hoverItemId={selCategory?.id}
        error={error || delReq.error}
        startOrderBy="name"
        pagination={{
          labelRowsPerPage: t("tbl:labelRowsPerPage"),
        }}
        cellDefinition={getHeadCells(t, classes, (v, e) => {
          e.stopPropagation();
          setSelectedCategoryId(v.id);
          setOpenEditDialog(true);
        }, refresh)}
        onClick={onEditClick}
        setSelected={setSelected}
        selected={selected}
        rows={rows}
      />
      <Box pt={2} pb={2} textAlign="right" className="rightSpacingChildren">
        <Button
          variant="contained"
          color="secondary"
          disabled={selected.length === 0}
          onClick={() => setDeleteConfirm(true)}
        >
          <DeleteIcon className={classes.iconLeft} />
          {t("actions:deleteLabel")}{" "}
          {selected.length !== 0 ? `(${selected.length})` : ""}
        </Button>
        <Button variant="contained" onClick={onAddClick} color="primary">
          <AddIcon className={classes.iconLeft} />
          {t("actions:add")}
        </Button>
      </Box>
    </>
  );
};

import { Checkbox, TableHead, TableSortLabel } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { Order, RMSTableHeadCell } from ".";
import { DEFAULT_SPACING } from "../../styles/themes";

const useRMSTableHeadStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  }),
);

export interface RMSTableHeadProps<T> {
  numSelected?: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: RMSTableHeadCell<T>[];
}

export function EnhancedTableHead<T>(props: RMSTableHeadProps<T>) {
  const classes = useRMSTableHeadStyles();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler = (property: keyof T) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {numSelected !== undefined ? (
          <TableCell padding="none">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              color="primary"
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        ) : null}
        {headCells.map(
          ({ id, align, disablePadding, label, sortable, width }, index) => (
            <TableCell
              key={id as string}
              align={align}
              padding={disablePadding ? "none" : "default"}
              sortDirection={orderBy === id ? order : false}
              style={{
                width,
                paddingLeft:
                  index === 0 && numSelected === undefined
                    ? DEFAULT_SPACING * 2
                    : undefined,
              }}
            >
              {sortable ? (
                <TableSortLabel
                  active={sortable && orderBy === id}
                  direction={orderBy === id ? order : "asc"}
                  onClick={createSortHandler(id)}
                >
                  {label}
                  {sortable && orderBy === id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                label
              )}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
}

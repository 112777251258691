import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { addKeyword, deleteKeyword, updateKeyword } from "../../api/keywords";
import { useEditLang } from "../../hooks/useEditLang";
import { RecipeDataContext } from "../../provider/RecipeData";
import { useDefaultStyles } from "../../styles/themes";
import LoadingComponent from "../atoms/LoadingComponent";
import { BaseLayout } from "../layout/BaseLayout";
import { ListMultiLangEntitiesClass } from "../molecules/ListMultiLangEntitiesClass";
import { ListRecipes } from "../molecules/ListRecipes";

export const ListKeywordsPage: React.FC = () => {
  const [
    {
      kwrds: { data, loading, error },
    },
    { refreshKeywords },
  ] = useContext(RecipeDataContext);
  const classes = useDefaultStyles();
  const { t } = useTranslation(["editKeyword", "tbl"]);
  const [searchValue, setSearchValue] = useState("");
  const [editLang] = useEditLang();
  const filteredKeywords = React.useMemo(() => {
    if (!data) return [];
    if (!searchValue) return data;
    return data.filter(
      (keyword) =>
        keyword[editLang]?.toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1,
    );
  }, [data, searchValue, editLang]);
  const [rsearchValue, setRSearchValue] = useState<string>();

  return (
    <BaseLayout>
      <LoadingComponent isLoading={loading} errorText={error}>
        <ListMultiLangEntitiesClass
          t={t}
          setSearchValue={setSearchValue}
          classes={classes}
          searchValue={searchValue}
          onClick={(x) => setRSearchValue(x)}
          entityState={filteredKeywords}
          refresh={refreshKeywords}
          deleteEntity={deleteKeyword}
          addEntity={addKeyword}
          updateEntity={updateKeyword}
        />
        {rsearchValue ? (
          <ListRecipes
            searchValue={rsearchValue}
            rowsPerPage={5}
            offset={0}
            page={0}
            searchKey={"keyword"}
          />
        ) : null}
      </LoadingComponent>
    </BaseLayout>
  );
};

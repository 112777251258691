import { Divider } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core/SvgIcon";
import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

export interface DrawerItemProps {
  langKey: string;
  path?: string;
  selected: boolean;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

export const DrawerItem: React.FC<DrawerItemProps> = ({
  langKey,
  Icon,
  path,
  selected,
}) => {
  const { t } = useTranslation("menu");
  const onClick = (path: string | undefined, e: SyntheticEvent) => {
    if (!path) {
      return;
    }
    window.ROUTING?.navigateTo(e, path);
    e.preventDefault();
  };
  // const renderLink = React.useMemo(
  //   () =>
  //     React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
  //       <RouterLink to={path!} ref={ref} {...itemProps} />
  //     )),
  //   [path],
  // );
  if (langKey === "divider") return <Divider />;
  return (
    <ListItem onClick={onClick.bind(null, path)} selected={selected} button>
      {Icon ? (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      ) : null}
      <ListItemText primary={t(langKey)} />
    </ListItem>
  );
};
